const initialState = "";

const loginStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGINSTAT":
      return action.data;
    default:
      return state;
  }
};

export default loginStateReducer;
