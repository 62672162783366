import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import axios from "axios";
import AllPagesNavbar from "../Navbar";
import Loading from "../Loading";
import { BiSolidEditAlt } from "react-icons/bi";
import { useLocation } from "react-router-dom";

import "../../CSS/EngineMaster.css";
const MYTECHSHEET = () => {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const state = useSelector((state) => state?.loginreducer);
  const date = new Date().toISOString();
  const userPass = state?.user?.userCode;

  const [PassPROPELLERNO, setPassPROPELLERNO] = useState("");
  const [search, setSearch] = useState("");
  const [PropellerList, setPropellerList] = useState([]);
  const [seldivision, setSelDivision] = useState("");
  const userState = useSelector((state) => state.getUserState);
  const userLoggeduserCode = userState.user.userCode;

  const [isloading, setisloading] = useState(false);

  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const localStartOfYear = new Date(
    startOfYear.getTime() - startOfYear.getTimezoneOffset() * 60000
  );

  const [startdate, setStartdate] = useState(localStartOfYear.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());

  const [PassLASTOVERREADING, setPassLASTOVERREADING] = useState("");
  const [PassLASTOVERHAULDATE, setPassLASTOVERHAULDATE] = useState("");

  useEffect(() => {
    if (userLoggeduserCode) {
      FetchDetails();
    }
  }, [userLoggeduserCode]);
  function FetchDetails() {
    setisloading(true);
    axios
      //   .get(`${BASEURL}/getTechLogSheetData)`, {
      .get(`${BASEURL}/getTechLogSheetData?PILOT CODE=${userLoggeduserCode}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        console.log(res.data);
        setisloading(false);
        setPropellerList(res?.data);
      })
      .catch((err) => {
        console.log("Error while fetching /getPropellers List", err);
      });
  }
  const filteredData = PropellerList.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = new Date(item["TECHLOG DATE"]);
    const eDate = new Date(item["TECHLOG DATE"]);

    const textMatch = searchData.every((term) => {
      return [
        "TECH LOG NO",
        "LEGINDEX",
        "SOURCE",
        "TECHLOG DATE",
        "TAKE OFF TIME",
        "LANDING TIME",
        "FLYING HRS",
        "MILES",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= new Date(startdate)) &&
      (!enddate || eDate <= new Date(enddate));

    return textMatch && dateMatch;
  });

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  if (isloading) {
    return <Loading />;
  }

  return (
    <div className="bg-gray-50 min-h-[100vh]">
      <AllPagesNavbar />
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page">{menu}</h1>
      </header>
      <section className="lg:flex items-center gap-5 mx-5">
        <div className="lg:flex items-center gap-3">
          <p>Search:</p>
          <input
            className="w-[300px] h-7 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <label className=" text-gray-700">From Date</label>
          <input
            type="date"
            onChange={(e) => {
              setStartdate(e.target.value);
            }}
            value={startdate.split("T")[0]}
            placeholder="From Date"
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <label className=" text-gray-700">End Date</label>
          <input
            type="date"
            onChange={(e) => {
              setEnddate(e.target.value);
            }}
            value={enddate.split("T")[0]}
            placeholder="To Date"
          />
        </div>
      </section>
      {isloading ? (
        <Loading />
      ) : (
        <div className="overflow-y-auto h-[500px] px-4">
          <table className="custom-table text-sm overflow__y__ relative ">
            <thead
              className="header-cell sticky top-[0px] left-[10px]"
              style={{ zIndex: 50 }}
            >
              <tr>
                <td className="text-right">TECH LOG NO</td>
                <td className="text-right">LEGINDEX </td>
                <td className="text-">AIRCRAFT </td>
                <td className="text-">SOURCE </td>
                <td className="text-">TECHLOG DATE </td>
                <td className="text-">TAKE OFF TIME </td>
                <td className="text-">LANDING TIME </td>
                <td className="text-">FLYING HRS </td>
                <td className="text-">MILES </td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 ? (
                filteredData.map((item, index) => (
                  <tr key={index} style={{ textTransform: "uppercase" }}>
                    <td className=" text-right">
                      {item["TECH LOG NO"] || "NA"}
                    </td>
                    <td className=" text-right">{item["LEGINDEX"] || "NA"}</td>
                    <td className=" text">{item["AIRCRAFT"] || "NA"}</td>
                    <td className=" text">{item["SOURCE"] || "NA"}</td>
                    <td className="text">
                      {item["TECHLOG DATE"]
                        ? item["TECHLOG DATE"].slice(8, 10) +
                          "/" +
                          item["TECHLOG DATE"].slice(5, 7) +
                          "/" +
                          item["TECHLOG DATE"].slice(0, 4)
                        : "NA"}
                    </td>
                    <td className="">
                      {item["TAKE OFF TIME"]
                        ? item["TAKE OFF TIME"].slice(11, 19)
                        : "NA"}
                    </td>
                    <td className="">
                      {item["LANDING TIME"]
                        ? item["LANDING TIME"].slice(11, 19)
                        : "NA"}
                    </td>
                    <td className="text-right">{item["FLYING HRS"] || "NA"}</td>
                    <td className="text-right ">
                      {item["MILES"] || "0"} &nbsp;
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="14" className="text-center">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MYTECHSHEET;
