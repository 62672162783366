const initialState = [];

const updateUserRights = (state = initialState, action) => {
  switch (action.type) {
    case "USERRIGHTS":
      console.log(action);
      return action.payload;
    default:
      return state;
  }
};

export default updateUserRights;
