import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "../CSS/FuelRate.css";
import Loading from "./Loading";
import { BsSearch } from "react-icons/bs";
import Footer from "./Footer";

function FuelRate() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const [purchaseData, setPurchaseData] = useState([]);
  const [searchedTerm, setSearchedTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // For fetching initial data from db
  async function getIntData() {
    try {
      if (!authToken) {
        navigate("/");
      } else {
        setIsLoading(true);
        const response = await axios.get(`${BASEURL}/getDataforauthority`, {
          headers: {
            "auth-token": authToken,
            "session-token": sclientSecret,
          },
        });

        // Transforming the response data to store in state
        const transformedData = response.data.recordset.map((item) => ({
          ...item,
          fueldate: item.fueldate
            ? item.fueldate.substr(0, 10).split("-").reverse().join("-")
            : "N/A",
        }));

        setPurchaseData(transformedData);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
      // Handle the error here
    }
  }

  useEffect(() => {
    getIntData();
  }, []);
  async function handleSubmit() {
    setIsLoading(true);
    var flag = false;
    for (var i = 0; i < purchaseData.length; i++) {
      // ... Your existing code ...
    }
    setIsLoading(false);
    if (flag) {
      alert("data not inserted");
    } else {
      alert("Success");
    }
  }
  // Handling changes for input fields
  const handleQuantityChange = (index, value) => {
    const updatedData = [...purchaseData];
    updatedData[index].qty = value;
    updatedData[index].amount =
      Number(value) *
      Number(updatedData[index].exchrate) *
      Number(updatedData[index].rate);
    setPurchaseData(updatedData);
  };

  const handleRateChange = (index, value) => {
    const updatedData = [...purchaseData];
    updatedData[index].rate = value;
    updatedData[index].amount =
      Number(updatedData[index].qty) *
      Number(updatedData[index].exchrate) *
      Number(value);
    setPurchaseData(updatedData);
  };

  const handleCurrencyChange = (index, value) => {
    const updatedData = [...purchaseData];
    updatedData[index].currency = value;
    setPurchaseData(updatedData);
  };

  const handleExchangeRateChange = (index, value) => {
    const updatedData = [...purchaseData];
    updatedData[index].exchrate = value;
    updatedData[index].amount =
      Number(updatedData[index].qty) *
      Number(value) *
      Number(updatedData[index].rate);
    setPurchaseData(updatedData);
  };

  const renderList = (
    <>
      <div className="content">
        <h1 className="ribbon">
          <h3>Pending Fuel Rate Updates</h3>
          <div>
            <button className="button__fuel_back" onClick={handleSubmit}>
              SAVE
            </button>
            <Link className="link__defoult" to="/main">
              <button className="button__fuel_back">BACK</button>{" "}
            </Link>{" "}
          </div>
        </h1>
        <tr>
          <td className="new__search">
            <label> Search :</label>
          </td>
          <td>
            <input
              className="new__search__input"
              type="text"
              onChange={(e) => {
                setSearchedTerm(e.target.value);
              }}
            />
            <span className="span__icon">
              {" "}
              <BsSearch />
            </span>
          </td>
        </tr>

        <table className="table__test3">
          <thead>
            <tr className="tr__test1">
              <th>Date</th>
              <th>Order Number</th>

              <th>Aircraft</th>
              <th>Location</th>
              <th>Supplier Code</th>
              <th>Supplier Name</th>
              <th>QTY</th>
              <th>Rate</th>
              <th>Currency</th>
              <th>Exchange Rate</th>
              <th>Amount</th>
            </tr>
          </thead>
          {purchaseData
            .filter((data) => {
              if (searchedTerm === "") {
                return data;
              } else if (
                data.orderno
                  .toString()
                  .toLowerCase()
                  .includes(searchedTerm.toString().toLowerCase())
              ) {
                return data;
              } else if (
                data.aircraft
                  .toString()
                  .toLowerCase()
                  .includes(searchedTerm.toString().toLowerCase())
              ) {
                return data;
              } else if (
                data.location
                  .toString()
                  .toLowerCase()
                  .includes(searchedTerm.toString().toLowerCase())
              ) {
                return data;
              } else if (
                data.suppliercode
                  .toString()
                  .toLowerCase()
                  .includes(searchedTerm.toString().toLowerCase())
              ) {
                return data;
              } else if (
                data.VENDNAME.toString()
                  .toLowerCase()
                  .includes(searchedTerm.toString().toLowerCase())
              ) {
                return data;
              } else if (
                data.fueldate
                  .toString()
                  .toLowerCase()
                  .includes(searchedTerm.toString().toLowerCase())
              ) {
                return data;
              }
            })
            .map((key, index) => {
              return (
                <tr className="fuel__rate" key={index}>
                  <td className="order__align">
                    <p> {key.fueldate ? key.fueldate : "N/A"}</p>
                  </td>
                  <td className="order__align">
                    <p>{key.orderno}</p>
                  </td>
                  <td>{key.aircraft}</td>
                  <td>{key.location}</td>
                  <td className="order__align">
                    <p>{key.suppliercode}</p>
                  </td>
                  <td>{key.VENDNAME}</td>
                  <td>
                    <input
                      type="number"
                      className="fuel__rate__input"
                      name="QTY"
                      onChange={(e) =>
                        handleQuantityChange(index, e.target.value)
                      }
                      value={key.qty}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="fuel__rate__amount"
                      onChange={(e) => handleRateChange(index, e.target.value)}
                      value={key.rate}
                    />
                  </td>
                  <td>
                    <select
                      className="currency__Select"
                      onChange={(e) =>
                        handleCurrencyChange(index, e.target.value)
                      }
                      value={key.currency}
                    >
                      <option value={key.currency}>{key.currency}</option>
                      <option value={key.currency === "KSH" ? "USD" : "KSH"}>
                        {key.currency === "KSH" ? "USD" : "KSH"}
                      </option>
                    </select>
                  </td>
                  <td>
                    <input
                      className="fuel__rate__input"
                      type="number"
                      onChange={(e) =>
                        handleExchangeRateChange(index, e.target.value)
                      }
                      value={key.exchrate}
                    />
                  </td>
                  <td>
                    <p style={{ textAlign: "right", paddingRight: "5px" }}>
                      {key.amount.toFixed(2)}
                    </p>
                  </td>
                </tr>
              );
            })}
        </table>
      </div>
      <Footer />
    </>
  );

  return <>{isLoading ? <Loading /> : renderList}</>;
}

export default FuelRate;
