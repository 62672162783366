import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "../CSS/Sidebar.css";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`}>
      <div className="sidebar-toggle" onClick={toggleSidebar}>
        {isOpen ? (
          <>
            <div className="color___fbar">
              {" "}
              <FaTimes />
            </div>
          </>
        ) : (
          <>
            <div className="color___fbar">
              <FaBars />
            </div>
          </>
        )}
      </div>
      <section className="main__align_items ">
        <Link className="defoult__link transaction-select-opt" to="/Dashboard">
          {" "}
          <p>Create new techlog document </p>
        </Link>{" "}
        <Link
          className="defoult__link transaction-select-opt"
          to="/OlderDocument"
        >
          <p>Techlog Documents </p>
        </Link>{" "}
        <Link
          className="defoult__link transaction-select-opt"
          to="/FuelExpence"
        >
          <p> fuel expense</p>
        </Link>{" "}
      </section>
    </div>
  );
};

export default Sidebar;
