import React from "react";
import "../CSS/Footer.css";
const Footer = () => {
  // year has been added
  const today = new Date();
  const year = today.getFullYear();
  return (
    <div>
      <footer className="footerstyle">
        <div className="toalignaside">
          {/* brand name is given in H tag */}
          <aside className="aside1">
            <h2>TeCH23</h2>

            {/* email of the company  */}

            <p>Email : Support@tech23.net</p>
            {/* contact of company is given here */}
            <p>Contact : +254 798074411 </p>
          </aside>
          <aside className="aside2">
            {/* detail information about terms of use  */}
            <p>
              <h3>Terms of Use</h3>
              This Software program is protected by the copyright law and
              international treaties.Unauthorized reproduction or redistribution
              of this program or any portion of it may result in severe civil
              and penalties, and will be prosecuted to maximum extent possible
              under the law.
            </p>
          </aside>
        </div>
        {/* copyright with symbol and all right reserved */}
        <div
          className="text-center"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          {/* year has been added dynamically */}
          Copyright © {year} TeCH23. All Rights Reserved
        </div>
      </footer>

      {/* <section className=" hover-underline-animation">
      <p>
        <h4> Truck exit from Head office for Loading </h4>
        <Link to="/TruckExitHeadOffice">
          <button className="button-30">
            go to &nbsp; <FaHandPointer />
          </button>
        </Link>

        <button className="button-30">no of trucks</button>
      </p>
      <p>
        <h4> Truck Arriving at Loading Point </h4>
        <Link to="/TruckArriveLoadingPoint">
          <button className="button-30">
            go to &nbsp; <FaHandPointer />
          </button>
        </Link>
        <button className="button-30">no of trucks</button>
      </p>
      <p>
        <h4> Truck Leaving from Loading Point</h4>
        <Link to="/TruckLeavingLoadingPoint">
          <button className="button-30">
            go to &nbsp; <FaHandPointer />
          </button>
        </Link>
        <button className="button-30">no of trucks</button>
      </p>
      <p>
        <h4> Truck Arriving at Offloading Point</h4>
        <Link to="/TruckArriveOffloadingPoint">
          <button className="button-30">
            go to &nbsp; <FaHandPointer />
          </button>
        </Link>
        <button className="button-30">no of trucks</button>
      </p>
      <p>
        <h4> Truck Leaving from Offloading Point</h4>
        <Link to="/TruckLeavingOffloadingPoint">
          <button className="button-30">
            go to &nbsp; <FaHandPointer />
          </button>
        </Link>
        <button className="button-30">no of trucks</button>
      </p>
      <p>
        <h4>
          {" "}
          Truck Arriving at the Head Office after completion of Journey
        </h4>
        <Link to="/TruckArrivingHeadOffice">
          <button className="button-30">
            go to &nbsp; <FaHandPointer />
          </button>
        </Link>
        <button className="button-30">no of trucks</button>
      </p>
      <p>
        <h4> Checkpoint Arrival and Departure Scanning</h4>
        <Link to="/CheckpointArrivalDeparture">
          <button className="button-30">
            go to &nbsp; <FaHandPointer />
          </button>
        </Link>
        <button className="button-30">no of trucks</button>
      </p>
    </section> */}
    </div>
  );
};

export default Footer;
