import React, { useEffect, useState } from "react";
import "../../CSS/Navlog.css";
import Axios from "axios";
function NavlogDashboard() {
  const [name, setName] = useState("");
  const [aircraftList, setAircraftList] = useState([]);
  const [flighNo, setFlightNo] = useState("");
  const [acType, setAcType] = useState("");
  const [routePlan, setRoutePlan] = useState([]);
  const [flightSector, setFlightSector] = useState([]);
  function getInitialData() {
    Axios.get("http://localhost:3001/getircrafts").then((response) => {
      setAircraftList(response.data.recordset);
    });
  }

  useEffect(getInitialData, []);
  return (
    <div>
      <header>
        <h3 className="NAVLOG_HEADING">JOURNEY / NAV LOG & FLIGHT RELEASE </h3>
        <h2>{name}</h2>
      </header>
      <section>
        <table className="section1__navlog">
          <tr>
            <td>authorised by:</td>
            <td>Name:</td>
            <td>
              <input type="text" />
            </td>
            <td>position:</td>
            <td>
              <input type="text" />
            </td>
            <td>sign:</td>
            <td>
              <input type="text" />
            </td>
            <td>date:</td>
            <td>
              <input type="date" />
            </td>
            <td>A/c reg: </td>
            <td>
              <select
                onChange={(e) => {
                  var registration = e.target.value;
                  Axios.post("http://localhost:3001/getaircraftdetails", {
                    registration: e.target.value,
                  })
                    .then((response) => {
                      setAcType(response.data.recordset[0].AIRCRAFTTYPE);
                      setFlightNo(response.data.recordset[0].SERIALNO);
                    })
                    .then(
                      Axios.post("http://localhost:3001/getNavRouteDetails", {
                        registration: registration,
                      }).then((response) => {
                        if (response.data.message) {
                          setRoutePlan([]);
                          setFlightSector([]);
                          alert(response.data.message);
                        } else {
                          setRoutePlan(response.data.recordset);
                          setFlightSector(response.data.recordset);
                        }
                      })
                    );
                }}
              >
                <option>SELECT:</option>
                {aircraftList.map((key) => {
                  return <option>{key.AIRCRAFTREG}</option>;
                })}
              </select>
            </td>
            <td>A/c type:</td>
            <td>
              <input
                value={acType}
                disabled={true}
                placeholder={acType}
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td>prepared by:</td>
            <td>Name:</td>
            <td>
              <input type="text" />
            </td>
            <td>position:</td>
            <td>
              <input type="text" />
            </td>
            <td>sign:</td>
            <td>
              <input type="text" />
            </td>
            <td>date:</td>
            <td>
              <input type="date" />
            </td>
            <td>flight no:</td>
            <td>
              <input
                value={flighNo}
                disabled={true}
                placeholder={flighNo}
                type="text"
              />
            </td>
            <td>flight service:</td>
            <td>
              <input type="text" />
            </td>
          </tr>
          <tr>
            <td>flight crew:</td>
            <td colspan="2">
              capt:
              <input type="text" />
            </td>

            <td colspan="2">
              sign:
              <input type="text" />
            </td>
            <td colspan="2">
              F/o:
              <input type="text" />
            </td>
            <td colspan="3">
              cabin crew:
              <input type="text" />
            </td>
            <td colspan="4">
              total no. of crew:
              <input type="text" />
            </td>
          </tr>
        </table>

        {/* 
        <button onClick={getData}>Click</button>
        <button onClick={pushData}>hhhhhh</button> */}
      </section>
      <section className="table1__navlog">
        <table className="route__plan">
          <tr>
            <th colspan="10" className="  route__plan__head ">
              {" "}
              route plan (flight ops): <input type="text" />
            </th>

            <th colspan="2">
              full plan: <input type="text" />
            </th>
          </tr>
          <tr>
            {" "}
            <th>from</th>
            <th>to</th>
            <th>comment</th>
            <th>MSA (FT)</th>
            <th>MAG trk</th>
            <th>FL (ft)</th>
            <th>TAS (kts)</th>
            <th>DIST (nm)</th>
            <th>ETE (MINS)</th>
            <th>ETE HRS</th>
            <th>FUEL REG kgs/*lbs</th>
            <th>TOTAL kgs/*lbs</th>
          </tr>
          {routePlan.map((key) => {
            return (
              <tr>
                <td>
                  <p>{key.OT_TECH_LOG_FROM_CITY}</p>
                  {/* <input
                    disabled={true}
                    value={key.OT_TECH_LOG_FROM_CITY}
                    placeholder={key.OT_TECH_LOG_FROM_CITY}
                    type="text"
                  /> */}
                </td>
                <td>
                  <p>{key.OT_TECH_LOG_TO_CITY}</p>
                  {/* <input
                    disabled={true}
                    placeholder={key.OT_TECH_LOG_TO_CITY}
                    value={key.OT_TECH_LOG_TO_CITY}
                    type="text"
                  /> */}
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
                <td>
                  <input type="text" />
                </td>
              </tr>
            );
          })}
          <tr>
            <td className="defoult">-</td>
          </tr>
          <tr className="total">
            <td colspan="2">
              total:
              <input type="text" />
            </td>
          </tr>
          <tr className="total">
            <td colspan="3">
              take off altern:
              <input type="text" />
            </td>
            <td colspan="3">
              enroute altern:
              <input type="text" />
            </td>
            <td colspan="3">
              destination altern:
              <input type="text" />
            </td>
          </tr>
        </table>
        <article className="text__special__instruction">
          <aside>
            <p>
              <label>SPECIAL INSTRUCTION TO FLIGHT CREW:</label>
            </p>
            <textarea rows="4" cols="50"></textarea>
          </aside>
          <aside>
            <p>
              <label>NOTAMS:</label>
            </p>
            <textarea rows="4" cols="50"></textarea>
          </aside>
        </article>
      </section>
      <main className="main__flight__section">
        <h3>FLIGHT SECTORS (PILOTS):</h3>
        <section>
          <table className="flight__section">
            <tr>
              {" "}
              <th>from</th>
              <th>to</th>
              <th>pax on</th>
              <th>pax off</th>
              <th>dist (nm)</th>
              <th>mag hdg</th>
              <th>block off (hh:MM)</th>
              <th>take 0ff (hh:MM)</th>
              <th>fl (ft) </th>
              <th>ETE (HH:MM)</th>
              <th>block on (HH:MM)</th>
              <th>ata (HH:MM)</th>
              <th>block on (HH:MM)</th>
              <th>TOt flt (HH:MM)</th>
              <th>tot block (HH:MM)</th>
              <th>fuel burn KGS/*lbs</th>
              <th>temp (*C)</th>
              <th>ias (kts)</th>
              <th>w/v</th>
              <th>gnd spd (kts)</th>
            </tr>
            {flightSector.map((key) => {
              return (
                <tr>
                  <td>
                    <p>{key.OT_TECH_LOG_FROM_CITY}</p>
                    {/* <input
                      className="custom-input"
                      readOnly
                      value={key.OT_TECH_LOG_FROM_CITY}
                      placeholder={key.OT_TECH_LOG_FROM_CITY}
                      type="text"
                    /> */}
                  </td>
                  <td>
                    <p>{key.OT_TECH_LOG_TO_CITY}</p>
                    {/* <input
                      className="custom-input"
                      readOnly
                      placeholder={key.OT_TECH_LOG_TO_CITY}
                      value={key.OT_TECH_LOG_TO_CITY}
                      type="text"
                    /> */}
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                  <td>
                    <input type="text" />
                  </td>
                </tr>
              );
            })}
            <tr>
              <td className="defoult">-</td>
            </tr>

            <tr className="total">
              <td colspan="3">
                alternate:
                <input type="text" />
              </td>
            </tr>
            <tr className="total">
              <td colspan="3">
                total:
                <input type="text" />
              </td>
            </tr>
          </table>
        </section>
      </main>

      <article className="text__special__instruction">
        <aside>
          <p>
            <label>MET / NOTES</label>
          </p>
          <textarea rows="7" cols="70"></textarea>
        </aside>
        <aside>
          <p>
            <label>VOYAGE REPORT / POST FLIGHT REMARK</label>
          </p>
          <textarea rows="4" cols="50"></textarea>
          <p className="text__Area__captain__sign">
            {" "}
            CAPTAIN SIGN: .............................
          </p>
        </aside>
      </article>
    </div>
  );
}

export default NavlogDashboard;
