import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import "../CSS/Registration.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { BsCamera } from "react-icons/bs";
import { Link } from "react-router-dom";
// import Profile from './Profile';
import Footer from "./Footer";
import Navbar from "./Navbar";
function Registration() {
  const initialValues = {
    EMPNO: "",
    EMPCODE: "",
    EMPNAME: "",
    ACCOUNTNO: "",
    JOINDATE: "",
    NATIONALID: "",
    TELEPHONE: "",
    EMAIL: "",
    OVERTIME: 0,
    DESIGNATION: "",
    DEPARTMENT: "",
    FREEZE: 0,
    VEHICLEALLOCATED: "",
    CASUAL: 0,
    SUBCONTRACTED: 1,
    CREATEDBY: "",
    CONFIRM: 0,
    NOTES: "",
    PORTPASS: 0,
    PORTPASSNO: "",
    PORTPASSEXPIRY: "",
    IMAGENAME: "",
    IMAGE: null,
  };
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const [formInput, setFormInput] = useState(initialValues);
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const [joinDate, setJoinDate] = useState();
  const [desigVal, setDesigVal] = useState([]);
  const [deptVal, setDeptVal] = useState([]);
  const [section1, setSection1] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [fileUpload, setFileUpload] = useState(null);
  const [filePreview, setFilePreview] = useState(false);
  const [portPassExpDate, setPortPassExpDate] = useState();
  const [dispPicture, setDispPicture] = useState("");
  const [picture, setPicture] = useState(null);
  const [cameraOn, setCameraOn] = useState(false);
  const [captured, setCaptured] = useState(false);
  const [checked, setchecked] = useState(true);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const handleInputItem = (e) => {
    const { name, value } = e.target;
    setFormInput({ ...formInput, [name]: value });
    console.log(formInput);
  };
  //     const formData = new FormData();

  //     axios.post(`${BASEURL}/insertPilot`, formInput, {headers: {
  // "Content-Type": "multipart/form-data","auth-token": authToken, },}).then((response) => {
  // alert(response.data.message);
  // window.location.reload();
  // });

  function handleSubmit() {
    const formData = new FormData();

    formData.append("image", formInput.IMAGENAME);
    formData.append("EMPNO", formInput.EMPNO);
    formData.append("EMPCODE", formInput.EMPCODE);
    formData.append("EMPNAME", formInput.EMPNAME);
    formData.append("ACCOUNTNO", formInput.ACCOUNTNO);
    formData.append("JOINDATE", formInput.JOINDATE);
    formData.append("NATIONALID", formInput.NATIONALID);
    formData.append("TELEPHONE", formInput.TELEPHONE);
    formData.append("EMAIL", formInput.EMAIL);
    formData.append("OVERTIME", formInput.OVERTIME);
    formData.append("DESIGNATION", formInput.DESIGNATION);
    formData.append("DEPARTMENT", formInput.DEPARTMENT);
    formData.append("FREEZE", formInput.FREEZE);
    formData.append("VEHICLEALLOCATED", formInput.VEHICLEALLOCATED);
    formData.append("CASUAL", formInput.CASUAL);
    formData.append("SUBCONTRACTED", formInput.SUBCONTRACTED);
    formData.append("CREATEDBY", formInput.CREATEDBY);
    formData.append("CONFIRM", formInput.CONFIRM);
    formData.append("NOTES", formInput.NOTES);
    formData.append("PORTPASS", formInput.PORTPASS);
    formData.append("PORTPASSNO", formInput.PORTPASSNO);
    formData.append("PORTPASSEXPIRY", formInput.PORTPASSEXPIRY);
    formData.append("IMAGENAME", formInput.IMAGENAME);
    try {
      axios
        .post(`${BASEURL}/insertPilot`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "auth-token": authToken,
            "session-token": sclientSecret,
          },
        })
        .then((response) => {
          console.log(response.data);
          alert(response.data.message);
          window.location.reload();
        })
        .catch(() => {
          alert("Internal server error. Please try logging in again!");
        });
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error appropriately, e.g., show error message to the user
    }
  }

  function department() {
    try {
      axios
        .get(`${BASEURL}/getemployeedepartment`, {
          headers: {
            "auth-token": authToken,
            "session-token": sclientSecret,
          },
        })
        .then((response) => {
          setDeptVal(response.data.recordset);
          console.log(response.data);
        });
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error appropriately, e.g., show error message to the user
    }
  }

  useEffect(department, []);

  function designation() {
    try {
      axios
        .get(`${BASEURL}/getemployeedesignation`, {
          headers: {
            "auth-token": authToken,
            "session-token": sclientSecret,
          },
        })
        .then((response) => {
          setDesigVal(response.data.recordset);
          console.log(response.data);
        });
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error appropriately, e.g., show error message to the user
    }
  }

  useEffect(designation, []);

  function Vehicle() {
    try {
      axios
        .get(`${BASEURL}/getvehicle`, {
          headers: {
            "auth-token": authToken,
            "session-token": sclientSecret,
          },
        })
        .then((response) => {
          setVehicles(response.data.recordset);
          console.log(response.data);
        });

      axios
        .get(`${BASEURL}/getempId`, {
          headers: {
            "auth-token": authToken,
            "session-token": sclientSecret,
          },
        })
        .then((response) => {
          console.log(response.data.recordset);
          formInput.EMPNO = response.data.recordset[0].ID;
        });
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error appropriately, e.g., show error message to the user
    }
  }

  useEffect(Vehicle, []);

  const openCamera = () => {
    setSection1(false);
    setCameraOn(true);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      })
      .catch((error) => {
        console.log("error accessing camera", error);
      });
  };

  const capturePicture = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);

    const pictureData = canvas.toDataURL();
    canvas.toBlob((blob) => {
      setPicture(blob);
    });
    setDispPicture(pictureData);
    video.srcObject.getTracks().forEach((track) => track.stop());
    setCaptured(true);
    setCameraOn(false);
  };

  const retakePicture = () => {
    setPicture(null);
    setDispPicture("");
    setCaptured(false);
    openCamera();
  };
  function handlePictureUpload(e) {
    const file = e.target.files[0];
    setPicture(file);
    formInput.IMAGE = file;
    const imageURL = URL.createObjectURL(file);
    setDispPicture(imageURL);
  }

  const renderFilePreview = () => {
    if (fileUpload) {
      const fileType = fileUpload.type.split("/")[1];

      if (fileType === "pdf") {
        return (
          <embed
            src={URL.createObjectURL(fileUpload)}
            type="application/pdf"
            width="100%"
            height="500px"
          />
        );
      } else if (
        ["jpg", "jpeg", "png", "heic", "heif", "webp"].includes(fileType)
      ) {
        return <img src={URL.createObjectURL(fileUpload)} />;
      }

      return <p>Preview not available for this file type.</p>;
    }
  };

  return (
    <div>
      {/* <div className="navbar-container1">
        <h2>Tech23</h2>
        <Link className="link__defoult" to="/main">
          <button className="button__fuel_back">BACK</button>{" "}
        </Link>{" "}
      </div> */}
      <Navbar />
      <div className="Profile_details"></div>
      {cameraOn ? (
        <div className="for__camera__btn">
          <video className="width__video" ref={videoRef} />

          <button
            className="Capture"
            onClick={() => {
              capturePicture();
            }}
          >
            Capture
          </button>
          <canvas ref={canvasRef} />
        </div>
      ) : null}
      {captured ? (
        <div className="for__camera__btn">
          <div>
            <img className="Image" src={dispPicture} />
          </div>

          <div className="camera_btn2">
            <button
              className="retake"
              onClick={() => {
                retakePicture();
              }}
            >
              Retake
            </button>
            <button
              className="Proceed"
              onClick={() => {
                setCameraOn(false);
                setCaptured(false);
                setSection1(true);
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      ) : null}

      {/* <div className="navbar-container1">
<Link className="link__defoult" to="/main">
              <button className="button__fuel_back">BACK</button>{" "}
            </Link>{" "}</div> */}

      {section1 ? (
        <div>
          <h2 style={{ textAlign: "center" }}>{menu || "PILOTS CREATION"}</h2>
          <form className="form7">
            <div className="registration-form-container">
              <div className="row">
                <div className="small-12 medium-2 large-2 columns">
                  <div className="circle">
                    <div className="center__no__data">
                      <div>
                        <img
                          src={dispPicture}
                          alt="No Data"
                          className={dispPicture ? "img" : "profile_pic"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="camera_upload">
                    <div>
                      <button
                        className="camera_btn"
                        onClick={() => {
                          const confirmBox = window.confirm(
                            "Allow access to camera?"
                          );
                          if (confirmBox === true) {
                            setSection1(false);
                            setCameraOn(true);
                            openCamera();
                          } else {
                            return null;
                          }
                        }}
                      >
                        <h1>
                          <span>
                            <BsCamera />
                          </span>
                        </h1>{" "}
                      </button>
                    </div>
                    <div>
                      <input
                        style={{ padding: "5px", display: "none" }}
                        id="file"
                        type="file"
                        placeholder="upload"
                        accept=".png, .jpg, .jpeg, .webp"
                        onChange={(e) => {
                          handlePictureUpload(e);
                        }}
                      />
                      <div>
                        <button>
                          {" "}
                          <label className="label_upload" htmlFor="file">
                            Upload
                          </label>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="Detials">
                  <div>
                    <label className="label">EMPLOYEE NUMBER</label>
                    <input
                      // required
                      type="text"
                      name="EMPNO"
                      defaultValue={formInput.EMPNO}
                      onChange={(e) => handleInputItem(e)}
                      disabled
                    />
                  </div>
                  <div>
                    <label className="label">EMPLOYEE CODE</label>
                    <input
                      // required
                      type="text"
                      name="EMPCODE"
                      defaultValue={formInput.EMPCODE}
                      onChange={(e) => handleInputItem(e)}
                    />
                  </div>
                  <div>
                    <label className="label">EMPLOYEE NAME</label>
                    <input
                      // required
                      type="text"
                      name="EMPNAME"
                      defaultValue={formInput.EMPNAME}
                      onChange={(e) => handleInputItem(e)}
                    />
                  </div>
                  <div>
                    <label className="label">ACCOUNT NO</label>
                    <input
                      // required
                      type="text"
                      name="ACCOUNTNO"
                      defaultValue={formInput.ACCOUNTNO}
                      onChange={(e) => handleInputItem(e)}
                    />
                  </div>
                  <div>
                    <label className="label">JOIN DATE</label>
                    <input
                      // required
                      type="date"
                      name="JOINDATE"
                      // max={new Date().toISOString().split('T')[0]}

                      onChange={(e) => {
                        formInput.JOINDATE = e.target.value;
                      }}
                    />
                  </div>
                  <div>
                    <label className="label">NATIONAL ID</label>
                    <input
                      // required
                      type="text"
                      name="NATIONALID"
                      defaultValue={formInput.NatId}
                      onChange={(e) => handleInputItem(e)}
                    />
                  </div>
                  <div>
                    <label className="label">TELEPHONE</label>
                    <input
                      // required
                      type="number"
                      name="TELEPHONE"
                      defaultValue={formInput.TELEPHONE}
                      onChange={(e) => handleInputItem(e)}
                    />
                  </div>
                  <div>
                    <label className="label">EMAIL</label>
                    <input
                      // required
                      type="email"
                      name="EMAIL"
                      defaultValue={formInput.EMAIL}
                      onChange={(e) => handleInputItem(e)}
                    />
                  </div>
                </div>

                <div className="Info">
                  <div>
                    <label className="label">DESIGNATION</label>
                    <select
                      // style={{ width: "164px" }}
                      className="input_field"
                      name="DESIGNATION"
                      value={formInput.DESIGNATION}
                      onChange={(e) => handleInputItem(e)}
                    >
                      <option value={""}>Select</option>
                      {desigVal.map((data) => (
                        <option value={data.OM_DESIGNATION_NAME}>
                          {data.OM_DESIGNATION_NAME}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="label">DEPARTMENT</label>
                    <select
                      className="input_field"
                      name="DEPARTMENT"
                      onChange={(e) => handleInputItem(e)}
                    >
                      <option value={""}>Select</option>
                      {deptVal.map((data) => (
                        <option value={data.OM_DEPARTMENT_NAME}>
                          {data.OM_DEPARTMENT_NAME}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="label">VEHICLE ALLOCATED </label>
                    <select
                      className="input_field"
                      name="VEHICLEALLOCATED"
                      onChange={(e) => handleInputItem(e)}
                    >
                      <option value={""}>Select</option>
                      {vehicles.map((vehi) => (
                        <option value={vehi["VEHICLE REG NO"]}>
                          {vehi["VEHICLE REG NO"]}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="label">OVERTIME</label>
                    <div className="radio_btn">
                      <div>
                        <input
                          className="radio"
                          type="radio"
                          name="OVERTIME"
                          onChange={(e) => handleInputItem(e)}
                          value={Number(1)}
                        />
                        Yes
                      </div>
                      <div>
                        <input
                          className="radio"
                          type="radio"
                          name="OVERTIME"
                          defaultChecked={true}
                          onChange={(e) => handleInputItem(e)}
                          value={Number(0)}
                        />
                        No
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="label">CASUAL</label>
                    <div className="radio_btn">
                      <div>
                        <input
                          className="radio"
                          type="radio"
                          name="CASUAL"
                          onChange={(e) => handleInputItem(e)}
                          value={Number(1)}
                        />
                        {/* <input
                          className="radio"
                          required
                          type="radio"
                          name="CASUAL"
                          onChange={(e) => {
                            handleInputItem(e);
                            if (e.target.value === "0") {
                              // If Casual is No, set Subcontracted to Yes
                              setFormInput({
                                ...formInput,
                                CASUAL: 0,
                                SUBCONTRACTED: 1,
                              });
                            } else {
                              // If Casual is Yes, set Subcontracted to No
                              setFormInput({
                                ...formInput,
                                CASUAL: 1,
                                SUBCONTRACTED: 0,
                              });
                            }
                          }}
                          value={1}
                          checked={formInput.CASUAL === 1}
                        /> */}
                        Yes
                      </div>
                      <div>
                        <input
                          className="radio"
                          type="radio"
                          name="CASUAL"
                          defaultChecked={true}
                          // defaultValue={formInput.casual}
                          onChange={(e) => handleInputItem(e)}
                          value={Number(0)}
                        />
                        {/* <input
                          className="radio"
                          required
                          type="radio"
                          name="CASUAL"
                          onChange={(e) => {
                            handleInputItem(e);
                            if (e.target.value === "1") {
                              // If Casual is Yes, set Subcontracted to No
                              setFormInput({
                                ...formInput,
                                CASUAL: 1,
                                SUBCONTRACTED: 0,
                              });
                            } else {
                              // If Casual is No, set Subcontracted to Yes
                              setFormInput({
                                ...formInput,
                                CASUAL: 0,
                                SUBCONTRACTED: 1,
                              });
                            }
                          }}
                          value={0}
                          checked={formInput.CASUAL === 0}
                        /> */}
                        No
                      </div>
                    </div>
                  </div>
                  {/* <div>
                    <label className="label">CONFIRM</label>
                    <div className="radio_btn">
                      <div>
                        <input
                          className="radio"
                          type="radio"
                          name="CONFIRM"
                          onChange={(e) => handleInputItem(e)}
                          value={Number(1)}
                        />
                        Yes
                      </div>
                      <div>
                        <input
                          className="radio"
                          type="radio"
                          name="CONFIRM"
                          defaultChecked={true}
                          // defaultValue={formInput.casual}
                          onChange={(e) => handleInputItem(e)}
                          value={Number(0)}
                        />
                        No
                      </div>
                    </div>
                  </div> */}
                  <div>
                    <label className="label">SUBCONTRACTED</label>
                    <div className="radio_btn">
                      <div>
                        <input
                          className="radio"
                          type="radio"
                          name="SUBCONTRACTED"
                          onChange={(e) => handleInputItem(e)}
                          value={Number(1)}
                        />
                        {/* <input
                          required
                          className="radio"
                          type="radio"
                          name="SUBCONTRACTED"
                          onChange={(e) => {
                            handleInputItem(e);
                            if (e.target.value === "0") {
                              // If Subcontracted is No, set Casual to Yes
                              setFormInput({
                                ...formInput,
                                SUBCONTRACTED: 0,
                                CASUAL: 1,
                              });
                            } else {
                              // If Subcontracted is Yes, set Casual to No
                              setFormInput({
                                ...formInput,
                                SUBCONTRACTED: 1,
                                CASUAL: 0,
                              });
                            }
                          }}
                          value={1}
                          checked={formInput.SUBCONTRACTED === 1}
                        /> */}
                        Yes
                      </div>
                      <div>
                        <input
                          className="radio"
                          type="radio"
                          name="SUBCONTRACTED"
                          defaultChecked={true}
                          onChange={(e) => handleInputItem(e)}
                          value={Number(0)}
                        />
                        {/* <input
                          required
                          className="radio"
                          type="radio"
                          name="subcontract"
                          onChange={(e) => {
                            handleInputItem(e);
                            if (e.target.value === "1") {
                              // If Subcontracted is Yes, set Casual to No
                              setFormInput({
                                ...formInput,
                                SUBCONTRACTED: 1,
                                CASUAL: 0,
                              });
                            } else {
                              // If Subcontracted is No, set Casual to Yes
                              setFormInput({
                                ...formInput,
                                SUBCONTRACTED: 0,
                                CASUAL: 1,
                              });
                            }
                          }}
                          value={0}
                          checked={formInput.SUBCONTRACTED === 0}
                        /> */}
                        No
                      </div>
                    </div>
                  </div>
                  {/* <div>
                    <label className="label">FREEZE</label>
                    <div className="radio_btn">
                      <div>
                        <input
                          className="radio"
                          type="radio"
                          name="FREEZE"
                          onChange={(e) => handleInputItem(e)}
                          value={Number(1)}
                        />
                        Yes
                      </div>
                      <div>
                        <input
                          className="radio"
                          type="radio"
                          name="FREEZE"
                          defaultChecked={true}
                          onChange={(e) => handleInputItem(e)}
                          value={Number(0)}
                        />
                        No
                      </div>
                    </div>
                  </div> */}

                  <div className="portDetails">
                    <label className="label">PORT PASS</label>
                    <div className="radio_btn">
                      <div>
                        <input
                          type="radio"
                          className="radio"
                          value={Number(1)}
                          name="PORTPASS"
                          onChange={(e) => {
                            handleInputItem(e);
                            setchecked(false);
                          }}
                        />
                        Yes
                      </div>
                      <div>
                        <input
                          type="radio"
                          className="radio"
                          defaultChecked
                          value={Number(0)}
                          name="PORTPASS"
                          onChange={(e) => {
                            handleInputItem(e);
                            setchecked(true);
                          }}
                        />
                        No
                      </div>
                    </div>
                  </div>
                  <div className="Pass_port_no">
                    <label className="label">PORT PASS NUMBER</label>
                    <input
                      className="input_field"
                      type="text"
                      name="PORTPASSNO"
                      disabled={checked}
                      defaultValue={formInput.PORTPASSNO}
                      onChange={(e) => handleInputItem(e)}
                    />
                  </div>
                  <div className="Pass_port_no">
                    <label className="label">PORT PASS EXPIRY DATE </label>
                    <input
                      disabled={checked}
                      className="input_field"
                      type="date"
                      name="PORTPASSEXPIRY"
                      onChange={(e) => {
                        formInput.PORTPASSEXPIRY = e.target.value;
                      }}
                      // onChange={(e) => handleInputItem(e)}
                    />
                  </div>
                  <div className="textarea_notes">
                    <label className="label"> Notes </label>
                    <textarea
                      className="Note"
                      type="text"
                      name="NOTES"
                      defaultValue={formInput.NOTES}
                      onChange={(e) => handleInputItem(e)}
                    />
                  </div>
                  <div className="upload_file_for_register">
                    <label>Upload file</label>
                    <input
                      style={{ width: "250px", backgroundColor: "#ecf1f2" }}
                      id="file"
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      // onChange={(e) => {
                      //   formInput.IMAGE = e.target.value;
                      // }}
                      onChange={(e) => {
                        setFileUpload(e.target.files[0]);
                      }}
                    ></input>
                  </div>
                  <span className="preview_btn">
                    <button
                      className="Preview"
                      type="button"
                      onClick={() => {
                        setFilePreview(!filePreview);
                      }}
                    >
                      {filePreview ? "Close" : "Preview"}
                    </button>
                  </span>

                  {filePreview ? <div>{renderFilePreview()}</div> : null}
                </div>
                <div className="nxt">
                  <div>
                    <button
                      onClick={() => {
                        window.location.reload();
                      }}
                    >
                      {" "}
                      Refresh{" "}
                    </button>
                  </div>
                  <div>
                    <button
                      className="View"
                      type="submit"
                      onClick={(e) => {
                        console.log(formInput);
                        const confirmBox = window.confirm(
                          "Are you sure all the details are correct?"
                        );
                        if (confirmBox === true) {
                          e.preventDefault();
                          handleSubmit();
                          console.log(formInput);
                        } else {
                          e.preventDefault();
                        }
                      }}
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : null}
      <Footer />
    </div>
  );
}

export default Registration;
