export const logIn = (data) => {
  return {
    type: "LOGIN",
    payload: data,
  };
};

export const logOut = (data) => {
  return {
    type: "LOGOUT",
    payload: data,
  };
};

export const logInStatus = (data) => {
  return {
    type: "LOGINSTAT",
    data: data,
  };
};
