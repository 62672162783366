import React, { useEffect } from "react";

const DisableAutocomplete = () => {
  useEffect(() => {
    const inputFields = document.querySelectorAll("input");
    inputFields.forEach((input) => {
      input.setAttribute("autocomplete", "off");
    });
  }, []);

  return null; // This component doesn't render anything
};

export default DisableAutocomplete;
