import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import axios from "axios";
import AllPagesNavbar from "./Navbar";
import Loading from "./Loading";
import { BiSolidEditAlt } from "react-icons/bi";
import { useLocation } from "react-router-dom";

function TrainingSchedule() {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const [search, setSearch] = useState("");
  const [isloading, setisloading] = useState(false);
  const userState = useSelector((state) => state.getUserState);
  const userLoggeduserCode = userState.user.userCode;
  const [sheduleNumber, setSheduleNumber] = useState(0);
  const [TrainingDays, setTrainingDays] = useState(0);
  const [TrainingType, setTrainingType] = useState([]);
  const [sheludeDate, setSheludeDate] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [listofEmployee, setlistofEmployee] = useState([]);
  const [SelectedValueType, setSelectedValueType] = useState("");
  const [SelectedValueDays, setSelectedValueDays] = useState(0);
  const [selectedEmployees, setSelectedEmployees] = useState([]); // New state to track selected employees
  const currentDate = new Date();
  const [toDateFilter, setToDateFilter] = useState(
    currentDate?.toISOString()?.split("T")[0]
  );

  useEffect(() => {
    FetchDetails();
  }, []);

  function FetchDetails() {
    setisloading(true);
    axios
      .get(`${BASEURL}/getNewTrainingSchedule`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        setisloading(false);
        setSheduleNumber(res.data[0]?.NEWSHEDULENO);
      })
      .catch((err) => {
        setisloading(false);
        console.log("Error while fetching /getEngines List", err);
      });
    axios
      .get(`${BASEURL}/getallemployee`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((response) => {
        console.log(response.data);
        setlistofEmployee(response.data);
      })
      .catch((err) => {
        setisloading(false);
        console.log("Error while fetching /getEngines List", err);
      });

    axios
      .get(`${BASEURL}/getTrainingTypes`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((response) => {
        setTrainingType(response.data);
      })
      .catch((err) => {
        setisloading(false);
        console.log("Error while fetching /getEngines List", err);
      });
  }

  function handleselected(e) {
    setSelectedValueType(e.value);
    const days = TrainingType.map((item) => {
      if (item.TRAININGTYPE === e.value) {
        return item.TRAININGDAYS;
      }
      return null; // Add this to handle map return when no match
    }).filter(Boolean); // Filter out null values
    setSelectedValueDays(days[0]);
  }

  const handleCheckboxChange = (item, isChecked) => {
    if (isChecked) {
      setSelectedEmployees((prev) => {
        if (!prev.find((employee) => employee.EMPID === item.EMPID)) {
          return [...prev, item];
        }
        return prev;
      });
    } else {
      setSelectedEmployees(
        (prev) => prev.filter((employee) => employee.EMPID !== item.EMPID) // Remove if unchecked
      );
    }
  };

  console.log(selectedEmployees); // This should show only the checked employees

  const filteredData = listofEmployee.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    const textMatch = searchData.every((term) => {
      return ["EMPCODE", "EMPNAME", "EMAIL", "DEPARTMENT", "DESIGNATION"].some(
        (key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        }
      );
    });

    return textMatch;
  });

  const HandleSave = async () => {
    if (selectedEmployees.length === 0) {
      alert("Please select at least one employee");
      return;
    }
    if (!SelectedValueType) {
      alert("Please select training type");
      return;
    }
    if (!SelectedValueDays) {
      alert("Please provide TRAINING DAYS");
      return;
    }

    if (!toDateFilter) {
      alert("Please provide SHEDULE DATE");
      return;
    }

    const confirmEdit = window.confirm("Are you sure you want to Save?");
    if (confirmEdit) {
      setisloading(true);
      const date = toDateFilter
        ? toDateFilter.slice(8, 10) +
          "/" +
          toDateFilter.slice(5, 7) +
          "/" +
          toDateFilter.slice(0, 4)
        : "";
      try {
        const requests = selectedEmployees.map((employee) => {
          console.log(employee.EMPID);
          const dataToSend = {
            TRAININGSCHEDULENO: sheduleNumber,
            TRAININGTYPE: SelectedValueType,
            EMPID: employee.EMPID,
            TRAININGDAYS: SelectedValueDays,
            SHEDULEDATE: toDateFilter,
            SCHEDULECREATEDBY: userLoggeduserCode,
          };

          return axios.post(`${BASEURL}/insertTrainingSchedule`, dataToSend, {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          });
        });

        await Promise.all(requests); // Wait for all requests to complete
        alert("Training schedules saved successfully!");
        window.location.reload(); // Refresh the page after successful save
      } catch (error) {
        console.error("Error while saving training schedules:", error);
        alert("An error occurred while saving the training schedules.");
      }
    }
  };

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (isloading) {
    return <Loading />;
  }

  return (
    <div className="bg-gray-50 min-h-[100vh] newpages__input">
      <AllPagesNavbar />
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page">{menu || "Engine Master"}</h1>
        <div className="lg:flex items-center gap-5 my-1">
          <button
            onClick={() => {
              window.location.reload();
            }}
            className="border-l-indigo-900 rounded mx-2 text-white"
          >
            New
          </button>
          <button
            onClick={HandleSave}
            className="border-l-indigo-900 rounded mx-2 text-white"
          >
            Save
          </button>
        </div>
      </header>
      <aside className="m-5">
        <section className="lg:flex  gap-10">
          <div>
            <p>TRAINING SCHEDULE NO</p>
            <input
              type="text"
              className="h-9 px-1 text-right"
              value={sheduleNumber}
              readOnly
            />
          </div>
          <div>
            <p>TRAINING TYPE</p>
            <Select
              className="input_field text-left uppercase z-[100]"
              required
              onChange={handleselected}
              defaultValue={
                SelectedValueType
                  ? { label: SelectedValueType, value: SelectedValueType }
                  : { label: "", value: "" }
              }
              options={[{ value: "", label: "" }].concat(
                TrainingType.map((item) => ({
                  label: item.TRAININGTYPE,
                  value: item.TRAININGTYPE,
                }))
              )}
            ></Select>
          </div>
          <div>
            <p>TRAINING DAYS</p>
            <input
              type="number"
              className="h-9 px-1"
              value={SelectedValueDays}
              readOnly
            />
          </div>
          <div>
            <p>SHEDULE DATE</p>
            <input
              type="date"
              className="h-9 px-1"
              value={toDateFilter}
              onChange={(e) => setToDateFilter(e.target.value)}
            />
          </div>
        </section>
      </aside>
      <div className="z-10 lg:flex  flex-col w-full">
        <div>
          <div className="lg:flex mx-6">
            <p className="w-[70px]">Search:</p>
            <input
              className="w-[300px] h-7 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="overflow-y-auto h-[500px] px-4">
            <table className="custom-table  overflow__y__ relative text-[12px] ">
              <thead
                className="header-cell sticky top-[0px] left-[10px]"
                style={{ zIndex: 50 }}
              >
                <tr>
                  <td></td>
                  <td className="">
                    <p>EMP CODE</p>
                  </td>
                  <td className="">EMPNAME </td>
                  <td className="">EMAIL</td>
                  <td className="">DESIGNATION</td>
                  <td className="">DEPARTMENT</td>
                  {/* <td className="w-[120px]">EDIT</td> */}
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 &&
                  filteredData.map((item, index) => (
                    <tr
                      key={index}
                      style={{
                        textTransform: "uppercase",
                        cursor: "pointer",
                      }}
                    >
                      <td>
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            handleCheckboxChange(item, e.target.checked)
                          }
                          className="w-[20px]"
                        ></input>
                      </td>
                      <td className="">
                        {item.EMPCODE ? item.EMPCODE : "N/A"}
                      </td>
                      <td className="">
                        {item.EMPNAME ? item.EMPNAME : "N/A"}
                      </td>
                      <td>{item.EMAIL ? item?.EMAIL : "N/A"}</td>
                      <td>{item?.DESIGNATION ? item?.DESIGNATION : "N/A"}</td>
                      <td>{item?.DEPARTMENT ? item?.DEPARTMENT : "N/A"}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainingSchedule;
