import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { useSelector } from "react-redux";
import AllPagesNavbar from "../Navbar";
import Loading from "../Loading";
import "../../CSS/EngineMaster.css";
import { useLocation } from "react-router-dom";
import { BiSolidEditAlt } from "react-icons/bi";

const SCHUDEPILOTADMIN = () => {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const [PropellerList, setPropellerList] = useState([]);
  const userState = useSelector((state) => state.getUserState);
  const userLoggeduserCode = userState.user.userCode;
  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const localStartOfYear = new Date(
    startOfYear.getTime() - startOfYear.getTimezoneOffset() * 60000
  );
  const [startdate, setStartdate] = useState(localStartOfYear.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());
  const [search, setSearch] = useState("");
  console.log(userLoggeduserCode);
  const [isLoading, setIsLoading] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);

  useEffect(() => {
    if (userLoggeduserCode) {
      fetchDropdownData();
    }
  }, [userLoggeduserCode]);

  const fetchDropdownData = () => {
    setIsLoading(true);
    //   getPilotSchedules
    axios
      .get(`${BASEURL}/getPilotSchedules?PILOTCODE=${userLoggeduserCode}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        console.log(res.data);
        setPropellerList(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("Error while fetching /getPilotSchedules List", err);
      });
  };

  const filteredData = PropellerList.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = new Date(item["DEPARTUREDATE"]);
    const eDate = new Date(item["DEPARTUREDATE"]);

    const textMatch = searchData.every((term) => {
      return [
        "SCHEDULENO",
        "PILOTCODE",
        "PILOTNAME",
        "DEPARTUREDATE",
        "DEPARTURETIME",
        "DEPTFROM",
        "ARRIVALAT",
        "AIRCRAFTREGNO",
        "STATUS",
        "ACTUALDEPTDATE",
        "ACTUALDEPTTIME",
        "CREATEDBY",
        "CREATEDTIME",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= new Date(startdate)) &&
      (!enddate || eDate <= new Date(enddate));

    const statusMatch = !statusFilter || item.STATUS === statusFilter;

    return textMatch && dateMatch && statusMatch;
  });

  //   cancle STARTlTripSchedued

  const STARTlTripSchedued = async (item) => {
    const confirmSave = window.confirm(
      `Are you sure you want to start \nSCHEDULE NO: ${item.SCHEDULENO}`
    );
    const now = new Date();
    const currentDate = new Date().toISOString();

    // Extract the current hours, minutes, and seconds
    const hours = now.getHours().toString().padStart(2, "0"); // Format to 2 digits
    const minutes = now.getMinutes().toString().padStart(2, "0"); // Format to 2 digits
    const seconds = now.getSeconds().toString().padStart(2, "0"); // Format to 2 digits

    // Format the current time as HH:MM:SS
    const currentDateTime = `${hours}:${minutes}:${seconds}`;

    if (confirmSave) {
      setIsLoading(true);

      try {
        const response = await axios.post(
          `${BASEURL}/insertPilotSchedule`,
          {
            SCHEDULENO: item.SCHEDULENO,
            PILOTCODE: item.PILOTCODE,
            PILOTNAME: item.PILOTNAME,
            DEPARTUREDATE: item.DEPARTUREDATE.slice(0, 10),
            DEPARTURETIME: item.DEPARTURETIME.slice(11, 19),
            ARRIVALDATE: item.ARRIVALDATE.slice(0, 10),
            ARRIVALTIME: item.ARRIVATIME.slice(11, 19),
            DEPTFROM: item.DEPTFROM,
            ARRIVALAT: item.ARRIVALAT,
            RESTDAYS: item.RESTDAYS,
            RESTHOURS: item.RESTHOURS,
            NEXTAVAILABLEDATE: item.NEXTAVAILABLEDATE.slice(0, 19),
            AIRCRAFTREGNO: item.AIRCRAFTREGNO,
            STATUS: "DEPARTURE",
            CREATEDBY: userLoggeduserCode,
            ACTUALDEPTDATE: currentDate.slice(0, 10),
            ACTUALDEPTTIME: currentDateTime,
            ACTUALARRIVALDATE: "",
            ACTUALARRIVALTIME: "",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        );
        alert(response.data.message);
        window.location.reload();
        setIsLoading(false);
      } catch (error) {
        console.error("Error saving schedule:", error);
        setIsLoading(false);
        alert("Failed to save the schedule.");
      }
    }
  };
  const ENDlTripSchedued = async (item) => {
    const confirmSave = window.confirm(
      `Are you sure you want to End \nSCHEDULE NO: ${item.SCHEDULENO}`
    );
    const now = new Date();
    const currentDate = new Date().toISOString();

    // Extract the current hours, minutes, and seconds
    const hours = now.getHours().toString().padStart(2, "0"); // Format to 2 digits
    const minutes = now.getMinutes().toString().padStart(2, "0"); // Format to 2 digits
    const seconds = now.getSeconds().toString().padStart(2, "0"); // Format to 2 digits

    // Format the current time as HH:MM:SS
    const currentDateTime = `${hours}:${minutes}:${seconds}`;

    if (confirmSave) {
      setIsLoading(true);

      try {
        const response = await axios.post(
          `${BASEURL}/insertPilotSchedule`,
          {
            SCHEDULENO: item.SCHEDULENO,
            PILOTCODE: item.PILOTCODE,
            PILOTNAME: item.PILOTNAME,
            DEPARTUREDATE: item.DEPARTUREDATE.slice(0, 10),
            DEPARTURETIME: item.DEPARTURETIME.slice(11, 19),
            ARRIVALDATE: item.ARRIVALDATE.slice(0, 10),
            ARRIVALTIME: item.ARRIVATIME.slice(11, 19),
            DEPTFROM: item.DEPTFROM,
            ARRIVALAT: item.ARRIVALAT,
            RESTDAYS: item.RESTDAYS,
            RESTHOURS: item.RESTHOURS,
            NEXTAVAILABLEDATE: item.NEXTAVAILABLEDATE.slice(0, 19),
            AIRCRAFTREGNO: item.AIRCRAFTREGNO,
            STATUS: "ARRIVAL",
            CREATEDBY: userLoggeduserCode,
            ACTUALDEPTDATE: item.ACTUALDEPTDATE.slice(0, 10),
            ACTUALDEPTTIME: item.ACTUALDEPTTIME.slice(11, 19),
            ACTUALARRIVALDATE: currentDate.slice(0, 10),
            ACTUALARRIVALTIME: currentDateTime,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        );
        alert(response.data.message);
        window.location.reload();
        setIsLoading(false);
      } catch (error) {
        console.error("Error saving schedule:", error);
        setIsLoading(false);
        alert("Failed to save the schedule.");
      }
    }
  };
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="bg-gray-50 min-h-[100vh] newpages__input">
      <AllPagesNavbar />
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page">{menu}</h1>
      </header>

      <section className="lg:flex items-center gap-8 mx-5">
        <div className="lg:flex items-center gap-3">
          <p>Search:</p>
          <input
            className="w-[300px] h-7 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <label className=" text-gray-700">From Date</label>
          <input
            type="date"
            onChange={(e) => {
              setStartdate(e.target.value);
            }}
            value={startdate.split("T")[0]}
            placeholder="From Date"
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <label className=" text-gray-700">End Date</label>
          <input
            type="date"
            onChange={(e) => {
              setEnddate(e.target.value);
            }}
            value={enddate.split("T")[0]}
            placeholder="To Date"
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <p className=" text-gray-700">Status</p>
          <Select
            options={[
              { value: "SCHEDULE", label: "SCHEDULE" },
              { value: "DEPARTURE", label: "DEPARTURE" },
              { value: "ARRIVAL", label: "ARRIVAL" },
              { value: "CANCEL", label: "CANCEL" },
            ]}
            isClearable
            placeholder="Select.."
            onChange={(selectedOption) =>
              setStatusFilter(selectedOption ? selectedOption.value : null)
            }
            className="w-[200px] z-[100]"
          />
        </div>
      </section>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="overflow-y-auto h-[500px] px-4">
          <table className="custom-table  overflow__y__ relative text-[12px] ">
            <thead
              className="header-cell sticky top-[0px] left-[10px]"
              style={{ zIndex: 50 }}
            >
              <tr>
                <td className="text-right">SCHEDULE NO</td>

                <td>DEPARTURE DATE & TIME</td>

                <td>DEPARTURE FROM</td>
                <td>ARRIVAL AT</td>
                <td>ARRIVAL DATE & TIME</td>
                <td>REST DAYS</td>
                <td>REST HOURS</td>
                <td>NEXT AVAILABLE DATE</td>
                <td>AIRCRAFT REG NO</td>
                <td>ACTUAL DEPARTURE DATE & TIME</td>
                <td>ACTUAL ARRIVAL DATE & TIME</td>

                <td>CREATED BY</td>
                <td>CREATED TIME</td>
                <td>STATUS</td>

                <td>UPDATE</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    <td className=" text-right">
                      {item["SCHEDULENO"] ? item["SCHEDULENO"] : "NA"}
                    </td>
                    <td className="">
                      {item["DEPARTUREDATE"]
                        ? item["DEPARTUREDATE"].slice(8, 10) +
                          "/" +
                          item["DEPARTUREDATE"].slice(5, 7) +
                          "/" +
                          item["DEPARTUREDATE"].slice(0, 4) +
                          " " +
                          item["DEPARTURETIME"].slice(11, 19)
                        : "NA"}
                    </td>
                    <td className=" ">
                      {item["DEPTFROM"] ? item["DEPTFROM"] : "NA"}
                    </td>
                    <td className=" ">
                      {item["ARRIVALAT"] ? item["ARRIVALAT"] : "NA"}
                    </td>
                    <td className="">
                      {item["ARRIVALDATE"]
                        ? item["ARRIVALDATE"]?.slice(8, 10) +
                          "/" +
                          item["ARRIVALDATE"]?.slice(5, 7) +
                          "/" +
                          item["ARRIVALDATE"]?.slice(0, 4) +
                          " " +
                          item["ARRIVATIME"]?.slice(11, 19)
                        : "NA"}
                    </td>
                    <td className=" ">
                      {item["RESTDAYS"] ? item["RESTDAYS"] : "0"}
                    </td>
                    <td className=" ">
                      {item["RESTHOURS"] ? item["RESTHOURS"] : "0"}
                    </td>
                    <td className="">
                      {item["NEXTAVAILABLEDATE"]
                        ? item["NEXTAVAILABLEDATE"]?.slice(8, 10) +
                          "/" +
                          item["NEXTAVAILABLEDATE"]?.slice(5, 7) +
                          "/" +
                          item["NEXTAVAILABLEDATE"]?.slice(0, 4) +
                          " " +
                          item["NEXTAVAILABLEDATE"]?.slice(11, 19)
                        : "NA"}
                    </td>
                    <td className=" ">
                      {item["AIRCRAFTREGNO"] ? item["AIRCRAFTREGNO"] : "NA"}
                    </td>
                    <td className="">
                      {item["ACTUALDEPTDATE"]
                        ? item["ACTUALDEPTDATE"].slice(8, 10) +
                          "/" +
                          item["ACTUALDEPTDATE"].slice(5, 7) +
                          "/" +
                          item["DEPARTUREDATE"].slice(0, 4) +
                          " " +
                          item["ACTUALDEPTTIME"].slice(11, 19)
                        : "N/A"}
                    </td>
                    <td className="">
                      {item["ACTUALARRIVALDATE"]
                        ? item["ACTUALARRIVALDATE"].slice(8, 10) +
                          "/" +
                          item["ACTUALARRIVALDATE"].slice(5, 7) +
                          "/" +
                          item["ACTUALARRIVALDATE"].slice(0, 4) +
                          " " +
                          item["ACTUALARRIVATIME"].slice(11, 19)
                        : "N/A"}
                    </td>
                    <td className=" ">
                      {item["CREATEDBY"] ? item["CREATEDBY"] : "NA"}
                    </td>
                    <td className=" ">
                      {item["CREATEDTIME"]
                        ? item["CREATEDTIME"]?.slice(11, 19)
                        : "NA"}
                    </td>{" "}
                    <td className=" ">
                      {item["STATUS"] ? item["STATUS"] : "NA"}
                    </td>{" "}
                    <td className=" ">
                      {item["STATUS"] !== "CANCEL" &&
                        item["STATUS"] === "SCHEDULE" && (
                          <div>
                            {" "}
                            <button
                              className="px-1 rounded py-1 border-none outline-none"
                              onClick={() => {
                                STARTlTripSchedued(item);
                              }}
                            >
                              START
                            </button>
                          </div>
                        )}
                      {item["STATUS"] !== "CANCEL" &&
                        item["STATUS"] === "DEPARTURE" && (
                          <div>
                            {" "}
                            <button
                              className="px-1 rounded py-1 border-none outline-none"
                              onClick={() => {
                                ENDlTripSchedued(item);
                              }}
                            >
                              END
                            </button>
                          </div>
                        )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SCHUDEPILOTADMIN;
