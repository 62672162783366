import React from "react";
import Footer from "./Footer";
const ForgotPwd = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
          fontSize: "30px",
        }}
      >
        Contact you Adminstrator.
        {/*  */}
      </div>
      <Footer />
    </div>
  );
};

export default ForgotPwd;
