import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logIn, logOut } from "../../Redux/actions/authAction";
import Axios from "axios";
import { legacy_createStore } from "redux";
import Footer from "./Footer";
import { logInStatus } from "../../Redux/actions/authAction";
import "../CSS/Login.css";
import Loading from "../JS/Loading";
import LoginImg from "../Images/loginfly.jpg";
import Select from "react-select";

function Login() {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errData, setErrData] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [modeOfLogin, setModeOfLogin] = useState("");
  const [modeOfLoginValue, setModeOfLoginValue] = useState("");
  // const [userType, setUserType] = useState("");
  // const myState = useSelector((state) => state.changeTheState);
  const loginState = useSelector((state) => state.getUserState);
  console.log(loginState?.user?.userName);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function clearState() {
    window.sessionStorage.clear();
    dispatch(logIn({}));
    dispatch(logInStatus("FALSE"));
  }
  useEffect(clearState, []);

  function handleClick() {
    setIsLoading(true);
    if (modeOfLoginValue === "") {
      alert("Please select mode Of Login ");

      return;
    }
    Axios.post(`${BASEURL}/login`, {
      email: email,
      password: password,
      MODULENAME: modeOfLoginValue,
    })
      .then((response) => {
        if (response.data.message) {
          setIsLoading(false);
          setErrData(response.data.message);
        } else if (response.data.user) {
          setIsLoading(false);
          dispatch(logIn(response.data));
          window.sessionStorage.setItem(
            "auth-token",
            response.data.authenticationToken
          );
          window.sessionStorage.setItem(
            "sclientSecret",
            response.data.sclientSecret
          );
          dispatch(logInStatus("TRUE"));
          if (modeOfLoginValue === "FLY OPERATIONS") {
            navigate("/Main");
          }
          if (modeOfLoginValue === "PILOT PORTAL") {
            navigate("/PilotDashboard");
          }
        } else {
        }
      })
      .catch((error) => {
        console.error("An error occurred while logging in:", error);
        alert("Unauthorized Connection");
        setIsLoading(false);
      });
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };
  //PASWORD
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const Imgfun = () => {
    setImageData(LoginImg);
  };

  useEffect(() => {
    Imgfun();
  }, []);

  const renderList = (
    <div className="main__for__login">
      <section className="pic__align">
        <div className="To_center_form">
          <div className="margintest">
            {" "}
            <h2 className="techlogheading">TECHLOG APPLICATION</h2>
          </div>
          <section className="form">
            <span className="skew-shake-x">{errData}</span>

            <h1 className="for_sign_in_heading">Sign In</h1>
            <header className="form_aliging_size">
              <div className="mb-6">
                <label className="for_lable_form">Mode Of Login</label>
                <Select
                  className="for_login_input"
                  required
                  placeholder="Select mode of login..."
                  value={
                    modeOfLogin
                      ? {
                          label: modeOfLogin,
                          value: modeOfLogin,
                        }
                      : {
                          label: "",
                          value: "",
                        }
                  }
                  onChange={(e) => {
                    setModeOfLogin(e.label);
                    setModeOfLoginValue(e.value);
                  }}
                  options={[
                    { label: "Select mode of login...", value: "" },
                    { label: "FLY OPERATIONS", value: "FLY OPERATIONS" },
                    { label: "PILOT PORTAL", value: "PILOT PORTAL" },
                  ]}
                />
              </div>
              <label className="for_lable_form" htmlFor="user_name">
                Email :
              </label>
              <input
                className="for_login_input"
                type="email"
                // class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onKeyPress={handleKeyPress}
              />

              <label className="for_lable_form" htmlFor="user_name">
                Password:
              </label>
              <input
                className="for_login_input"
                type={passwordShown ? "text" : "password"}
                // class="form-control"
                id="exampleInputPassword1"
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onKeyPress={handleKeyPress}
              />
              <div className="checkbox__show__password">
                <p> Show Password</p>
                <input
                  className="checkbox"
                  type="checkbox"
                  onClick={togglePassword}
                />
              </div>

              <div className="to_center_pass_btn">
                <button className="sign" onClick={handleClick} type="submit">
                  Sign In
                </button>
                <p className="ptag">
                  <p> Forgot Password?</p>
                  <p className="line">
                    <Link to="/forgotpwd" className="click">
                      Click Here
                    </Link>
                  </p>
                </p>
              </div>
            </header>
          </section>
        </div>
        <picture className="pic__name">
          {/* <img
            className="image__style"
            src={LoginImg}
            alt=""
            width="600"
            height="450"
          /> */}
          {imageData ? (
            <img
              src={imageData}
              width="600"
              height="450"
              alt="Image"
              className="image__style"
              loading="lazy"
            />
          ) : (
            <div className="default-image-placeholder">
              {/* Default styling when imageData is not available */}
              <div id="card">
                <div class="card-image">
                  <div class="block pulsate"></div>
                  <div class="block pulsate"></div>
                  <div class="block pulsate"></div>
                </div>
                <div class="card-image">
                  <div class="block pulsate"></div>
                  <div class="block pulsate"></div>
                </div>
                <div class="card-content">
                  <div class="block2 pulsate"></div>
                  <div class="block3 pulsate"></div>
                  <div class="circle__login pulsate"></div>
                  <div></div>
                </div>
              </div>
            </div>
          )}
        </picture>
      </section>
      <Footer />
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
}

export default Login;
