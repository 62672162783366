const innitialState = 0;

export const insertTechlogReducer = (state = innitialState, action) => {
  switch (action.type) {
    case "SETNO":
      return (state = action.payload);

    default:
      return state;
  }
};
