import React, { useEffect, useState, useRef } from "react";
import { techlogDocno } from "../../Redux/actions/techlogActions";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "../JS/Footer";
import "../CSS/OlderDocument.css";
import Loading from "./Loading";
import Navbar from "./Navbar";
const OlderDocument = () => {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [datas, setDatas] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [pilotname, setPilotName] = useState("");
  const [aircraft, setAircraft] = useState("");
  const [searchterm, setsearchterm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  async function getIntData() {
    try {
      if (!authToken) {
        navigate("/");
      } else {
        setIsLoading(true);
        const response = await Axios.get(`${BASEURL}/getolderdocuments`, {
          headers: {
            "auth-token": authToken,
            "session-token": sclientSecret,
          },
        });
        setDatas(response.data.recordset);
        console.log(response.data.recordset);
        setFilteredData(response.data.recordset);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
      setIsLoading(false);
      // Handle error appropriately, e.g., show error message to the user
    }
  }

  useEffect(() => {
    getIntData();
  }, []);
  function handleFilter() {
    const filteredArray = datas.filter((item) => {
      const itemDate = new Date(item.OT_TECH_LOG_DATE); // Assuming each item has a date property

      if (fromDate && toDate) {
        return itemDate >= new Date(fromDate) && itemDate <= new Date(toDate);
      } else if (fromDate) {
        return itemDate >= new Date(fromDate);
      } else if (toDate) {
        return itemDate <= new Date(toDate);
      }

      return true;
    });

    setFilteredData(filteredArray);
  }

  const renderList = (
    <div>
      <Navbar />
      <div className="content">
        <h2 className="">
          <h3 style={{ textAlign: "center" }}>
            {menu || "LIST OF TECHLOG DOCUMENTS"}
          </h3>
          <Link className="link__defoult" to="/main">
            <button className="button__fuel_back">BACK</button>{" "}
          </Link>{" "}
        </h2>

        <div className="defoult__filter">
          <h3 className="font-bold text-lg">filter by </h3>
          <table className="filter_table_old_doc">
            <tr>
              <div className="older__align">
                <aside>
                  <td>
                    <p>SEARCH</p>
                  </td>
                  <td></td>
                  <td>
                    <p>
                      <input
                        type="text"
                        onChange={(e) => {
                          setsearchterm(e.target.value);
                        }}
                      />
                    </p>
                  </td>
                </aside>
                {/* <aside>
                  <td>
                    <p>FLIGHT:</p>
                  </td>
                  <td></td>
                  <td>
                    <p>
                      <input
                        type="text"
                        onChange={(e) => {
                          setsearchterm(e.target.value);
                        }}
                      />
                    </p>
                  </td>
                </aside> */}
              </div>
            </tr>
            <tr>
              <div className="older__align">
                <aside>
                  <td>
                    <p>FROM DATE: </p>
                  </td>
                  <td></td>
                  <td>
                    <p>
                      <input
                        type="date"
                        onChange={(e) => {
                          setFromDate(e.target.value);
                        }}
                      />
                    </p>
                  </td>
                </aside>
                <aside>
                  <td>
                    <p>TO DATE: </p>
                  </td>

                  <td>
                    <p>
                      <input
                        type="date"
                        onChange={(e) => {
                          setToDate(e.target.value);
                        }}
                      />
                    </p>
                  </td>
                </aside>
                <aside>
                  <td>
                    <button
                      className="button__fuel_back"
                      onClick={handleFilter}
                    >
                      Filter
                    </button>
                  </td>
                </aside>
              </div>
            </tr>
          </table>
        </div>
        <section className="overflow__margin">
          <table className="filter__table">
            <tr className="first__tr__filter new__filter">
              <td>
                {" "}
                <p> DOC NO</p>
              </td>
              <td>
                <p> FLIGHT</p>
              </td>
              <td>
                <p> CAPTAIN</p>
              </td>
              <td>
                <p> CO-PILOT </p>
              </td>

              <td>
                <p> DATE</p>
              </td>
              <td>
                {" "}
                <p> DETAILS</p>
              </td>
            </tr>
            {filteredData
              .filter((data) => {
                if (searchterm === "") {
                  return data;
                } else if (
                  data.OT_TECH_LOG_AIRLINE_REG_NO.toString()
                    .toLowerCase()
                    .includes(searchterm.toString().toLowerCase())
                ) {
                  return data;
                } else if (
                  data.OT_TECH_LOG_CAPTAIN.toString()
                    .toLowerCase()
                    .includes(searchterm.toString().toLowerCase())
                ) {
                  return data;
                } else if (
                  data.OT_TECH_LOG_CO_PILOT.toString()
                    .toLowerCase()
                    .includes(searchterm.toString().toLowerCase())
                ) {
                  return data;
                } else if (
                  data.OT_TECH_LOG_DOC_NO_SERIAL?.toString()
                    .toLowerCase()
                    .includes(searchterm.toString().toLowerCase())
                ) {
                  return data;
                } else if (
                  data.OT_TECH_LOG_DOC_NO?.toString()
                    .toLowerCase()
                    .includes(searchterm.toString().toLowerCase())
                ) {
                  return data;
                }
              })
              .map((data) => {
                return (
                  <tr className="older__doc___table">
                    <td className="numeric__right ">
                      {data.OT_TECH_LOG_DOC_NO
                        ? data.OT_TECH_LOG_DOC_NO
                        : "N/A"}
                    </td>
                    <td>
                      {data.OT_TECH_LOG_AIRLINE_REG_NO
                        ? data.OT_TECH_LOG_AIRLINE_REG_NO
                        : "N/A"}
                    </td>
                    <td>
                      {data.OT_TECH_LOG_CAPTAIN
                        ? data.OT_TECH_LOG_CAPTAIN
                        : "N/A"}
                    </td>

                    <td>
                      {data.OT_TECH_LOG_CO_PILOT
                        ? data.OT_TECH_LOG_CO_PILOT
                        : "N/A"}
                    </td>
                    <td>
                      {data.OT_TECH_LOG_DATE
                        ? data.OT_TECH_LOG_DATE.substr(0, 10)
                            .split("-")
                            .reverse()
                            .join("-")
                        : "N/A"}
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          dispatch(techlogDocno(data.OT_TECH_LOG_DOC_NO));
                          navigate("/olderdetails");
                        }}
                        className="button__older__doc"
                      >
                        SEE DETAILS
                      </button>
                    </td>
                  </tr>
                );
              })}
          </table>
        </section>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
  return <>{isLoading ? <Loading /> : renderList}</>;
};

export default OlderDocument;
