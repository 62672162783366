export const insertTechlog = (data) => {
  return {
    type: "INSERTTECHLOG",
    payload: data,
  };
};

export const techlogDocno = (data) => {
  return {
    type: "SETNO",
    payload: data,
  };
};
