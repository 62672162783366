import React from "react";
import "../CSS/Loading.css";

const Loading = () => {
  return (
    <div>
      <div className="loading__to__center">
        <div>
          {" "}
          <span class="loader"></span>
        </div>
        <h4>Loading...</h4>
      </div>
    </div>
  );
};

export default Loading;
