import rootReducer from "./Reducers/index";
import { createStore } from "redux";

const SESSION_TIMEOUT = 60 * 60 * 1000; // One hour in milliseconds

function saveToSessionStorage(state) {
  try {
    const serialisedState = JSON.stringify(state);
    sessionStorage.setItem("persistentState", serialisedState);
  } catch (e) {
    console.warn(e);
  }
}

function loadFromSessionStorage() {
  try {
    const serialisedState = sessionStorage.getItem("persistentState");
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

function clearSessionStorage() {
  sessionStorage.removeItem("persistentState");
  window.sessionStorage.clear();
}

const store = createStore(
  rootReducer,
  loadFromSessionStorage(),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(() => saveToSessionStorage(store.getState()));

// Set a timeout to clear session storage after one hour
let sessionTimeout;

function resetSessionTimeout() {
  if (sessionTimeout) {
    clearTimeout(sessionTimeout);
  }
  sessionTimeout = setTimeout(clearSessionStorage, SESSION_TIMEOUT);
}

// Call this function whenever you want to reset the timer, e.g., after user activity
function onUserActivity() {
  resetSessionTimeout();
}

// Set up event listeners to track user activity, for example:
document.addEventListener("mousemove", onUserActivity);
document.addEventListener("keypress", onUserActivity);
// Add other events that you want to consider as user activity

resetSessionTimeout(); // Start the session timeout initially

export default store;
