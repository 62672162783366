import "./App.css";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from "../src/Components/JS/Dashboard";
import ForgotPwd from "../src/Components/JS/ForgotPwd.jsx";
import Login from "../src/Components/JS/Login";
import Loading from "./Components/JS/Loading";
import NavlogDashboard from "./Components/JS/Navlog/NavlogDashboard";
import NotFound from "../src/Components/JS/NotFound";
import Main from "../src/Components/JS/Main";
import OlderDocument from "../src/Components/JS/OlderDocument";
import FuelExpence from "../src/Components/JS/FuelExpence";
import DisableAutocomplete from "../src/Components/JS/DisableAutocomplete";
import OlderDocumentDetails from "./Components/JS/OlderdocumentDetails";
import Navbar from "../src/Components/JS/Navbar";
import Sidebar from "../src/Components/JS/Sidebar";
import FuelRate from "./Components/JS/FuelRate";
import Registration from "./Components/JS/Registration";
import ENGINECREATION from "./Components/JS/ENGINECREATION.jsx";
import PROPELLERCREATION from "./Components/JS/PROPELLERCREATION.jsx";
import ENGINEOVERHAUL from "./Components/JS/ENGINEOVERHAUL.jsx";
import PROPELLEROVERHAUL from "./Components/JS/PROPELLEROVERHAUL.jsx";
import PILOTSCHEDULES from "./Components/JS/PILOTSCHEDULES.jsx";
import TRAININGACTUALS from "./Components/JS/TRAININGACTUALS.jsx";

//
// pilot pages
import PilotDashboard from "./Components/JS/Pilot/PilotDashboard.jsx";
import MYTECHSHEET from "./Components/JS/Pilot/MYTECHSHEET.jsx";
import MYMILESCOVERED from "./Components/JS/Pilot/MYMILESCOVERED.jsx";
import MYHOURSFLOWN from "./Components/JS/Pilot/MYHOURSFLOWN.jsx";
import MYTAKEOFFSANDLANDINGS from "./Components/JS/Pilot/MYTAKEOFFSANDLANDINGS.jsx";
import MYSCHEDULE from "./Components/JS/Pilot/MYSCHEDULE.jsx";
import MyTrainingSchedules from "./Components/JS/Pilot/MyTrainingSchedules.jsx";

// import PilotDefect from "../src/Components/JS/PilotDefect";
// const LazyDashbord = React.lazy(() => import("../src/Components/JS/Dashboard"));

import TrainingSchedule from "./Components/JS/TrainingSchedule.jsx";
import TrainingType from "./Components/JS/TrainingType.jsx";
function App() {
  const authToken = window.sessionStorage.getItem("auth-token");
  // useEffect(() => {
  //   if (!authToken) {
  //     Navigate("/");
  //   }
  // }, []);
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Main" element={<Main />} />
          <Route path="/Loading" element={<Loading />} />
          <Route path="/LISTOFTECHLOGDOCUMENTS" element={<OlderDocument />} />
          <Route path="/FUELEXPENSES" element={<FuelExpence />} />
          <Route path="/olderdetails" element={<OlderDocumentDetails />} />
          <Route path="/Navbar" element={<Navbar />} />
          <Route path="/Sidebar" element={<Sidebar />} />
          <Route path="/fuelrate" element={<FuelRate />} />
          <Route path="/PILOTSCREATION" element={<Registration />} />
          <Route path="/TRAININGSCHEDULES" element={<TrainingSchedule />} />
          <Route
            path="/DisableAutocomplete"
            element={<DisableAutocomplete />}
          />
          <Route path="/*" element={<NotFound />} />
          {/* <Route
            path="dashboard"
            element={
              <React.Suspense fallback="loading...">
                <LazyDashbord />
              </React.Suspense>
            }
          /> */}
          <Route path="/TECHLOGDOCUMENTCREATION" element={<Dashboard />} />
          <Route path="/ENGINECREATION" element={<ENGINECREATION />} />
          <Route path="/PROPELLERCREATION" element={<PROPELLERCREATION />} />
          <Route path="/ENGINEOVERHAUL" element={<ENGINEOVERHAUL />} />
          <Route path="/PROPELLEROVERHAUL" element={<PROPELLEROVERHAUL />} />
          <Route path="/ForgotPwd" element={<ForgotPwd />} />
          {/* <Route path="/PilotDefect" element={<PilotDefect />} /> */}
          {/* <Route path="/navlog" element={<NavlogDashboard />} /> */}
          <Route path="/PilotDashboard" element={<PilotDashboard />} />
          <Route path="/MYTECHSHEET" element={<MYTECHSHEET />} />
          <Route path="/MYMILESCOVERED" element={<MYMILESCOVERED />} />
          <Route path="/MYHOURSFLOWN" element={<MYHOURSFLOWN />} />
          <Route
            path="/MYTAKEOFFSANDLANDINGS"
            element={<MYTAKEOFFSANDLANDINGS />}
          />
          <Route path="/TRAININGTYPES" element={<TrainingType />} />
          <Route path="/MYSCHEDULE" element={<MYSCHEDULE />} />
          <Route path="/PILOTSCHEDULES" element={<PILOTSCHEDULES />} />
          <Route path="/TRAININGACTUALS" element={<TRAININGACTUALS />} />
          <Route
            path="/MyTrainingSchedules"
            element={<MyTrainingSchedules />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
