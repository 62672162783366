import loginStateReducer from "./loginStateReducer";
import { insertTechlogReducer } from "./techlogReducer";
import { combineReducers } from "redux";
// import { insertTechlogReducer } from "./techlogReducer";
import getUserState from "./getUserState";
import updateUserRights from "./userRightsReducer";
const rootReducer = combineReducers({
  getUserState,
  loginStateReducer,
  insertTechlogReducer,
  updateUserRights,
});

export default rootReducer;
