import React, { useEffect, useState, useRef } from "react";
import "../CSS/Dashboard.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { logIn } from "../../../src/Redux/actions/authAction";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import {
  HiOutlineChevronDoubleRight,
  HiOutlineChevronRight,
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronLeft,
} from "react-icons/hi";
import { insertTechlog } from "../../Redux/actions/techlogActions";
import DisableAutocomplete from "../JS/DisableAutocomplete";
import Footer from "./Footer";
import Loading from "./Loading";
import axios from "axios";
import Navbar from "./Navbar";

const Dashboard = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const [piloname, setPilotname] = useState("SELECT");
  const [copiloname, setcoPilotname] = useState("SELECT");
  const [isLoading, setIsLoading] = useState(false);
  const [engineHoursBf, setengineHoursBf] = useState("");
  const [hoursFlownbf, sethoursFlownbf] = useState("");
  const [milage, setmilage] = useState(0);
  const [pilotslist, setPilotslist] = useState([]);
  const [aircraft, setAircraft] = useState([]);
  const [pilot, setPilot] = useState("");
  const [copilot, setCopilot] = useState("");
  var [docno, setDocno] = useState("");
  const [routelist, setRouteList] = useState([]);
  const [checksoffTime, setCheckoffTime] = useState("");
  const [checksonTime, setCheckonTime] = useState("");
  const [checktimediff, setChecktimediff] = useState(0);
  const [flightTakeoffTime, setFlightTakeoffTime] = useState("");
  const [flightLandingTime, setFlightLandingTime] = useState("");
  const [flyTime, setFlytime] = useState("");
  const [serialNo, setSerialNumber] = useState("");
  const [aircrafttype, setType] = useState("");
  const [rowLength, setRowLength] = useState(1);
  const [rowArray, setRowArray] = useState([{ id: 1 }]);
  const [intState, setIntState] = useState("log");
  const [engArray, setEngArray] = useState([]);
  const [datass, setData] = useState([]);
  const [gstatus, setStatus] = useState(false);
  const [fromRoute, setfromRoute] = useState("");
  const [ToRoute, setToRoute] = useState("");
  const [operationtype, setOperationType] = useState("Mara");
  //subit action
  const [isSubmitting, setIsSubmitting] = useState(false);
  const myState = useSelector((state) => state.getUserState);

  const navigate = useNavigate();

  const loginState = useSelector((state) => state.loginStateReducer);

  //
  function getIntData() {
    console.log(myState);
    // setIsLoading(true);
    if (!authToken) {
      navigate("/");
    }
  }
  // useEffect(getIntData, []);
  //logout
  const dispatch = useDispatch();
  function logOut() {
    dispatch(logIn([]));
    navigate("/");
  }
  //submit function

  const handleButtonClick = () => {
    if (window.confirm("Are you sure you want to save these values ?")) {
      if (inparray.milage > 0) {
        setNextfromroute(inparray.toRoute);
        setIsSubmitting(true);

        datas.push(inparray);
        rowArray.push({ id: rowLength + 1 });
        setRowLength(rowLength + 1);

        // handleReset();
        getTotalbf();

        setCurLeg(Number(curleg) + 1);
        setInpArray({
          leg: "",
          docno: 0,
          fromRoute: "",
          toRoute: "",
          chksoff: "00:00",
          chksOn: "00:00",
          blockTime: "",
          blockTimeDesc: "",
          takeOff: "00:00",
          landing: "00:00",
          flyingtime: "",
          flyTimeDesc: "",
          eclh: 0,
          ecrh: 0,
          fulh: 0,
          furh: 0,
          eorh: 0,
          eolh: 0,
          createddate: "",
          createdby: "",
          milage: 0,
        });
        document.getElementById("test").value = "";
        document.getElementById("test1").value = "";
        document.getElementById("test3").value = "";
        document.getElementById("test4").value = "";
        document.getElementById("test5").value = "";
        document.getElementById("test6").value = "";
        document.getElementById("test7").value = "";
      } else {
        alert("Mileage must be greater than zero");
      }
    }
  };

  const [engineInput, setEngineInput] = useState({
    techlogNo: "",
    leftorright: "R/H",
    techlogenginestaritt: "",
    techlogenginestarttime: "",
    techlogenginetorque: "",
    techlogenginerpm: "",
    techlogengineegt: "",
    techlogenginefuelflow: "",
    techlogenginefuelflow: "",
    techlogengineoiltemp: "",
    techlogengineoilpress: "",
    techlogenginediffpress: "",
    techlogenginegenload: "",
  });
  const [leftengineInput, setLeftEngineInput] = useState({
    techlogNo: "",
    leftorright: "L/H",
    techlogenginestaritt: "",
    techlogenginestarttime: "",
    techlogenginetorque: "",
    techlogenginerpm: "",
    techlogengineegt: "",
    techlogenginefuelflow: "",
    techlogengineoiltemp: "",
    techlogengineoilpress: "",
    techlogenginediffpress: "",
    techlogenginegenload: "",
  });
  const [headerdetails, setHeaderdetails] = useState({
    docno: 0,
    serialno: "",
    aircraftreg: "",
    pilotcode: "",
    copilotcode: "",
    techlogdate: "",
    aircrafttype: "",
    aircraftserialno: "",
    hoursbf: 0,
    hoursflown: 0,
    hourscf: 0,
    enginecyclesbf: 0,
    enginecyclesflown: 0,
    enginecyclescf: 0,
    startfuelleft: 0,
    stopfuelleft: 0,
    startfuelrh: 0,
    stopfuelrh: 0,
    ias: 0,
    oat: 0,
    pressalt: 0,
    coadueon: "00:00",
    nextchecktype: "",
    nextchecktypedueataf: "00:00",
    nextchecktypehours: 0,
    techlogcreatedby: "",
    techlogcreateddate: "00:00",
    mileage: 0,
    operationtype: operationtype,
    ENGINESERIAL: "",
    PROPELLER1SERIAL: "",
    PROPELLER2SERIAL: "",
  });
  const [msg, setMsg] = useState("");
  const [engineCyclelh, setengineCyclelh] = useState("");
  const [engineCyclerh, setengineCyclerh] = useState("");
  const [inparray, setInpArray] = useState({
    leg: "",
    docno: 0,
    fromRoute: "",
    toRoute: "",
    chksoff: "00:00",
    chksOn: "00:00",
    blockTime: "",
    blockTimeDesc: "",
    takeOff: "00:00",
    landing: "00:00",
    flyingtime: "",
    flyTimeDesc: "",
    eclh: 0,
    ecrh: 0,
    fulh: 0,
    furh: 0,
    eorh: 0,
    eolh: 0,
    createddate: "00:00",
    createdby: "no input",
    milage: 0,
  });
  const [datas, setDatas] = useState([]);
  const [pilotdefect, setPilotDefect] = useState({
    techlogNo: "",
    deffectNo: "",
    defect: "",
    createdby: "",
  });
  const [deffectNoinp, setDeffectNo] = useState("");
  const [nextfromroute, setNextfromroute] = useState("");
  var currenttime = moment().format("DD/MM/YYYY");
  const [hoursbfCountTotal, sethoursbfCountTotal] = useState(0);
  const [engCycletotal, setengCycletotal] = useState(0);
  const [flytimetotal, setflytimetotal] = useState(0);
  const [fuelUpliftLH, setfuelUpliftLH] = useState(0);
  const [fuelUpliftRH, setfuelUpliftRH] = useState(0);
  // function GetMilaege() {
  //   var total = datas.reduce((acc, item) => {
  //     console.log(acc);
  //     console.log(item);
  //     return acc + Number(item.milage);
  //   }, 0);
  //   console.log(total);
  //   setmilage(total);
  // }
  function getTotalbf() {
    var total = 0;
    var engcycletotale = 0;
    var hrsflttal = 0;
    console.log(datas);
    for (var i = 0; i < datas.length; i++) {
      total = Number(
        Number(total) +
          Number(datas[i].blockTime) +
          Number(`0.${datas[i].blockTimeDesc}`)
      ).toFixed(2);
      sethoursbfCountTotal(total);
      headerdetails.hourscf = total + Number(hoursFlownbf);
      headerdetails.hoursflown = total;
    }
    // for (var i = 0; i < datas.length; i++) {
    var total = datas.reduce((acc, item) => {
      console.log(acc);
      console.log(item);
      return acc + Number(item.milage);
    }, 0);
    console.log(total);
    headerdetails.mileage = total;
    setmilage(total);
    console.log(headerdetails.mileage);
    // total = Number(
    //   Number(total) + Number(datas[i].milage)
    //   // +
    //   // Number(`0.${datas[i].blockTimeDesc}`)
    // ).toFixed(2);
    // console.log(total);
    // headerdetails.hourscf = total + Number(milage);
    // // headerdetails.hoursflown = total;
    // }
    for (var i = 0; i < datas.length; i++) {
      engcycletotale =
        engcycletotale + Number(datas[i].ecrh) + Number(datas[i].eclh);
      setengCycletotal(engcycletotale);
      headerdetails.enginecyclesflown = engcycletotale;
      headerdetails.enginecyclescf =
        engcycletotale + Number(engineCyclelh) + Number(engineCyclerh);
    }
    for (var i = 0; i < datas.length; i++) {
      hrsflttal = Number(
        Number(hrsflttal) +
          Number(datas[i].flyingtime) +
          Number(`0.${datas[i].flyTimeDesc}`)
      ).toFixed(2);
      setflytimetotal(hrsflttal);
      headerdetails.hourscf = hrsflttal;
    }
    var upliftLh = 0;
    var upliftRh = 0;
    for (var i = 0; i < datas.length; i++) {
      upliftLh = upliftLh + Number(datas[i].fulh);
      setfuelUpliftLH(upliftLh);
    }
    for (var i = 0; i < datas.length; i++) {
      upliftRh = upliftRh + Number(datas[i].furh);
      setfuelUpliftRH(upliftRh);
    }
  }
  function handleHeaderDataChange(e) {
    const { name, value } = e.target;
    setHeaderdetails({ ...headerdetails, [name]: value });
  }
  function handleReset() {
    setInpArray({
      leg: "",
      docno: "",
      fromRoute: "",
      toRoute: "",
      chksoff: "",
      chksOn: "",
      blockTime: "",
      takeOff: "",
      landing: "",
      flyingtime: "",
      eclh: "",
      ecrh: "",
      fulh: "",
      furh: "",
      eorh: "",
      eolh: "",
      createddate: "",
      createdby: "",
      milage: 0,
    });
  }
  function handleToRouteReset() {
    inparray.toRoute = "";
  }
  function handleEngineReadingDataChange(e) {
    const { name, value } = e.target;
    setEngineInput({ ...engineInput, [name]: value });
  }
  function handleLeftEngineReadingDataChange(e) {
    const { name, value } = e.target;
    setLeftEngineInput({ ...leftengineInput, [name]: value });
  }
  function handleChange(e) {
    const { name, value } = e.target;
    setInpArray({ ...inparray, [name]: value });
  }
  const [myDataNext, setMyData] = useState(0);
  const getPilots = async () => {
    try {
      const pilotsResponse = await Axios.get(`${BASEURL}/getpilots`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      });
      setPilotslist(pilotsResponse.data.recordset);
    } catch (error) {
      console.error("Error fetching pilots:", error);
    }

    try {
      const aircraftResponse = await Axios.get(`${BASEURL}/getircrafts`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      });
      setAircraft(aircraftResponse.data.recordset);
    } catch (error) {
      console.error("Error fetching aircrafts:", error);
    }

    try {
      const docResponse = await Axios.get(`${BASEURL}/getDoc`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      });
      const docno = docResponse.data.recordset[0].NEXTNO;
      setDocno(docno);
      setMyData(docno);

      try {
        const defectNoResponse = await Axios.post(
          `${BASEURL}/getDefectNo`,
          { docno },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        );
        setDeffectNo(defectNoResponse.data.recordset[0].DEFECNO);
      } catch (error) {
        console.error("Error fetching defect number:", error);
      }
    } catch (error) {
      console.error("Error fetching document number:", error);
    }

    try {
      const routeResponse = await Axios.get(`${BASEURL}/getRoutes`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      });
      setRouteList(routeResponse.data.recordset);
    } catch (error) {
      console.error("Error fetching routes:", error);
    }
  };

  function checkIfempty(obj) {
    for (var key in obj) {
      if (obj[key] === null || obj[key] === "") return false;
    }
    return true;
  }

  useEffect(() => {
    getPilots();
    getIntData();
  }, []);
  const scrollContainerRef = useRef(null);
  const [activeButton, setActiveButton] = useState("left");

  const scrollToStart = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft = 0;
      setActiveButton("left");
    }
  };

  const scrollToEnd = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft =
        scrollContainerRef.current.scrollWidth;
      setActiveButton("right");
    }
  };
  useEffect(getTotalbf, [headerdetails, inparray]);
  const pilotDeffRenderList = <></>;
  const [curleg, setCurLeg] = useState(1);

  // function handleFromRoute(value) {
  //   console.log(value);
  //   setfromRoute(value);
  // }
  // function handleToRoute(value) {
  //   console.log(value);
  //   setToRoute(value);

  //   if (!value || !fromRoute) {
  //     alert("Please select the Route");
  //     return;
  //   }

  //   axios.post(``)
  // }

  useEffect(() => {
    const fetchMileage = async () => {
      if (inparray.fromRoute && inparray.toRoute) {
        console.log(inparray.fromRoute);
        console.log(inparray.toRoute);
        try {
          const response = await Axios.post(
            `${BASEURL}/getmileageforSelectedRoute`,
            {
              FROMCITY: inparray.fromRoute,
              TOCITY: inparray.toRoute,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sclientSecret,
              },
            }
          );
          console.log(response?.data?.recordset[0]);
          const mileage = response?.data?.recordset[0];
          console.log(mileage?.MILEAGE);
          setInpArray((prevState) => ({
            ...prevState,
            milage: mileage?.MILEAGE,
          }));
        } catch (error) {
          console.error("Error fetching mileage:", error);
        }
      }
    };

    fetchMileage();
  }, [inparray.fromRoute, inparray.toRoute, authToken, BASEURL]);

  const techlogRenderList = (
    <>
      <div className="arrow__go__end">
        {activeButton !== "left" && (
          <p className="arrow__go__end" onClick={scrollToStart}>
            <HiOutlineChevronDoubleLeft />
          </p>
        )}
        <p>&nbsp;</p>
        {activeButton !== "right" && (
          <p className="arrow__go__end" onClick={scrollToEnd}>
            <HiOutlineChevronDoubleRight />
          </p>
        )}
      </div>
      <div ref={scrollContainerRef} className="scroll-container">
        {" "}
        <section className="arrow__and__header">
          <h2 className={`reading_header ${gstatus ? "test" : ""}`}>
            main table
          </h2>
        </section>
        <main
          style={{
            display: "inline-block",

            // background: "#f0f0f0",
          }}
        >
          {/* <ul className={`navbar-menu ${isOpen ? "is-open" : ""}`}> */}

          <table
            hidden={gstatus ? true : false}
            className={`main_table1 ${gstatus ? "test" : ""}`}
          >
            <tr className="tr_main_table">
              <th className="main_table1_th Leg_to_sticky">leg</th>
              <th className="main_table1_th" colspan="2">
                Route
              </th>
              <th className="main_table1_th" colspan="4">
                Blocktime
              </th>
              <th className="main_table1_th" colspan="4">
                Flight time
              </th>
              <th className="main_table1_th" colspan="2">
                Engine cycles
              </th>
              <th className="main_table1_th" colspan="2">
                Fuel uplift
              </th>
              <th className="main_table1_th" colspan="2">
                Engine Oil Uplift
              </th>
              <th className="main_table1_th" colspan="2">
                MILES
              </th>
            </tr>
            <tr className="tr_main_table">
              <th className="main_table1_th Leg_to_sticky">Leg</th>
              <th className="main_table1_th">from </th>
              <th className="main_table1_th">to</th>
              <th className="main_table1_th">chks off</th>
              <th className="main_table1_th">chks on </th>
              <th className="main_table1_th">hrs</th>
              <th className="main_table1_th">hrs</th>
              <th className="main_table1_th">Take Off </th>
              <th className="main_table1_th">Landing</th>
              <th className="main_table1_th">hrs</th>
              <th className="main_table1_th">hrs</th>
              <th className="main_table1_th">L/H</th>
              <th className="main_table1_th">R/H</th>
              <th className="main_table1_th">L/H</th>
              <th className="main_table1_th">R/H</th>
              <th className="main_table1_th">L/H</th>
              <th className="main_table1_th">R/H</th>
              <th className="main_table1_th">miles</th>
            </tr>
            <tr className="tr_main_table">
              <td className="main_table1_td Leg_to_sticky">
                <input
                  type="number"
                  name="leg"
                  // value={data.id}
                  value={curleg}
                  placeholder={curleg}
                  disabled={true}
                  onChange={handleChange}
                  className="inside_table_input"
                />
              </td>
              <td className="main_table1_td">
                {curleg === 1 ? (
                  <>
                    <select
                      className="select__to__main__table"
                      onChange={async (e) => {
                        if (inparray.toRoute === e.target.value) {
                          alert("Can not be same");

                          e.target.value = "SELECT";
                        } else {
                          handleChange(e);
                          // handleFromRoute(e.target.value);
                        }
                      }}
                      name="fromRoute"
                      value={inparray.fromRoute}
                    >
                      <option>SELECT</option>
                      {routelist.map((data) => {
                        return <option>{data.CITYCODE}</option>;
                      })}
                    </select>
                  </>
                ) : (
                  <>
                    <input
                      className="select__to__main__table defaoult__select__main"
                      value={nextfromroute}
                      disabled={true}
                    />
                  </>
                )}
              </td>
              <td className="main_table1_td">
                <select
                  className="select__to__main__table"
                  onChange={(e) => {
                    // handleChange(e);
                    inparray.docno = docno;
                    inparray.createdby = "USER";
                    inparray.createddate = new Date().toISOString();
                    if (
                      inparray.fromRoute === e.target.value ||
                      nextfromroute === e.target.value
                    ) {
                      alert("Can not be same");
                      e.target.value = "SELECT";

                      // handleToRouteReset();
                      // setNextfromroute(e.target.value);
                    } else {
                      handleChange(e);
                      // handleToRoute(e.target.value);
                    }
                  }}
                  value={inparray.toRoute}
                  name="toRoute"
                >
                  <option>SELECT</option>
                  {routelist.map((data) => {
                    return (
                      <option value={data.CITYCODE}>{data.CITYCODE}</option>
                    );
                  })}
                </select>
              </td>
              <td className="main_table1_td">
                <input
                  className="inside_table_input__time"
                  type="time"
                  name="chksoff"
                  onChange={(e) => {
                    setCheckoffTime(e.target.value);

                    inparray.chksoff = e.target.value;
                  }}
                  value={inparray.chksoff}
                />
              </td>
              <td className="main_table1_td">
                <input
                  className="inside_table_input__time"
                  type="time"
                  name="chksOn"
                  onChange={(e) => {
                    setCheckonTime(e.target.value);
                    var now = e.target.value;
                    var then = checksoffTime;

                    const diff = moment
                      .utc(moment(now, "HH:mm").diff(moment(then, "HH:mm")))
                      .format("HH:mm");
                    var diff1 = diff.slice(0, 2);
                    // .toString();

                    // chcktime = diff;
                    inparray.blockTime = diff1;
                    var a = diff;
                    var b = Number(diff.slice(0, 2));
                    var c = Number(diff.slice(3, 5));
                    var d = c / 60;
                    var h = d * 10;
                    var f = Math.round(h);

                    inparray.blockTimeDesc = f;
                    e.target.defaultValue === ""
                      ? (inparray.chksOn = "a")
                      : (inparray.chksOn = e.target.value);
                  }}
                  value={inparray.chksOn}
                />
              </td>
              <td className="main_table1_td">
                <input
                  className="inside_table_input"
                  type="text"
                  name="blockTime"
                  placeholder={inparray.blockTime}
                  disabled={true}
                  value={String(inparray.blockTime).slice(0, 2)}
                  // value={chcktime}
                />
              </td>
              <td className="main_table1_td">
                <input
                  className="inside_table_input"
                  type="text"
                  name="new"
                  // placeholder={inparray.blockTime}
                  disabled={true}
                  value={inparray.blockTimeDesc}
                />
              </td>
              <td className="main_table1_td">
                <input
                  className="inside_table_input__time"
                  type="time"
                  name="takeOff"
                  value={inparray.takeOff}
                  onChange={(e) => {
                    setFlightTakeoffTime(e.target.value);
                    var timetopush =
                      // e.target.value.slice(0, 2) +
                      // e.target.value.slice(3, 5);

                      (inparray.takeOff = e.target.value);
                  }}
                />
              </td>
              <td className="main_table1_td">
                <input
                  className="inside_table_input__time"
                  type="time"
                  name="landing"
                  onChange={(e) => {
                    setFlightLandingTime(e.target.value);
                    var now = e.target.value;
                    var then = flightTakeoffTime;

                    const diff = moment
                      .utc(moment(now, "HH:mm").diff(moment(then, "HH:mm")))
                      .format("HH:mm");
                    var diff1 = diff.slice(0, 2);
                    // .toString();

                    // chcktime = diff;
                    inparray.flyingtime = diff1;
                    var a = diff;
                    var b = Number(diff.slice(0, 2));
                    var c = Number(diff.slice(3, 5));
                    var d = c / 60;
                    var h = d * 10;
                    var f = Math.round(h);

                    inparray.flyTimeDesc = f;
                    e.target.value === ""
                      ? (inparray.landing = "a")
                      : (inparray.landing = e.target.value);
                  }}
                  value={inparray.landing}
                />
              </td>
              <td className="main_table1_td">
                <input
                  className="inside_table_input"
                  type="text"
                  name="flyingtime"
                  placeholder={inparray.flyingtime}
                  disabled={true}
                  value={String(inparray.flyingtime).slice(0, 2)}
                  onChange={handleChange}
                />
              </td>
              <td className="main_table1_td">
                <input
                  className="inside_table_input"
                  type="text"
                  name="new"
                  placeholder={inparray.flyingtime}
                  disabled={true}
                  value={inparray.flyTimeDesc}
                />
              </td>
              <td className="main_table1_td">
                <input
                  id="test"
                  className="inside_table_input"
                  type="number"
                  name="eclh"
                  onChange={handleChange}
                  // defaultChecked={inparray.eclh}
                />
              </td>
              <td className="main_table1_td">
                <input
                  id="test1"
                  className="inside_table_input"
                  type="number"
                  name="ecrh"
                  onChange={handleChange}
                  // defaultChecked={inparray.ecrh}
                />
              </td>
              <td className="main_table1_td">
                <input
                  id="test3"
                  className="inside_table_input"
                  type="number"
                  name="fulh"
                  onChange={handleChange}
                  // placeholder={inparray.fulh}
                />
              </td>
              <td className="main_table1_td">
                <input
                  id="test4"
                  className="inside_table_input"
                  type="number"
                  name="furh"
                  onChange={handleChange}
                  // placeholder={inparray.furh}
                />
              </td>
              <td className="main_table1_td">
                <input
                  id="test5"
                  className="inside_table_input"
                  type="number"
                  name="eolh"
                  onChange={handleChange}
                  // placeholder={inparray.eolh}
                />
              </td>
              <td className="main_table1_td">
                <input
                  id="test6"
                  className="inside_table_input"
                  type="number"
                  name="eorh"
                  onChange={handleChange}
                  // placeholder={inparray.eorh}
                />
              </td>
              <td className="main_table1_td">
                <input
                  id="test7"
                  className="inside_table_input"
                  type="number"
                  name="milage"
                  onChange={handleChange}
                  value={inparray.milage}
                  // placeholder={inparray.eorh}
                />
              </td>
            </tr>
          </table>
          <div
            hidden={gstatus ? true : false}
            className="new__save__plus__save"
          >
            <aside className={`to__ovrgjfkfk ${gstatus ? "test" : ""}`}>
              {" "}
              {/* onClick={handleButtonClick} disabled={isSubmitting} */}
              <button
                hidden={gstatus ? true : false}
                className="button__to__add__new__row "
                onClick={() => {
                  inparray.docno = docno;
                  inparray.leg = curleg;
                  if (curleg !== 1) {
                    inparray.fromRoute = nextfromroute;
                  }
                  handleButtonClick();
                  // GetMilaege();
                }}
              >
                +
              </button>
              <button
                hidden={gstatus ? true : false}
                className="button__to__add__new__row "
                onClick={() => {
                  if (curleg === 2) {
                    setCurLeg(1);
                    datas.pop();
                  } else if (curleg > 2) {
                    var leng = datas.length;
                    inparray.docno = docno;
                    inparray.leg = curleg - 1;
                    setCurLeg(curleg - 1);
                    setNextfromroute(datas[leng - 2].toRoute);

                    if (curleg !== 1) {
                      inparray.fromRoute = datas[leng - 2].toRoute;
                    }
                    // console.log(datas[leng - 2].toRoute);
                    datas.pop();
                  }

                  // handleButtonClick();
                }}
              >
                -
              </button>
            </aside>
            <div
              className={`to__make__save__btn__stycky ${gstatus ? "test" : ""}`}
            >
              {" "}
              {/* <div className="to__make__save__btn__stycky"> */}
              <button
                hidden={datas.length < 0 ? true : false}
                onClick={async () => {
                  getTotalbf();
                  if (window.confirm("Do you want to save")) {
                    console.log(datas);
                    var n = 1;
                    for (var i = 0; i < datas.length; i++) {
                      // datas[i].createddate = new Date().toISOString();
                      try {
                        const response = await Axios.post(
                          `${BASEURL}/insertlog`,
                          datas[i],
                          {
                            headers: {
                              "auth-token": authToken,
                              "session-token": sclientSecret,
                            },
                          }
                        );
                        if (i === datas.length - 1) {
                          alert(response.data.message);
                          n++;
                        } else {
                          n++;
                        }
                      } catch (error) {
                        console.error("Error inserting log:", error);
                      }
                    }
                  }
                }}

                // className="save__edit__button"
              >
                SAVE
              </button>{" "}
            </div>
          </div>
          <div hidden={datas.length === 0 ? true : false}>
            <table className={`main_table1 ${gstatus ? "test" : ""}`}>
              <tr className="tr_main_table">
                <th className="main_table1_th Leg_to_sticky">leg</th>
                <th className="main_table1_th" colspan="2">
                  Route
                </th>
                <th className="main_table1_th" colspan="4">
                  Blocktime
                </th>
                <th className="main_table1_th" colspan="4">
                  Flight time
                </th>
                <th className="main_table1_th" colspan="2">
                  Engine cycles
                </th>
                <th className="main_table1_th" colspan="2">
                  Fuel uplift
                </th>
                <th className="main_table1_th" colspan="2">
                  Engine Oil Uplift
                </th>
                <th className="main_table1_th" colspan="2">
                  Miles
                </th>
              </tr>
              <tr className="tr_main_table">
                <th className="main_table1_th Leg_to_sticky">Leg</th>
                <th className="main_table1_th">from </th>
                <th className="main_table1_th">to</th>
                <th className="main_table1_th">chks off</th>
                <th className="main_table1_th">chks on </th>
                <th className="main_table1_th">hrs</th>
                <th className="main_table1_th">hrs</th>
                <th className="main_table1_th">Take Off </th>
                <th className="main_table1_th">Landing</th>
                <th className="main_table1_th">hrs</th>
                <th className="main_table1_th">hrs</th>
                <th className="main_table1_th">L/H</th>
                <th className="main_table1_th">R/H</th>
                <th className="main_table1_th">L/H</th>
                <th className="main_table1_th">R/H</th>
                <th className="main_table1_th">L/H</th>
                <th className="main_table1_th">R/H</th>
                <th className="main_table1_th">Miles</th>
              </tr>
              {datas.map((data) => {
                return (
                  <tr>
                    <td className="main_table1_td Leg_to_sticky">
                      <input
                        className="inside_table_input"
                        type="text"
                        placeholder={data.leg}
                        disabled={true}
                        // value={data.leg}
                      />
                    </td>

                    <td className="main_table1_td">
                      <select
                        className="select__to__main__table"
                        onChange={(e) => {
                          data.fromRoute = e.target.value;
                        }}
                      >
                        <option>{data.fromRoute}</option>
                        {routelist.map((data) => {
                          return (
                            <option value={data.CITYCODE}>
                              {data.CITYCODE}
                            </option>
                          );
                        })}
                      </select>
                    </td>

                    <td className="main_table1_td">
                      <select
                        className="select__to__main__table"
                        onChange={(e) => {
                          data.toRoute = e.target.value;
                        }}
                      >
                        <option>{data.toRoute}</option>
                        {routelist.map((data) => {
                          return (
                            <option value={data.CITYCODE}>
                              {data.CITYCODE}
                            </option>
                          );
                        })}
                      </select>
                    </td>

                    <td className="main_table1_td">
                      <input
                        className="inside_table_input__time"
                        type="time"
                        placeholder={data.chksoff.slice(11, 16)}
                        defaultValue={
                          data.chksoff.slice(11, 16) || data.chksoff
                        }
                        // disabled={true}
                        onChange={(e) => {
                          data.chksoff = e.target.value;
                        }}
                      />
                    </td>

                    <td className="main_table1_td">
                      <input
                        className="inside_table_input__time"
                        type="time"
                        placeholder={data.chksOn}
                        defaultValue={data.chksOn.slice(11, 16) || data.chksOn}
                        onChange={(e) => {
                          data.chksOn = e.target.value;
                          var now = e.target.value;
                          var then = data.chksoff;

                          const diff = moment
                            .utc(
                              moment(now, "HH:mm").diff(moment(then, "HH:mm"))
                            )
                            .format("HHmm")
                            .toString();

                          data.blockTime = diff;
                          var a = diff;
                          var b = Number(diff.slice(0, 2));
                          var c = Number(diff.slice(2, 4));
                          var d = c / 60;
                          var h = d * 10;
                          var f = Math.round(h);
                          data.blockTimeDesc = f;
                        }}
                      />
                    </td>

                    <td className="main_table1_td">
                      <input
                        className="inside_table_input"
                        placeholder={String(data.blockTime).slice(0, 2)}
                        disabled={true}
                        // value={String(data.blockTime).slice(0, 2)}
                      />
                    </td>

                    <td className="main_table1_td">
                      <input
                        className="inside_table_input"
                        placeholder={data.blockTimeDesc}
                        disabled={true}

                        // defaultValue={String(data.blockTime).slice(2, 5)}
                      />
                    </td>

                    <td className="main_table1_td">
                      <input
                        type="time"
                        onChange={(e) => {
                          data.takeOff = e.target.value;
                        }}
                        className="inside_table_input__time"
                        // placeholder={data.takeOff.slice(11, 16) || data.takeOff}
                        defaultValue={
                          data.takeOff.slice(11, 16) || data.takeOff
                        }
                      />
                    </td>

                    <td className="main_table1_td">
                      <input
                        type="time"
                        onChange={(e) => {
                          data.landing = e.target.value;
                          var now = e.target.value;
                          var then = data.takeOff;

                          const diff = moment
                            .utc(
                              moment(now, "HH:mm").diff(moment(then, "HH:mm"))
                            )
                            .format("HHmm")
                            .toString();

                          data.flyingtime = diff;
                          var a = diff;
                          var b = Number(diff.slice(0, 2));
                          var c = Number(diff.slice(2, 4));
                          var d = c / 60;
                          var h = d * 10;
                          var f = Math.round(h);
                          data.flyTimeDesc = f;
                        }}
                        className="inside_table_input__time"
                        placeholder={data.landing.slice(11, 16)}
                        defaultValue={
                          data.landing.slice(11, 16) || data.landing
                        }
                      />
                    </td>

                    <td className="main_table1_td">
                      <input
                        className="inside_table_input"
                        placeholder={String(data.flyingtime).slice(0, 2)}
                        disabled={true}
                        // value={String(data.flyingtime).slice(0, 2)}
                      />
                    </td>

                    <td className="main_table1_td">
                      <input
                        className="inside_table_input"
                        placeholder={data.flyTimeDesc}
                        disabled={true}
                      />
                    </td>

                    <td className="main_table1_td">
                      <input
                        className="inside_table_input"
                        placeholder={data.eclh}
                        onClick={() => {
                          data.eclh = "";
                        }}
                        onChange={(e) => {
                          data.eclh = "";
                          data.eclh = e.target.value;
                        }}
                      />
                    </td>

                    <td className="main_table1_td">
                      <input
                        className="inside_table_input"
                        placeholder={data.ecrh}
                        onChange={(e) => {
                          data.ecrh = e.target.value;
                        }}
                      />
                    </td>

                    <td className="main_table1_td">
                      <input
                        className="inside_table_input"
                        placeholder={data.fulh}
                        onChange={(e) => {
                          data.fulh = e.target.value;
                        }}
                      />
                    </td>

                    <td className="main_table1_td">
                      <input
                        className="inside_table_input"
                        placeholder={data.furh}
                        onChange={(e) => {
                          data.furh = e.target.value;
                        }}
                      />
                    </td>

                    <td className="main_table1_td">
                      <input
                        className="inside_table_input"
                        placeholder={data.eolh}
                        onChange={(e) => {
                          data.eolh = e.target.value;
                        }}
                      />
                    </td>

                    <td className="main_table1_td">
                      <input
                        className="inside_table_input"
                        placeholder={data.eorh}
                        onChange={(e) => {
                          data.eorh = e.target.value;
                        }}
                      />
                    </td>
                    <td className="main_table1_td">
                      <input
                        className="inside_table_input"
                        placeholder={data.milage}
                        onChange={(e) => {
                          data.milage = e.target.value;
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
          {/* </div> */}
        </main>
      </div>
      <article className="for_main_article1">
        {/* for input fields below main table */}
        <div className="all_input_below_main_table">
          <main className="main__overflow">
            <section className="section2">
              <table>
                <tr>
                  <th>
                    <p className="for_nocolor_heading"> A/F Hours B/F:</p>
                  </th>
                  <th>
                    <p className="for_nocolor_heading">Engine Cycles B/F: </p>{" "}
                  </th>
                  <th>
                    {" "}
                    <p className="for_redHeading">Start Fuel L/H:</p>{" "}
                  </th>
                  <th>
                    <p className="for_redHeading">Start Fuel R/H:</p>{" "}
                  </th>
                  <th>
                    <p className="for_redHeading">Total Fuel Uplift L/H:</p>{" "}
                  </th>
                  <th>
                    <p className="for_redHeading">Total Fuel Uplift R/H:</p>{" "}
                  </th>
                  <th>
                    <p className="for_redHeading"> Engine Hours B/F:</p>
                  </th>
                </tr>
                <tr>
                  <td>
                    <input
                      className="forGreen_background defoult__stle__remove"
                      type="text"
                      name="hoursbf"
                      onChange={handleHeaderDataChange}
                      placeholder={hoursFlownbf}
                      disabled={true}
                      value={hoursFlownbf}
                    />
                  </td>
                  <td>
                    <input
                      className="forGreen_background defoult__stle__remove"
                      type="text"
                      name="enginecyclesbf"
                      onChange={handleHeaderDataChange}
                      placeholder={engineCyclelh + engineCyclerh}
                      disabled={true}
                      value={headerdetails.enginecyclesbf}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="startfuelleft"
                      onChange={handleHeaderDataChange}
                      value={headerdetails.startfuelleft}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="startfuelrh"
                      onChange={handleHeaderDataChange}
                      defaultValue={headerdetails.startfuelrh}
                    />
                  </td>
                  <td>
                    <input
                      className=" defoult__stle__remove"
                      type="number"
                      name="fuelUpliftLH"
                      disabled
                      // onChange={handleHeaderDataChange}
                      value={fuelUpliftLH}
                    />
                  </td>
                  <td>
                    <input
                      className=" defoult__stle__remove"
                      type="number"
                      name="fuelUpliftRH"
                      disabled
                      // onChange={handleHeaderDataChange}
                      value={fuelUpliftRH}
                    />
                  </td>
                  <td>
                    <input
                      className="forGreen_background defoult__stle__remove"
                      type="text"
                      name="new"
                      placeholder={engineHoursBf}
                      value={engineHoursBf}
                      disabled={true}
                    />
                  </td>
                </tr>
              </table>
            </section>
            {/* second line of input below main table */}
            <section className="section3">
              <table>
                <tr>
                  <th>
                    <p className="for_nocolor_heading"> A/F Hours Flown:</p>
                  </th>
                  <th>
                    <p className="for_nocolor_heading">Engine Cycle:</p>{" "}
                  </th>
                  <th>
                    <p className="for_blueHeading">Stop Fuel L/H </p>
                  </th>
                  <th>
                    <p className="for_blueHeading">Stop Fuel R/H</p>
                  </th>
                  <th>
                    <p className="for_nocolor_heading">Press Alt </p>
                  </th>
                  <th>
                    <p className="for__default__text3"></p>
                  </th>
                  <th>
                    <p className="for_redHeading">Engine Hours:</p>
                  </th>
                </tr>
                <tr>
                  <td>
                    <input
                      className="forGreen_background defoult__stle__remove"
                      type="text"
                      name="hoursflown"
                      onChange={handleHeaderDataChange}
                      value={flytimetotal}
                      placeholder={flytimetotal}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <input
                      className="forGreen_background defoult__stle__remove"
                      type="text"
                      name="enginecyclesflown"
                      onChange={handleHeaderDataChange}
                      defaultValue={engCycletotal}
                      placeholder={engCycletotal}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="stopfuelleft"
                      onChange={handleHeaderDataChange}
                      value={headerdetails.stopfuelleft}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="stopfuelrh"
                      onChange={handleHeaderDataChange}
                      value={headerdetails.stopfuelrh}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="pressalt"
                      onChange={handleHeaderDataChange}
                      value={headerdetails.pressalt}
                    />
                  </td>
                  <td>
                    <input
                      className="for_default_box defoult__stle__remove"
                      type="text"
                      name="new"
                      disabled="true"
                    />
                  </td>
                  <td>
                    <input
                      className="forGreen_background defoult__stle__remove"
                      type="text"
                      name="new"
                      value={hoursbfCountTotal}
                      placeholder={hoursbfCountTotal}
                      disabled={true}
                    />
                  </td>
                </tr>
              </table>
            </section>
            {/* 3rd input section after main table */}
            <section className="section4">
              <table>
                <tr>
                  <th>
                    <p className="for_nocolor_heading">A/F Hours C/F:</p>{" "}
                  </th>
                  <th>
                    <p className="for_nocolor_heading">Engine Cycle C/F: </p>
                  </th>
                  <th>
                    <p className="for_nocolor_heading">
                      IAS(indicate air Speed):
                    </p>
                  </th>
                  <th className="for__default__text1 pointer__event"></th>
                  <th>
                    <p className="for_nocolor_heading">
                      OAT(Ouside Air Temp):{" "}
                    </p>
                  </th>
                  <th className="for__default__text2"></th>
                  <th>
                    <p className="for_redHeading">Engine Hours C/F:</p>
                  </th>
                </tr>
                <tr>
                  <td>
                    <input
                      className="forGreen_background defoult__stle__remove"
                      type="text"
                      name="hourscf"
                      onChange={handleHeaderDataChange}
                      value={(
                        Number(flytimetotal) + Number(hoursFlownbf)
                      ).toFixed(2)}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <input
                      className="forGreen_background defoult__stle__remove"
                      type="text"
                      name="enginecyclescf"
                      onChange={handleHeaderDataChange}
                      value={(
                        Number(engCycletotal) +
                        Number(engineCyclelh) +
                        Number(engineCyclerh)
                      ).toFixed(2)}
                      disabled={true}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="ias"
                      onChange={handleHeaderDataChange}
                      value={headerdetails.ias}
                    />
                  </td>
                  <td>
                    <input
                      className="for_default_box defoult__stle__remove"
                      type="text"
                      name="new"
                      disabled="true"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="oat"
                      onChange={handleHeaderDataChange}
                      value={headerdetails.oat}
                    />
                  </td>
                  <td>
                    <input
                      className="for_default_box defoult__stle__remove"
                      type="text"
                      name="new"
                      disabled="true"
                    />
                  </td>
                  <td>
                    <input
                      className="forGreen_background defoult__stle__remove"
                      type="text"
                      name="new"
                      value={(
                        Number(hoursbfCountTotal) + Number(engineHoursBf)
                      ).toFixed(2)}
                      disabled={true}
                    />
                  </td>
                </tr>
              </table>
            </section>
          </main>
        </div>

        <div className="for_end_section" style={{ display: "none" }}>
          <section className="section5">
            <h2 className="reading_header">engine reading</h2>
            <table className="to_align_engine_reading_table">
              <tr className="main_table1_tr2">
                <th className="main_table1_th2">engine pos</th>
                <th className="main_table1_th2">star 1tt </th>
                <th className="main_table1_th2">star time</th>
                <th className="main_table1_th2">torque</th>
                <th className="main_table1_th2">rpm </th>
                <th className="main_table1_th2">egt/1tt</th>
                <th className="main_table1_th2">fuel flow</th>
                <th className="main_table1_th2">oil temp</th>
                <th className="main_table1_th2">oil press</th>
                <th className="main_table1_th2">Diff press</th>
                <th className="main_table1_th2">gen load</th>
              </tr>
              <tr className="main_table1_tr2">
                <td className="main_table1_td2">
                  <h4 className="lh_rh">R/H</h4>
                  {/* <input
                    className="inside_table_input2 defoult__stle__remove"
                    type="text"
                    name="new"
                    placeholder=""
                    disabled={true}
                  /> */}
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogenginestaritt"
                    defaultValue={engineInput.techlogenginestaritt}
                    onChange={handleEngineReadingDataChange}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogenginestarttime"
                    defaultValue={engineInput.techlogenginestarttime}
                    onChange={handleEngineReadingDataChange}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogenginetorque"
                    defaultValue={engineInput.techlogenginetorque}
                    onChange={handleEngineReadingDataChange}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogenginerpm"
                    defaultValue={engineInput.techlogenginerpm}
                    onChange={handleEngineReadingDataChange}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogengineegt"
                    defaultValue={engineInput.techlogengineegt}
                    onChange={handleEngineReadingDataChange}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogenginefuelflow"
                    defaultValue={engineInput.techlogenginefuelflow}
                    onChange={handleEngineReadingDataChange}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogengineoiltemp"
                    defaultValue={engineInput.techlogengineoiltemp}
                    onChange={handleEngineReadingDataChange}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogengineoilpress"
                    defaultValue={engineInput.techlogengineoilpress}
                    onChange={handleEngineReadingDataChange}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogenginediffpress"
                    defaultValue={engineInput.techlogenginediffpress}
                    onChange={handleEngineReadingDataChange}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="new"
                    defaultValue={engineInput.techlogenginegenload}
                    onChange={(e) => {
                      engineInput.techlogenginegenload = e.target.value;
                    }}
                  />
                </td>
                <td className="main_table1_td2">
                  <button
                    onClick={(e) => {
                      if (
                        window.confirm(
                          "Are you sure you want to save these values ?"
                        )
                      ) {
                        engineInput.techlogNo = docno;
                        engArray.push(engineInput);
                        // alert("Do you want to save");
                      }
                    }}
                  >
                    SAVE
                  </button>
                </td>
              </tr>
              <tr className="main_table1_tr2">
                <td className="main_table1_td2">
                  <h4 className="lh_rh">L/H</h4>
                  {/* <input
                    className="inside_table_input2 defoult__stle__remove"
                    type="text"
                    name="new"
                    placeholder="L/H"
                    disabled={true}
                  /> */}
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogenginestaritt"
                    defaultValue={leftengineInput.techlogenginestaritt}
                    onChange={handleLeftEngineReadingDataChange}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogenginestarttime"
                    defaultValue={leftengineInput.techlogenginestarttime}
                    onChange={handleLeftEngineReadingDataChange}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogenginetorque"
                    defaultValue={leftengineInput.techlogenginetorque}
                    onChange={handleLeftEngineReadingDataChange}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogenginerpm"
                    defaultValue={leftengineInput.techlogenginerpm}
                    onChange={handleLeftEngineReadingDataChange}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogengineegt"
                    defaultValue={leftengineInput.techlogengineegt}
                    onChange={handleLeftEngineReadingDataChange}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogenginefuelflow"
                    defaultValue={leftengineInput.techlogenginefuelflow}
                    onChange={handleLeftEngineReadingDataChange}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogengineoiltemp"
                    defaultValue={leftengineInput.techlogengineoiltemp}
                    onChange={handleLeftEngineReadingDataChange}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogengineoilpress"
                    defaultValue={leftengineInput.techlogengineoilpress}
                    onChange={handleLeftEngineReadingDataChange}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="techlogenginediffpress"
                    defaultValue={leftengineInput.techlogenginediffpress}
                    onChange={(e) => {
                      leftengineInput.techlogenginediffpress = e.target.value;
                    }}
                  />
                </td>
                <td className="main_table1_td2">
                  <input
                    className="inside_table_input2"
                    type="text"
                    name="new"
                    defaultValue={leftengineInput.techlogenginegenload}
                    onChange={(e) => {
                      leftengineInput.techlogenginegenload = e.target.value;
                    }}
                  />
                </td>
                <td className="main_table1_td2">
                  <button
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you want to save these values ?"
                        )
                      ) {
                        leftengineInput.techlogNo = docno;
                        engArray.push(leftengineInput);
                        // alert("Do you want to save");
                      }
                    }}
                  >
                    SAVE
                  </button>
                </td>
              </tr>
            </table>
            <div className="to__make__save__btn__stycky">
              {" "}
              <div className="to__make__save__btn__stycky">
                <button
                  onClick={async () => {
                    if (
                      window.confirm(
                        "Are you sure you want to save these values?"
                      )
                    ) {
                      for (let i = 0; i < engArray.length; i++) {
                        try {
                          const response = await Axios.post(
                            `${BASEURL}/insertenginereading`,
                            engArray[i],
                            {
                              headers: {
                                "auth-token": authToken,
                                "session-token": sclientSecret,
                              },
                            }
                          );

                          // alert(response.data.message);
                        } catch (error) {
                          console.error(
                            "Error inserting engine reading:",
                            error
                          );
                        }
                      }
                    }
                  }}
                  className="save__edit__button"
                >
                  SAVE
                </button>
              </div>
            </div>
          </section>
        </div>
      </article>
      <>
        <div className="indicate__pilot__defect" style={{ display: "none" }}>
          <main className="main2">
            <h2 className="Indicate_Pilot_Defects">Indicate Pilot Defects</h2>
            <table className="main_table_2">
              <tr className="tr_main_table_2">
                <td className="main_table1_td_2">
                  <label className="main__table2__lable">Defect No</label>
                  <input
                    className="inside_table_input_2 defoult__stle__remove"
                    type="text"
                    name="defectNo"
                    value={pilotdefect.deffectNo}
                    placeholder={deffectNoinp}
                    disabled={true}
                  />
                </td>
                <td className="main_table1_td_2">
                  <label className="main__table2__lable">Defect</label>
                  <input
                    className="inside_table_input_2 defoult__stle__remove"
                    type="pilotdefect"
                    name="new"
                    defaultValue={pilotdefect.defect}
                    onChange={(e) => {
                      pilotdefect.defect = e.target.value;
                    }}
                  />
                </td>
              </tr>
              <tr className="tr_main_table_2">
                <td className="main_table1_td_2">
                  <label className="main__table2__lable">Created By</label>
                  <input
                    className="inside_table_input_2 defoult__stle__remove"
                    type="createdby"
                    name="new"
                    defaultValue={pilotdefect.createdby}
                    onChange={(e) => {
                      pilotdefect.createdby = e.target.value;
                    }}
                  />
                </td>
              </tr>
            </table>
            <aside className="aside_btn">
              <button
                className="aside__button__code"
                onClick={async () => {
                  if (
                    window.confirm(
                      "Are you sure you want to save these values?"
                    )
                  ) {
                    try {
                      pilotdefect.techlogNo = docno;
                      pilotdefect.deffectNo = deffectNoinp;
                      const response = await Axios.post(
                        `${BASEURL}/insertpilotDefect`,
                        {
                          techlogNo: pilotdefect.techlogNo,
                          deffectNo: pilotdefect.deffectNo,
                          defect: pilotdefect.defect,
                          createdby: pilotdefect.createdby,
                        },
                        {
                          headers: {
                            "auth-token": authToken,
                            "session-token": sclientSecret,
                          },
                        }
                      );
                      alert(response.data.message);
                    } catch (error) {
                      console.error("Error inserting pilot defect:", error);
                    }
                  }
                }}
              >
                SAVE
              </button>
            </aside>
          </main>
          <main className="main3">
            <h2 className="Indicate_Pilot_Defects">
              Engineers Defects Rectification
            </h2>
            <table className="main_table_3">
              <tr className="tr_main_table_3">
                <td className="main_table1_td_3">
                  <input
                    className="inside_table_input_3"
                    type="text"
                    name="new"
                  />
                </td>
                <td className="main_table1_td_3">
                  <input
                    className="inside_table_input_3"
                    type="text"
                    name="new"
                  />
                </td>
              </tr>
              <tr className="tr_main_table_3">
                <td className="main_table1_td_3">
                  <input
                    className="inside_table_input_3"
                    type="text"
                    name="new"
                  />
                </td>
                <td className="main_table1_td_3">
                  <input
                    className="inside_table_input_3"
                    type="text"
                    name="new"
                  />
                </td>
              </tr>
            </table>
          </main>
        </div>
      </>
    </>
  );

  function handleSubmit() {
    if (window.confirm("Are you sure you want to confirm?")) {
      var d = docno;
      try {
        Axios.post(
          `${BASEURL}/confirm`,
          {
            docNo: d,
            currenttime: currenttime,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        ).then((response) => {
          if (response.data.message === "OK") {
            window.alert("Document Confirmed");
          }
        });
      } catch (error) {
        console.error("Error confirming document:", error);
      }
    }
  }

  function handleHeaderSubmit() {
    console.log(headerdetails);
    if (window.confirm("Are you sure you want to save?")) {
      try {
        Axios.post(`${BASEURL}/insertheader`, headerdetails, {
          headers: {
            "auth-token": authToken,
            "session-token": sclientSecret,
          },
        }).then((response) => {
          alert(response.data.message);
        });
      } catch (error) {
        console.error("Error inserting header:", error);
      }
    }
  }

  async function prevDoc() {
    try {
      if (docno === 1) {
        alert("No older documents");
      } else {
        setIsLoading(true);
        setDatas([]);
        setCurLeg(1);
        if (docno - 1 > 0) {
          var docNo = Number(docno) - 1;
          setDocno(Number(docno) - 1);

          await Axios.post(
            `${BASEURL}/gettechlogdetails`,
            {
              docNo: docNo,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sclientSecret,
              },
            }
          ).then((response) => {
            setDatas(response.data.recordset);
            getTotalbf();

            setCurLeg(response.data.recordset.length + 1);
            setNextfromroute(
              response.data.recordset[response.data.recordset.length - 1]
                ?.toRoute
            );
          });

          await Axios.post(
            `${BASEURL}/getHeaderDetails`,
            {
              docNo: docNo,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sclientSecret,
              },
            }
          ).then(async (response) => {
            setHeaderdetails(response.data.recordset[0]);
            console.log(response.data.recordset[0].operationtype);
            setOperationType(response.data.recordset[0].operationtype);
            getTotalbf();
            Axios.post(
              `${BASEURL}/getpilotname`,
              {
                code: response.data.recordset[0].pilotcode,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sclientSecret,
                },
              }
            ).then((response) => {
              setPilotname(response.data.recordset[0].PILOTNAME);
            });
            Axios.post(
              `${BASEURL}/getpilotname`,
              {
                code: response.data.recordset[0].copilotcode,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sclientSecret,
                },
              }
            ).then((response) => {
              setcoPilotname(response.data.recordset[0]?.PILOTNAME);
            });

            setStatus(response.data.recordset[0].status);

            var regi = response.data.recordset[0].aircraftreg;

            await Axios.post(
              `${BASEURL}/getenginecyclesbf`,
              {
                regi: regi,
                cDate: currenttime,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sclientSecret,
                },
              }
            ).then((response) => {
              if (response.data.recordset[0].CYCLESENGINE === null) {
                setengineCyclelh(0);
                setengineCyclerh(0);
                headerdetails.enginecyclesbf = 0;
              } else {
                setengineCyclelh(
                  Number(response.data.recordset[0].CYCLESENGINE)
                );
                setengineCyclerh(0);
                headerdetails.enginecyclesbf = Number(
                  response.data.recordset[0].CYCLESENGINE
                );
              }
            });

            await Axios.post(
              `${BASEURL}/enginehoursbf`,
              {
                regi: regi,
                cDate: currenttime,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sclientSecret,
                },
              }
            ).then((response) => {
              if (response.data.recordset[0].HOURSENGINE === null) {
                setengineHoursBf(0);
                headerdetails.hoursbf = 0;
              } else {
                setengineHoursBf(
                  Number(response.data.recordset[0].HOURSENGINE)
                );
              }
            });

            await Axios.post(
              `${BASEURL}/hoursflownbw`,
              {
                regi: regi,
                cDate: currenttime,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sclientSecret,
                },
              }
            ).then((response) => {
              if (response.data.recordset[0].HOURSFLOWN === null) {
                sethoursFlownbf(0);
                headerdetails.hoursbf = 0;
              } else {
                sethoursFlownbf(response.data.recordset[0].HOURSFLOWN);
                headerdetails.hoursbf = response.data.recordset[0].HOURSFLOWN;
              }
            });

            await Axios.post(
              `${BASEURL}/getleftenginereadinsdetails`,
              {
                docno: docNo,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sclientSecret,
                },
              }
            ).then((response) => {
              if (response.data.recordset.length > 0) {
                setLeftEngineInput(response.data.recordset[0]);
              }
            });

            await Axios.post(
              `${BASEURL}/getrightenginereadinsdetails`,
              {
                docno: docNo,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sclientSecret,
                },
              }
            ).then((response) => {
              if (response.data.recordset.length > 0) {
                setEngineInput(response.data.recordset[0]);
              }
            });

            await Axios.post(
              `${BASEURL}/getpilotdeffect`,
              {
                docno: docNo,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sclientSecret,
                },
              }
            ).then((response) => {
              if (response.data?.recordset?.length > 0) {
                setPilotDefect(response.data.recordset[0]);
              }
              setIsLoading(false);
            });
          });
        }
      }
    } catch (error) {
      console.error("An error occurred in prevDoc:", error);
      setIsLoading(false);
      // Handle the error here
    }
  }

  async function nextDoc() {
    try {
      if (docno + 1 < myDataNext) {
        setDatas([]);
        setCurLeg(1);
        setIsLoading(true);

        var d = Number(docno) + 1;
        setDocno(Number(docno) + 1);
        setDatas([]);
        await Axios.post(
          `${BASEURL}/gettechlogdetails`,
          {
            docNo: d,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        ).then((response) => {
          setDatas(response.data.recordset);
          getTotalbf();
          setCurLeg(response.data.recordset.length + 1);
          setNextfromroute(
            response.data.recordset[response.data.recordset.length - 1]?.toRoute
          );
        });

        await Axios.post(
          `${BASEURL}/getHeaderDetails`,
          {
            docNo: d,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        ).then((response) => {
          setHeaderdetails(response.data.recordset[0]);
          console.log(response.data.recordset[0].operationtype);
          setOperationType(response.data.recordset[0].operationtype);
          Axios.post(
            `${BASEURL}/getpilotname`,
            {
              code: response.data.recordset[0].pilotcode,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sclientSecret,
              },
            }
          ).then((response) => {
            setPilotname(response.data.recordset[0].PILOTNAME);
          });
          Axios.post(
            `${BASEURL}/getpilotname`,
            {
              code: response.data.recordset[0].copilotcode,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sclientSecret,
              },
            }
          ).then((response) => {
            setcoPilotname(response.data.recordset[0]?.PILOTNAME);
          });
          setStatus(response.data.recordset[0].status);

          var regi = response.data.recordset[0].aircraftreg;

          Axios.post(
            `${BASEURL}/getenginecyclesbf`,
            {
              regi: regi,
              cDate: currenttime,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sclientSecret,
              },
            }
          ).then((response) => {
            if (response.data.recordset[0].CYCLESENGINE === null) {
              setengineCyclelh(0);
              setengineCyclerh(0);
              headerdetails.enginecyclesbf = 0;
            } else {
              setengineCyclelh(Number(response.data.recordset[0].CYCLESENGINE));
              setengineCyclerh(0);
              headerdetails.enginecyclesbf = Number(
                response.data.recordset[0].CYCLESENGINE
              );
            }
          });

          Axios.post(
            `${BASEURL}/enginehoursbf`,
            {
              regi: regi,
              cDate: currenttime,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sclientSecret,
              },
            }
          ).then((response) => {
            if (response.data.recordset[0].HOURSENGINE === null) {
              setengineHoursBf(0);
              headerdetails.hoursbf = 0;
            } else {
              setengineHoursBf(Number(response.data.recordset[0].HOURSENGINE));
            }
          });

          Axios.post(
            `${BASEURL}/hoursflownbw`,
            {
              regi: regi,
              cDate: currenttime,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sclientSecret,
              },
            }
          ).then((response) => {
            if (response.data.recordset[0].HOURSFLOWN === null) {
              sethoursFlownbf(0);
              headerdetails.hoursbf = 0;
            } else {
              sethoursFlownbf(response.data.recordset[0].HOURSFLOWN);
              headerdetails.hoursbf = response.data.recordset[0].HOURSFLOWN;
            }
          });

          Axios.post(
            `${BASEURL}/getleftenginereadinsdetails`,
            {
              docno: d,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sclientSecret,
              },
            }
          ).then((response) => {
            if (response.data.recordset.length > 0) {
              setLeftEngineInput(response.data.recordset[0]);
            }
          });

          Axios.post(
            `${BASEURL}/getrightenginereadinsdetails`,
            {
              docno: d,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sclientSecret,
              },
            }
          ).then((response) => {
            if (response.data.recordset.length > 0) {
              setEngineInput(response.data.recordset[0]);
            }
          });

          Axios.post(
            `${BASEURL}/getpilotdeffect`,
            {
              docno: d,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sclientSecret,
              },
            }
          ).then((response) => {
            if (response.data.recordset.length > 0) {
              setPilotDefect(response.data.recordset[0]);
            }
          });
        });
        setIsLoading(false);
      } else {
        window.location.reload();
      }
    } catch (error) {
      console.error("An error occurred in nextDoc:", error);
      // Handle the error here
    }
  }

  async function firstDoc() {
    try {
      setDocno(1);
      if (docno === 1) {
        // No action needed
      } else {
        setIsLoading(true);

        var docNoo = 1;
        if (docNoo === 1) {
          setDocno(docNoo);
          await Axios.post(
            `${BASEURL}/gettechlogdetails`,
            {
              docNo: 1,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sclientSecret,
              },
            }
          ).then((response) => {
            setDatas(response.data.recordset);
            getTotalbf();
            setCurLeg(response.data.recordset.length + 1);
            setIsLoading(false);
          });

          await Axios.post(
            `${BASEURL}/getHeaderDetails`,
            {
              docNo: 1,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sclientSecret,
              },
            }
          ).then((response) => {
            setHeaderdetails(response.data.recordset[0]);
            Axios.post(
              `${BASEURL}/getpilotname`,
              {
                code: response.data.recordset[0].pilotcode,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sclientSecret,
                },
              }
            ).then((response) => {
              setPilotname(response.data.recordset[0].PILOTNAME);
            });

            Axios.post(
              `${BASEURL}/getpilotname`,
              {
                code: response.data.recordset[0].copilotcode,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sclientSecret,
                },
              }
            ).then((response) => {
              setcoPilotname(response.data.recordset[0].PILOTNAME);
            });

            setStatus(response.data.recordset[0].status);
            setIsLoading(false);
            var regi = response.data.recordset[0].aircraftreg;

            Axios.post(
              `${BASEURL}/getenginecyclesbf`,
              {
                regi: regi,
                cDate: currenttime,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sclientSecret,
                },
              }
            ).then((response) => {
              if (response.data.recordset[0].CYCLESENGINE === null) {
                setengineCyclelh(0);
                setengineCyclerh(0);
                headerdetails.enginecyclesbf = 0;
              } else {
                setengineCyclelh(
                  Number(response.data.recordset[0].CYCLESENGINE)
                );
                setengineCyclerh(0);
                headerdetails.enginecyclesbf = Number(
                  response.data.recordset[0].CYCLESENGINE
                );
              }
            });

            Axios.post(
              `${BASEURL}/enginehoursbf`,
              {
                regi: regi,
                cDate: currenttime,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sclientSecret,
                },
              }
            ).then((response) => {
              if (response.data.recordset[0].HOURSENGINE === null) {
                setengineHoursBf(0);
                headerdetails.hoursbf = 0;
              } else {
                setengineHoursBf(
                  Number(response.data.recordset[0].HOURSENGINE)
                );
              }
            });

            Axios.post(
              `${BASEURL}/hoursflownbw`,
              {
                regi: regi,
                cDate: currenttime,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sclientSecret,
                },
              }
            ).then((response) => {
              if (response.data.recordset[0].HOURSFLOWN === null) {
                sethoursFlownbf(0);
                headerdetails.hoursbf = 0;
              } else {
                sethoursFlownbf(response.data.recordset[0].HOURSFLOWN);
                headerdetails.hoursbf = response.data.recordset[0].HOURSFLOWN;
              }
            });

            Axios.post(
              `${BASEURL}/getpilotdeffect`,
              {
                docno: 1,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sclientSecret,
                },
              }
            ).then((response) => {
              if (response.data.recordset.length > 0) {
                setPilotDefect(response.data.recordset[0]);
              }
            });

            Axios.post(
              `${BASEURL}/getleftenginereadinsdetails`,
              {
                docno: 1,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sclientSecret,
                },
              }
            ).then((response) => {
              if (response.data.recordset.length > 0) {
                setLeftEngineInput(response.data.recordset[0]);
              }
            });

            Axios.post(
              `${BASEURL}/getrightenginereadinsdetails`,
              {
                docno: 1,
              },
              {
                headers: {
                  "auth-token": authToken,
                  "session-token": sclientSecret,
                },
              }
            ).then((response) => {
              if (response.data.recordset.length > 0) {
                setEngineInput(response.data.recordset[0]);
              }
            });
          });
        }
      }
    } catch (error) {
      console.error("An error occurred in firstDoc:", error);
      // Handle the error here
    }
  }

  function hanleRevertSubmit() {
    try {
      if (window.confirm("Are you sure you want to Revert confirm?")) {
        var d = docno;
        Axios.post(
          `${BASEURL}/revertconfirm`,
          {
            docNo: d,
            userName: myState.user.emailId,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        ).then((response) => {
          alert(response.data.message);
          setStatus(false);
        });
      }
    } catch (error) {
      console.error("An error occurred in hanleRevertSubmit:", error);
      // Handle the error here
    }
  }

  function handleDelete() {
    try {
      if (window.confirm("Are you sure you want to delete?")) {
        var d = docno;
        Axios.post(
          `${BASEURL}/delete`,
          {
            docNo: d,
            userName: myState.user.emailId,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        ).then((response) => {
          alert(response.data.message);
          window.location.reload();
        });
      }
    } catch (error) {
      console.error("An error occurred in handleDelete:", error);
      // Handle the error here
    }
  }

  const renderList = (
    <div>
      <Navbar />
      {/* <h3 className="an__error__occured">{msg}</h3> */}
      <header className="techlog__header">
        <DisableAutocomplete />
      </header>
      <h2
        style={{
          marginBottom: "10px",
          textAlign: "center",
        }}
      >
        {menu || "TECHLOG DOCUMENT CREATION"}
      </h2>
      <div className="for__msg__save__button">
        <div className="for__btn__first__line">
          {" "}
          <button onClick={handleHeaderSubmit} className="save__confirm__top">
            SAVE
          </button>{" "}
          <button onClick={handleSubmit} className="save__confirm__top">
            CONFIRM
          </button>
          <button onClick={hanleRevertSubmit} className="save__confirm__top">
            REVERT CONFIRM
          </button>
          <button onClick={handleDelete} className="save__confirm__top">
            DELETE
          </button>
          {/* <button
            className="save__confirm__top"
            onClick={() => {
              if (window.confirm("do you want to print?")) {
                // if (true) {
                window.print();
              }
              // }
            }}
          >
            PRINT
          </button> */}
          <button
            className="save__confirm__top"
            onClick={() => {
              window.location.reload();
            }}
          >
            NEW DOCUMENT
          </button>
        </div>
        <aside>
          {" "}
          {/* <Link to="/Main">
            {" "}
            <button>BACK</button>
          </Link>
          <button onClick={logOut}>LOGOUT</button> */}
        </aside>
      </div>
      <aside className="aside__display__greater_or__less">
        <p
          onClick={async () => {
            await firstDoc();
            getTotalbf();
          }}
          className="button__for__search__current"
        >
          &lt;&lt;
        </p>
        <p
          onClick={async () => {
            await prevDoc();
            getTotalbf();
          }}
          className="button__for__search__current"
        >
          {" "}
          &lt;{" "}
        </p>
        {/* </aside>
      <aside className="aside__display__greater_or__less"> */}
        <p
          onClick={async () => {
            await nextDoc();
            getTotalbf();
          }}
          className="button__for__search__current"
        >
          &gt;
        </p>
        {/* <p onClick={lastDoc} className="button__for__search__current">
          &gt;&gt;&gt;
        </p> */}
        {/* <Link to="/dashboard"> */}
        <p
          onClick={() => {
            window.location.reload();
          }}
          className="button__for__search__current"
        >
          &gt;&gt;
        </p>
        {/* </Link> */}
      </aside>
      <div>
        <header className={`table_for_1st_info ${gstatus ? "test" : ""}`}>
          <div className="row_1">
            <table className="inside_main">
              <tr>
                <th>
                  <label for="fname">Doc No:</label>
                </th>
                <td>
                  <div className="for__doc__no__and__button">
                    <input
                      className=" disabled__header__secton__top"
                      type="text"
                      id="fname"
                      name="fname"
                      disabled={true}
                      value={docno}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  {" "}
                  <label for="fname">A/C type:</label>
                </th>
                <td>
                  <input
                    className="disabled__header__secton__top"
                    type="text"
                    id="fname"
                    name="aircrafttype"
                    placeholder={aircrafttype}
                    disabled={true}
                    value={headerdetails.aircrafttype}
                  />
                </td>
              </tr>
              <tr>
                <th>
                  {" "}
                  <label for="fname">Serial No:</label>
                </th>
                <td>
                  <input
                    defaultValue={headerdetails.serialno}
                    onChange={(e) => {
                      headerdetails.serialno = e.target.value;
                    }}
                    className="disabled__header__secton__top"
                    type="text"
                    id="fname"
                    name="aircrafttype"
                  />
                </td>
              </tr>
            </table>
            <div className="inside_main_div">
              <tr>
                <th>
                  {" "}
                  <label for="fname">Aircraft No:</label>
                </th>
                <td>
                  {headerdetails.aircraftreg == "" ? (
                    <select
                      className="for__defoult__margin"
                      name="aircraftreg"
                      onChange={(e) => {
                        try {
                          const regi = e.target.value;

                          Axios.post(
                            `${BASEURL}/getaircraftdetails`,
                            {
                              registration: regi,
                            },
                            {
                              headers: {
                                "auth-token": authToken,
                                "session-token": sclientSecret,
                              },
                            }
                          )
                            .then((response) => {
                              console.log(response);
                              headerdetails.ENGINESERIAL =
                                response.data.recordset[0].ENGINENO;
                              headerdetails.PROPELLER1SERIAL =
                                response.data.recordset[0].PROPELLER1;
                              headerdetails.PROPELLER2SERIAL =
                                response.data.recordset[0].PROPELLER2;
                              setType(response.data.recordset[0].AIRCRAFTTYPE);
                              headerdetails.aircraftserialno =
                                response.data.recordset[0].SERIALNO;
                              setSerialNumber(
                                response.data.recordset[0].SERIALNO
                              );
                              headerdetails.aircrafttype =
                                response.data.recordset[0].AIRCRAFTTYPE;
                            })
                            .then(
                              Axios.post(
                                `${BASEURL}/getenginecyclesbf`,
                                {
                                  regi: regi,
                                  cDate: currenttime,
                                },
                                {
                                  headers: {
                                    "auth-token": authToken,
                                    "session-token": sclientSecret,
                                  },
                                }
                              ).then((response) => {
                                console.log(response);
                                if (
                                  response.data.recordset[0].CYCLESENGINE ===
                                  null
                                ) {
                                  setengineCyclelh(0);
                                  setengineCyclerh(0);
                                  headerdetails.enginecyclesbf = 0;
                                } else {
                                  setengineCyclelh(
                                    Number(
                                      response.data.recordset[0].CYCLESENGINE
                                    )
                                  );
                                  setengineCyclerh(0);
                                  headerdetails.enginecyclesbf = Number(
                                    response.data.recordset[0].CYCLESENGINE
                                  );
                                }
                              })
                            )
                            .then(
                              Axios.post(
                                `${BASEURL}/enginehoursbf`,
                                {
                                  regi: regi,
                                  cDate: currenttime,
                                },
                                {
                                  headers: {
                                    "auth-token": authToken,
                                    "session-token": sclientSecret,
                                  },
                                }
                              ).then((response) => {
                                if (
                                  response.data.recordset[0].HOURSENGINE ===
                                  null
                                ) {
                                  setengineHoursBf(0);
                                  headerdetails.hoursbf = 0;
                                } else {
                                  setengineHoursBf(
                                    Number(
                                      response.data.recordset[0].HOURSENGINE
                                    )
                                  );
                                }
                              })
                            )
                            .then(
                              Axios.post(
                                `${BASEURL}/hoursflownbw`,
                                {
                                  regi: regi,
                                  cDate: currenttime,
                                },
                                {
                                  headers: {
                                    "auth-token": authToken,
                                    "session-token": sclientSecret,
                                  },
                                }
                              ).then((response) => {
                                if (
                                  response.data.recordset[0].HOURSFLOWN === null
                                ) {
                                  sethoursFlownbf(0);
                                  headerdetails.hoursbf = 0;
                                } else {
                                  sethoursFlownbf(
                                    response.data.recordset[0].HOURSFLOWN
                                  );
                                  headerdetails.hoursbf =
                                    response.data.recordset[0].HOURSFLOWN;
                                }
                              })
                            )
                            .catch((error) => {
                              console.error(
                                "An error occurred in aircraft details request:",
                                error
                              );
                              // Handle the error here
                            });
                          headerdetails.aircraftreg = e.target.value;
                          // headerdetails.techlogdate = currenttime;
                          // headerdetails.techlogcreateddate = currenttime;
                          headerdetails.techlogcreatedby = "USER";
                          headerdetails.docno = docno;
                          // headerdetails.hoursbf = hoursFlownbf;
                          // headerdetails.enginecyclesbf =
                          //   engineCyclelh + engineCyclerh;
                        } catch (error) {
                          console.error(
                            "An error occurred in aircraft selection:",
                            error
                          );
                          // Handle the error here
                        }
                      }}
                    >
                      <option>Select</option>
                      {aircraft.map((data) => {
                        return (
                          <option value={data.AIRCRAFTREG}>
                            {data.AIRCRAFTREG}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <select
                      name="aircraftreg"
                      className="for__defoult__margin"
                      onChange={(e) => {
                        try {
                          const regi = e.target.value;

                          Axios.post(
                            `${BASEURL}/getaircraftdetails`,
                            {
                              registration: regi,
                            },
                            {
                              headers: {
                                "auth-token": authToken,
                                "session-token": sclientSecret,
                              },
                            }
                          )
                            .then((response) => {
                              setType(response.data.recordset[0].AIRCRAFTTYPE);

                              headerdetails.ENGINESERIAL =
                                response.data.recordset[0].ENGINENO;
                              headerdetails.PROPELLER1SERIAL =
                                response.data.recordset[0].PROPELLER1;
                              headerdetails.PROPELLER2SERIAL =
                                response.data.recordset[0].PROPELLER2;

                              headerdetails.aircraftserialno =
                                response.data.recordset[0].SERIALNO;
                              setSerialNumber(
                                response.data.recordset[0].SERIALNO
                              );
                              headerdetails.aircrafttype =
                                response.data.recordset[0].AIRCRAFTTYPE;
                            })
                            .then(
                              Axios.post(
                                `${BASEURL}/getenginecyclesbf`,
                                {
                                  regi: regi,
                                  cDate: currenttime,
                                },
                                {
                                  headers: {
                                    "auth-token": authToken,
                                    "session-token": sclientSecret,
                                  },
                                }
                              ).then((response) => {
                                if (
                                  response.data.recordset[0].CYCLESENGINE ===
                                  null
                                ) {
                                  setengineCyclelh(0);
                                  setengineCyclerh(0);
                                  headerdetails.enginecyclesbf = 0;
                                } else {
                                  setengineCyclelh(
                                    Number(
                                      response.data.recordset[0].CYCLESENGINE
                                    )
                                  );
                                  setengineCyclerh(0);
                                  headerdetails.enginecyclesbf = Number(
                                    response.data.recordset[0].CYCLESENGINE
                                  );
                                }
                              })
                            )
                            .then(
                              Axios.post(
                                `${BASEURL}/enginehoursbf`,
                                {
                                  regi: regi,
                                  cDate: currenttime,
                                },
                                {
                                  headers: {
                                    "auth-token": authToken,
                                    "session-token": sclientSecret,
                                  },
                                }
                              ).then((response) => {
                                if (
                                  response.data.recordset[0].HOURSENGINE ===
                                  null
                                ) {
                                  setengineHoursBf(0);
                                  headerdetails.hoursbf = 0;
                                } else {
                                  setengineHoursBf(
                                    Number(
                                      response.data.recordset[0].HOURSENGINE
                                    )
                                  );
                                  headerdetails.hoursbf = Number(
                                    response.data.recordset[0].HOURSENGINE
                                  );
                                }
                              })
                            )
                            .then(
                              Axios.post(
                                `${BASEURL}/hoursflownbw`,
                                {
                                  regi: regi,
                                  cDate: currenttime,
                                },
                                {
                                  headers: {
                                    "auth-token": authToken,
                                    "session-token": sclientSecret,
                                  },
                                }
                              ).then((response) => {
                                if (
                                  response.data.recordset[0].HOURSFLOWN === null
                                ) {
                                  sethoursFlownbf(0);
                                  headerdetails.hoursbf = 0;
                                } else {
                                  sethoursFlownbf(
                                    response.data.recordset[0].HOURSFLOWN
                                  );
                                  headerdetails.hoursbf =
                                    response.data.recordset[0].HOURSFLOWN;
                                }
                              })
                            )
                            .then(
                              Axios.post(
                                `${BASEURL}/getDefectNo`,
                                {
                                  docno: docno,
                                },
                                {
                                  headers: {
                                    "auth-token": authToken,
                                    "session-token": sclientSecret,
                                  },
                                }
                              ).then((response) => {
                                setDeffectNo(
                                  response.data.recordset[0].DEFECNO
                                );
                              })
                            );

                          headerdetails.aircraftreg = e.target.value;
                          // headerdetails.techlogdate = currenttime;
                          // headerdetails.techlogcreateddate = currenttime;
                          headerdetails.techlogcreatedby = "USER";
                          headerdetails.docno = docno;
                          // headerdetails.hoursbf = hoursFlownbf;
                          // headerdetails.enginecyclesbf =
                          //   engineCyclelh + engineCyclerh;
                        } catch (error) {
                          console.error(
                            "An error occurred in aircraft selection:",
                            error
                          );
                          // Handle the error here
                        }
                      }}
                    >
                      <option>{headerdetails.aircraftreg}</option>
                      {aircraft.map((data) => {
                        return (
                          <option value={data.AIRCRAFTREG}>
                            {data.AIRCRAFTREG}
                          </option>
                        );
                      })}
                    </select>
                  )}
                </td>
              </tr>

              <tr>
                <th>
                  {" "}
                  <label for="fname">Aircraft SNo:</label>
                </th>
                <td>
                  <input
                    className="disabled__header__secton__top"
                    type="text"
                    id="fname"
                    name="aircraftserialno"
                    placeholder={serialNo}
                    disabled={true}
                    value={headerdetails.aircraftserialno}
                  />
                </td>
              </tr>
              <tr>
                <th>
                  {" "}
                  <label for="fname">Miles:</label>
                </th>
                <td>
                  <input
                    className="disabled__header__secton__top"
                    type="number"
                    id="fname"
                    name="aircraftserialno"
                    onChange={(e) => {
                      headerdetails.mileage = e.target.value;
                      setmilage(e.target.value);
                    }}
                    // disabled
                    // defaultValue={headerdetails.mileage}
                    value={milage}
                  />
                </td>
              </tr>
              <tr className="display__none__style">
                <th>&nbsp;</th>
                <td>
                  <input
                    className="for_default_box defoult__stle__remove"
                    type="text"
                    name="new"
                    disabled="true"
                  />
                </td>
              </tr>
            </div>
            <div className="inside_main_div">
              <tr>
                <th>
                  {" "}
                  <label for="fname">Type of Operation</label>
                </th>
                <td>
                  <>
                    {" "}
                    <select
                      name="operationtype"
                      value={operationtype}
                      onChange={(e) => {
                        setOperationType(e.target.value);
                        headerdetails.operationtype = e.target.value;
                      }}
                      className="newSelect"
                    >
                      <option value={headerdetails.operationtype}>
                        {headerdetails.operationtype}
                      </option>
                      <option value="Mara">Mara</option>
                      <option value="ACMI">ACMI</option>
                      <option value="Dry lease">Dry lease</option>
                    </select>
                  </>
                </td>
              </tr>
              <tr>
                <th>
                  {" "}
                  <label for="fname">Captain:</label>
                </th>
                <td>
                  <>
                    {" "}
                    <select
                      name="pilotcode"
                      onChange={(e) => {
                        setPilot(e.target.value);
                        headerdetails.pilotcode = e.target.value;
                      }}
                      className="newSelect"
                    >
                      <option value="">{piloname}</option>
                      {pilotslist.map((data) => {
                        return (
                          <option value={data.PILOTCODE}>
                            {data.PILOTNAME}
                          </option>
                        );
                      })}
                    </select>
                    <input
                      className="selct_input_section disabled__header__secton__top"
                      type="text"
                      id="fname"
                      name="fname"
                      disabled={true}
                      placeholder={pilot}
                      value={headerdetails.pilotcode}
                    />
                  </>
                </td>
              </tr>
              <tr>
                <th>
                  {" "}
                  <label for="fname">Co-Pilot:</label>
                </th>
                <td>
                  <select
                    className="newSelect"
                    onChange={(e) => {
                      if (e.target.value === pilot) {
                        alert("Can not be same");
                        e.target.value = "SELECT";
                        headerdetails.copilotcode = "";
                        setCopilot("");
                      } else {
                        setCopilot(e.target.value);
                        headerdetails.copilotcode = e.target.value;
                      }
                    }}
                    name="copilotcode"
                    id="cars"
                  >
                    <option value="">{copiloname}</option>
                    {pilotslist.map((data) => {
                      return (
                        <option value={data.PILOTCODE}>{data.PILOTNAME}</option>
                      );
                    })}
                  </select>

                  <input
                    className="selct_input_section disabled__header__secton__top"
                    type="text"
                    id="fname"
                    name="fname"
                    placeholder={copilot}
                    disabled={true}
                    value={headerdetails.copilotcode}
                  />
                </td>
              </tr>
              <tr className="display__none__style">
                <th>&nbsp;</th>
                <td>
                  <input
                    className="for_default_box defoult__stle__remove"
                    type="text"
                    name="new"
                    disabled="true"
                  />
                </td>
              </tr>
            </div>
            <div className="inside_main_div">
              <tr>
                <th>
                  {" "}
                  <label for="fname">Date:</label>
                </th>
                <td>
                  <input
                    className="disabled__header__secton__top hrs__flown__input"
                    type="date"
                    name="date"
                    style={{ width: "120px" }}
                    // disabled="true"
                    // defaultValue={}
                    onChange={(e) => {
                      headerdetails.techlogcreateddate = e.target.value;
                      headerdetails.techlogdate = e.target.value;
                    }}
                    defaultValue={headerdetails.techlogdate.slice(0, 10)}
                  />
                </td>
              </tr>
              <tr>
                <th className="for__default__text">
                  <label>
                    HRS Flown <br /> Past 30 Days:
                  </label>
                </th>
                <td>
                  <input
                    style={{ width: "120px" }}
                    className="disabled__header__secton__top hrs__flown__input"
                    type="input"
                    disabled="true"
                  />
                </td>
              </tr>
              <tr className="display__none__style">
                <th>&nbsp;</th>
                <td>
                  <input
                    className="for_default_box defoult__stle__remove"
                    type="text"
                    name="new"
                    disabled="true"
                  />
                </td>
              </tr>
            </div>
            <div className="inside_main_div">
              <tr>
                <th>
                  {" "}
                  <label for="fname">ENGINE NO</label>
                </th>
                <td>
                  <input
                    className="disabled__header__secton__top hrs__flown__input"
                    disabled="true"
                    defaultValue={headerdetails.ENGINESERIAL}
                  />
                </td>
              </tr>
              <tr>
                <th className="for__default__text">
                  <label>PROPELLER 1 </label>
                </th>
                <td>
                  <input
                    className="disabled__header__secton__top hrs__flown__input"
                    type="input"
                    disabled="true"
                    defaultValue={headerdetails.PROPELLER1SERIAL}
                  />
                </td>
              </tr>
              <tr>
                <th className="for__default__text">
                  <label>PROPELLER 2 </label>
                </th>
                <td>
                  <input
                    className="disabled__header__secton__top hrs__flown__input"
                    type="input"
                    disabled="true"
                    defaultValue={headerdetails.PROPELLER2SERIAL}
                  />
                </td>
              </tr>
            </div>
          </div>
        </header>
        {/* <section className="section_1_buttons">
        <button
          onClick={() => {
            setIntState("log");
          }}
        >
          1 flight time{" "}
        </button>

        <button
          onClick={() => {
            setIntState("pilot");
          }}
        >
          {" "}
          2 pilot defects any
        </button>

        <button>notes</button>
      </section> */}
        {/* main page table begins here */}
        {intState === "log" ? techlogRenderList : pilotDeffRenderList}
        <section className="section_for2nd_page" style={{ display: "none" }}>
          <h3 className="heading_for_head2nd_page">
            Certificate of Release To Service{" "}
          </h3>
          <section>
            <div className="certificate__of_dew">
              <div>
                <th>
                  {" "}
                  <label for="fname">C of A Due on:</label>
                </th>
                <td>
                  <input
                    onChange={(e) => {
                      var year = e.target.value.slice(0, 4);
                      var month = e.target.value.slice(5, 7);
                      var da = e.target.value.slice(8, 10);
                      headerdetails.coadueon = da + "/" + month + "/" + year;
                    }}
                    name="coadueon"
                    type="date"
                    id="fname"
                    defaultValue={headerdetails.coadueon.slice(0, 10)}
                  />
                </td>
              </div>
            </div>
            <div className="certificate__of_dew">
              <div>
                <th>
                  {" "}
                  <label for="fname">Next check Type:</label>
                </th>
                <td>
                  <input
                    onChange={handleHeaderDataChange}
                    id="fname"
                    name="nextchecktype"
                    type="text"
                    // onInput={(event) => {
                    //   event.target.value = event.target.value.replace(
                    //     /[^0-9]/g,

                    //     ""
                    //   );
                    // }}
                    defaultValue={headerdetails.nextchecktype}
                  />
                </td>
              </div>
              <div>
                <th>
                  {" "}
                  <label for="fname">Due at a/f:</label>
                </th>
                <td>
                  <input
                    onChange={(e) => {
                      var year = e.target.value.slice(0, 4);
                      var month = e.target.value.slice(5, 7);
                      var da = e.target.value.slice(8, 10);
                      headerdetails.nextchecktypedueataf =
                        da + "/" + month + "/" + year;
                    }}
                    type="date"
                    id="fname"
                    name="nextchecktypedueataf"
                    defaultValue={headerdetails.nextchecktypedueataf.slice(
                      0,
                      10
                    )}
                  />
                </td>
              </div>
              <div>
                <th>
                  {" "}
                  <label for="fname">Next check type hours:</label>
                </th>
                <td>
                  <input
                    onChange={(e) => {
                      headerdetails.nextchecktypehours = e.target.value;
                    }}
                    type="text"
                    id="fname"
                    onInput={(event) => {
                      event.target.value = event.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                    }}
                    name="nextchecktypehours"
                    defaultValue={headerdetails.nextchecktypehours}
                  />
                </td>
              </div>
            </div>
          </section>
        </section>
      </div>
      <Footer />
    </div>
  );
  return <div>{isLoading ? <Loading /> : renderList}</div>;
};

export default Dashboard;
