import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import axios from "axios";
import AllPagesNavbar from "../Navbar";
import Loading from "../Loading";
import { BiSolidEditAlt } from "react-icons/bi";
import { useLocation } from "react-router-dom";

import "../../CSS/EngineMaster.css";
const MYTAKEOFFSANDLANDINGS = () => {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const state = useSelector((state) => state?.loginreducer);
  const date = new Date().toISOString();
  const userPass = state?.user?.userCode;

  const [PassPROPELLERNO, setPassPROPELLERNO] = useState("");
  const [search, setSearch] = useState("");
  const [PropellerList, setPropellerList] = useState([]);
  const [seldivision, setSelDivision] = useState("");
  const userState = useSelector((state) => state.getUserState);
  const userLoggeduserCode = userState.user.userCode;

  const [isloading, setisloading] = useState(false);

  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const localStartOfYear = new Date(
    startOfYear.getTime() - startOfYear.getTimezoneOffset() * 60000
  );

  const [startdate, setStartdate] = useState(localStartOfYear.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());

  const [PassLASTOVERREADING, setPassLASTOVERREADING] = useState("");
  const [PassLASTOVERHAULDATE, setPassLASTOVERHAULDATE] = useState("");

  useEffect(() => {
    if (userLoggeduserCode) {
      FetchDetails();
    }
  }, [userLoggeduserCode]);
  function FetchDetails() {
    setisloading(true);
    axios
      //   .get(`${BASEURL}/getTechLogSheetData)`, {
      .get(
        `${BASEURL}/getHistoricPilotSchedules?PILOT CODE=${userLoggeduserCode}`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sclientSecret,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setisloading(false);
        setPropellerList(res?.data);
      })
      .catch((err) => {
        console.log("Error while fetching /getPropellers List", err);
      });
  }
  const filteredData = PropellerList.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    // Convert all dates to Date objects for filtering
    const sDate = new Date(item["TECHLOG DATE"]);
    const eDate = new Date(item["TECHLOG DATE"]);

    // Text Match - check if any search term matches any field
    const textMatch = searchData.every((term) => {
      return [
        "AIRCRAFT",
        "BLOCK TIME HOURS",
        "BLOCK TIME MINUTES",
        "CHECK OFF",
        "CHECK ON",
        "CREATED DATE",
        "ENGINE CYCLES",
        "ENGINESERIAL",
        "FLYING HRS",
        "FROM",
        "FUEL LH",
        "FUEL RH",
        "LANDING TIME",
        "LEGINDEX",
        "MILES",
        "MILES/HOUR",
        "MINUTES",
        "PERIOD",
        "PILOT CODE",
        "PILOT NAME",
        "PROPELLER1SERIAL",
        "PROPELLER2SERIAL",
        "SERIALNUMBER",
        "TAKE OFF TIME",
        "TECH LOG NO",
        "TECHLOG DATE",
        "TECHLOG TYPE",
        "TO",
        "TOTAL FUEL",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    // Date Match - check if date is within range
    const dateMatch =
      (!startdate || sDate >= new Date(startdate)) &&
      (!enddate || eDate <= new Date(enddate));

    // Return true if both text and date match
    return textMatch && dateMatch;
  });

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  if (isloading) {
    return <Loading />;
  }

  return (
    <div className="bg-gray-50 min-h-[100vh]">
      <AllPagesNavbar />
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page">{menu}</h1>
      </header>
      <section className="lg:flex items-center gap-5 mx-5">
        <div className="lg:flex items-center gap-3">
          <p>Search:</p>
          <input
            className="w-[300px] h-7 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <label className=" text-gray-700">From Date</label>
          <input
            type="date"
            onChange={(e) => {
              setStartdate(e.target.value);
            }}
            value={startdate.split("T")[0]}
            placeholder="From Date"
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <label className=" text-gray-700">End Date</label>
          <input
            type="date"
            onChange={(e) => {
              setEnddate(e.target.value);
            }}
            value={enddate.split("T")[0]}
            placeholder="To Date"
          />
        </div>
      </section>
      {isloading ? (
        <Loading />
      ) : (
        <div className="overflow-y-auto h-[500px] px-4">
          <table className="custom-table text-sm overflow__y__ relative ">
            <thead
              className="header-cell sticky top-[0px] left-[10px]"
              style={{ zIndex: 50 }}
            >
              <tr>
                <td>AIRCRAFT</td>
                <td>BLOCK TIME HOURS</td>
                <td>BLOCK TIME MINUTES</td>
                <td>CHECK OFF</td>
                <td>CHECK ON</td>
                <td>CREATED DATE</td>
                <td>ENGINE CYCLES</td>
                <td>ENGINE SERIAL</td>
                <td>FLYING HRS</td>
                <td>FROM</td>
                <td>FUEL LH</td>
                <td>FUEL RH</td>
                <td>LANDING TIME</td>
                <td>LEGINDEX</td>
                <td>MILES</td>
                <td>MILES/HOUR</td>
                <td>MINUTES</td>
                <td>PERIOD</td>
                <td>PILOT CODE</td>
                <td>PILOT NAME</td>
                <td>PROPELLER1 SERIAL</td>
                <td>PROPELLER2 SERIAL</td>
                <td>SERIAL NUMBER</td>
                <td>TAKE OFF TIME</td>
                <td>TECH LOG NO</td>
                <td>TECHLOG DATE</td>
                <td>TECHLOG TYPE</td>
                <td>TO</td>
                <td>TOTAL FUEL</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData.map((item, index) => (
                  <tr key={index} className="for__more__data">
                    <td>
                      <p className=" ">{item["AIRCRAFT"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["BLOCK TIME HOURS"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["BLOCK TIME MINUTES"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">
                        {item["CHECK OFF"]
                          ? item["CHECK OFF"].slice(11, 19)
                          : "NA"}
                      </p>
                    </td>
                    <td>
                      <p className=" ">
                        {item["CHECK ON"]
                          ? item["CHECK ON"].slice(11, 19)
                          : "NA"}
                      </p>
                    </td>
                    <td>
                      <p className=" ">
                        {item["CREATED DATE"]
                          ? item["CREATED DATE"].slice(8, 10) +
                            "/" +
                            item["CREATED DATE"].slice(5, 7) +
                            "/" +
                            item["CREATED DATE"].slice(0, 4)
                          : "NA"}
                      </p>
                    </td>
                    <td>
                      <p className=" ">{item["ENGINE CYCLES"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["ENGINESERIAL"] || "NA"}</p>
                    </td>
                    <td>
                      <p className="  text-right">
                        {item["FLYING HRS"] || "NA"}
                      </p>
                    </td>
                    <td>
                      <p className=" ">{item["FROM"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["FUEL LH"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["FUEL RH"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">
                        {item["LANDING TIME"]
                          ? item["LANDING TIME"].slice(11, 19)
                          : "NA"}
                      </p>
                    </td>
                    <td>
                      <p className="  text-right">{item["LEGINDEX"] || "NA"}</p>
                    </td>
                    <td>
                      <p className="  text-right">{item["MILES"] || "NA"}</p>
                    </td>
                    <td>
                      <p className="  text-right">
                        {item["MILES/HOUR"] !== undefined &&
                        item["MILES/HOUR"] !== null
                          ? item["MILES/HOUR"].toFixed(2)
                          : "NA"}
                      </p>
                    </td>
                    <td>
                      <p className=" ">{item["MINUTES"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["PERIOD"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["PILOT CODE"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["PILOT NAME"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["PROPELLER1SERIAL"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["PROPELLER2SERIAL"] || "NA"}</p>
                    </td>
                    <td>
                      <p className="  text-right">
                        {item["SERIALNUMBER"] || "NA"}
                      </p>
                    </td>
                    <td>
                      <p className=" ">
                        {item["TAKE OFF TIME"]
                          ? item["TAKE OFF TIME"].slice(11, 19)
                          : "NA"}
                      </p>
                    </td>
                    <td>
                      <p className=" ">{item["TECH LOG NO"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">
                        {item["TECHLOG DATE"]
                          ? item["TECHLOG DATE"].slice(8, 10) +
                            "/" +
                            item["TECHLOG DATE"].slice(5, 7) +
                            "/" +
                            item["TECHLOG DATE"].slice(0, 4)
                          : "NA"}
                      </p>
                    </td>
                    <td>
                      <p className=" ">{item["TECHLOG TYPE"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["TO"] || "NA"}</p>
                    </td>
                    <td>
                      <p className=" ">{item["TOTAL FUEL"] || "NA"}</p>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MYTAKEOFFSANDLANDINGS;
