import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { useSelector } from "react-redux";
import AllPagesNavbar from "./Navbar";
import Loading from "./Loading";
import "../CSS/EngineMaster.css";
import { useLocation } from "react-router-dom";
import { BiSolidEditAlt } from "react-icons/bi";
import Footer from "./Footer";
const PILOTSCHEDULES = () => {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;

  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const [isloading, setisloading] = useState(false);

  const [PropellerList, setPropellerList] = useState([]);

  const userState = useSelector((state) => state.getUserState);
  const userLoggeduserCode = userState.user.userCode;
  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const localStartOfYear = new Date(
    startOfYear.getTime() - startOfYear.getTimezoneOffset() * 60000
  );

  const [startdate, setStartdate] = useState(localStartOfYear.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());
  const [search, setSearch] = useState("");

  console.log(userLoggeduserCode);

  const [SCHEDULENOpass, setSCHEDULENOpass] = useState("");
  const [PILOTCODE, setPILOTCODE] = useState("");
  const [PILOTNAME, setPILOTNAME] = useState("");
  const [DEPARTUREDATE, setDEPARTUREDATE] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [DEPARTURETIME, setDEPARTURETIME] = useState();
  const [DEPTFROM, setDEPTFROM] = useState("");
  const [ARRIVALAT, setARRIVALAT] = useState("");
  const [AIRCRAFTREGNO, setAIRCRAFTREGNO] = useState("");
  const [restDays, setRestDays] = useState("0");
  const [restHours, setRestHours] = useState("0");
  const [nextAvailableDate, setNextAvailableDate] = useState("");
  const [arrivalDate, setArrivalDate] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [STATUS, setSTATUS] = useState("");
  const [ACTUALDEPTDATE, setACTUALDEPTDATE] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [ACTUALDEPTTIME, setACTUALDEPTTIME] = useState(
    new Date().toLocaleTimeString()
  );
  const [isLoading, setIsLoading] = useState(false);

  const [pilotOptions, setPilotOptions] = useState([]);
  const [routeOptions, setRouteOptions] = useState([]);
  const [aircraftOptions, setAircraftOptions] = useState([]);
  const [statusFilter, setStatusFilter] = useState(null);
  const handleRestHoursChange = (e) => {
    const value = e.target.value;

    // Check if the input is a valid integer or empty
    if (value === "" || /^\d+$/.test(value)) {
      setRestHours(value);
    } else {
      // If input contains a decimal point, reset to 0
      setRestHours("0");
    }
  };

  const handleRestDaysChange = (e) => {
    const value = e.target.value;

    console.log("Rest Days Input:", value); // Debugging

    // Check if the input is a valid integer or empty
    if (value === "" || /^\d+$/.test(value)) {
      setRestDays(value);
    } else {
      // If input contains a decimal point, reset to 0
      setRestDays("0");
    }
  };
  console.log(restHours);
  useEffect(() => {
    fetchDropdownData();
    const timeInterval = setInterval(() => {
      setACTUALDEPTTIME(new Date().toLocaleTimeString());
    }, 1000);
    return () => clearInterval(timeInterval);
  }, []);

  const fetchDropdownData = () => {
    setIsLoading(true);
    //   getPilotSchedules
    axios
      .get(`${BASEURL}/getPilotSchedules`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        console.log(res.data);
        setPropellerList(res?.data);
      })
      .catch((err) => {
        console.log("Error while fetching /getPilotSchedules List", err);
      });
    axios
      .get(`${BASEURL}/getPilotScheduleNo`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        console.log(res.data?.[0].SCHEDULENO);
        setSCHEDULENOpass(res.data?.[0].SCHEDULENO);
      })
      .catch((err) => {
        console.log("Error while fetching /getPilotScheduleNo List", err);
      });

    axios
      .get(`${BASEURL}/getpilots`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        console.log(res.data.recordsets[0]);

        const pilots = res.data.recordsets[0].map((pilot) => ({
          value: pilot.PILOTCODE,
          label: pilot.PILOTNAME,
        }));
        setPilotOptions(pilots);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching pilots:", err);
        setIsLoading(false);
      });

    axios
      .get(`${BASEURL}/getRoutes`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        const routes = res.data.recordsets[0].map((route) => ({
          value: route.CITYCODE,
          label: route.CITYCODE,
        }));
        setRouteOptions(routes);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching routes:", err);
        setIsLoading(false);
      });

    axios
      .get(`${BASEURL}/getircrafts`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        const aircrafts = res.data.recordsets[0].map((aircraft) => ({
          value: aircraft.AIRCRAFTREG,
          label: aircraft.AIRCRAFTREG,
        }));
        setAircraftOptions(aircrafts);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching aircrafts:", err);
        setIsLoading(false);
      });
  };

  const calculateNextAvailableDateManually = () => {
    if (!arrivalDate || !arrivalTime || !restDays || !restHours) return "";

    // Parse arrival time into hours and minutes
    let [arrivalHour, arrivalMinute] = arrivalTime.split(":").map(Number);

    // Add rest hours to arrival hour
    let totalHours = arrivalHour + parseInt(restHours, 10);

    // Add rest days to the arrival date
    let nextAvailableDate = new Date(arrivalDate);
    nextAvailableDate.setDate(
      nextAvailableDate.getDate() + parseInt(restDays, 10)
    );

    // Handle overflow if total hours exceed 24
    if (totalHours >= 24) {
      // Add extra days for hours exceeding 24
      const extraDays = Math.floor(totalHours / 24);
      nextAvailableDate.setDate(nextAvailableDate.getDate() + extraDays);
      totalHours = totalHours % 24; // Keep only the remaining hours
    }

    // Format the result back into a date string (yyyy-mm-dd)
    const formattedDate = nextAvailableDate.toISOString().slice(0, 10);

    // Ensure time is formatted as HH:MM
    const formattedTime = `${String(totalHours).padStart(2, "0")}:${String(
      arrivalMinute
    ).padStart(2, "0")}`;

    // Combine the final date and time
    return `${formattedDate}T${formattedTime}:00`;
  };
  const handleDepartureDateChange = (e) => {
    setDEPARTUREDATE(e.target.value);
    validateDates(e.target.value, arrivalDate);
  };

  const handleArrivalDateChange = (e) => {
    setArrivalDate(e.target.value);
    validateDates(DEPARTUREDATE, e.target.value);
  };
  const validateDates = (depDate, arrDate) => {
    // Reset error state

    // Convert date strings to Date objects
    const dep = new Date(depDate);
    const arr = new Date(arrDate);

    // Check if arrivalDate is less than departureDate
    if (arrDate && depDate && arr < dep) {
      alert("Arrival date cannot be earlier than departure date.");
      setArrivalDate("");
    }
  };
  const handleSave = async () => {
    const passTime = new Date().toISOString();
    const passTimeTrim = passTime.slice(11, 16);
    console.log(passTimeTrim);

    if (!SCHEDULENOpass) {
      alert("Please Provide SCHEDULE NO");
      return;
    }
    if (!PILOTNAME) {
      alert("Please Provide PILOT NAME");
      return;
    }
    if (!DEPARTUREDATE) {
      alert("Please Provide DEPARTURE DATE");
      return;
    }
    if (!DEPARTURETIME) {
      alert("Please Provide DEPARTURE TIME");
      return;
    }
    if (!DEPTFROM) {
      alert("Please Provide DEPARTURE FROM");
      return;
    }
    if (!ARRIVALAT) {
      alert("Please Provide ARRIVAL AT");
      return;
    }
    if (!AIRCRAFTREGNO) {
      alert("Please Provide AIRCRAFT REG NO");
      return;
    }
    if (!arrivalDate) {
      alert("Please Provide Arrival Date");
      return;
    }
    if (!arrivalDate) {
      alert("Please Provide Arrival Date");
      return;
    }
    if (!arrivalTime) {
      alert("Please Provide Arrival Time");
      return;
    }
    if (!restDays) {
      alert("Please Provide Rest Days");
      return;
    }
    if (!restHours) {
      alert("Please Provide Rest Hours");
      return;
    }
    const calculatedNextAvailableDate = calculateNextAvailableDateManually();
    console.log(arrivalDate, arrivalTime, restDays, restHours);

    console.log(calculatedNextAvailableDate);

    //confirm
    const confirmSave = window.confirm("Are you sure you want to Save?");
    if (confirmSave) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${BASEURL}/insertPilotSchedule`,
          {
            SCHEDULENO: SCHEDULENOpass,
            PILOTCODE: PILOTCODE,
            PILOTNAME: PILOTNAME,
            DEPARTUREDATE: DEPARTUREDATE,
            DEPARTURETIME: DEPARTURETIME,
            ARRIVALDATE: arrivalDate,
            ARRIVALTIME: arrivalTime,
            DEPTFROM: DEPTFROM,
            ARRIVALAT: ARRIVALAT,
            RESTDAYS: restDays,
            RESTHOURS: restHours,
            NEXTAVAILABLEDATE: calculatedNextAvailableDate,
            AIRCRAFTREGNO: AIRCRAFTREGNO,
            STATUS: "SCHEDULE",
            CREATEDBY: userLoggeduserCode,
            ACTUALDEPTDATE: "",
            ACTUALDEPTTIME: "",
            ACTUALARRIVALDATE: "",
            ACTUALARRIVALTIME: "",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        );
        alert(response.data.message);
        window.location.reload();
        setIsLoading(false);
      } catch (error) {
        console.error("Error saving schedule:", error);
        setIsLoading(false);
        alert("Failed to save the schedule.");
      }
    }
  };

  const filteredData = PropellerList.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = new Date(item["DEPARTUREDATE"]);
    const eDate = new Date(item["DEPARTUREDATE"]);

    const textMatch = searchData.every((term) => {
      return [
        "SCHEDULENO",
        "PILOTCODE",
        "PILOTNAME",
        "DEPARTUREDATE",
        "DEPARTURETIME",
        "DEPTFROM",
        "ARRIVALAT",
        "AIRCRAFTREGNO",
        "STATUS",
        "ACTUALDEPTDATE",
        "ACTUALDEPTTIME",
        "CREATEDBY",
        "CREATEDTIME",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= new Date(startdate)) &&
      (!enddate || eDate <= new Date(enddate));

    const statusMatch = !statusFilter || item.STATUS === statusFilter;

    return textMatch && dateMatch && statusMatch;
  });

  function handleEdit(item) {
    setSCHEDULENOpass(item.SCHEDULENO);
    setPILOTCODE(item.PILOTCODE);
    setPILOTNAME(item.PILOTNAME);
    setDEPARTUREDATE(item.DEPARTUREDATE.slice(0, 10));
    setDEPARTURETIME(item.DEPARTURETIME.slice(11, 19));
    setDEPTFROM(item.DEPTFROM);
    setARRIVALAT(item.ARRIVALAT);
    setAIRCRAFTREGNO(item.AIRCRAFTREGNO);
    setArrivalDate(item.ARRIVALDATE.slice(0, 10));
    setArrivalTime(item.ARRIVATIME.slice(11, 19));
    setRestDays(item.RESTDAYS);
    setRestHours(item.RESTHOURS);

    setSTATUS("SCHEDULE");
  }

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  //   cancle cancelTripSchedued

  const cancelTripSchedued = async (item) => {
    const confirmSave = window.confirm(
      `Are you sure you want to cancel \nSCHEDULE NO: ${item.SCHEDULENO}`
    );

    if (confirmSave) {
      setIsLoading(true);

      try {
        const response = await axios.post(
          `${BASEURL}/insertPilotSchedule`,
          {
            SCHEDULENO: item.SCHEDULENO,
            PILOTCODE: item.PILOTCODE,
            PILOTNAME: item.PILOTNAME,
            DEPARTUREDATE: item.DEPARTUREDATE.slice(0, 10),
            DEPARTURETIME: item.DEPARTURETIME.slice(11, 19),
            ARRIVALDATE: item.ARRIVALDATE.slice(0, 10),
            ARRIVALTIME: item.ARRIVATIME.slice(11, 19),
            DEPTFROM: item.DEPTFROM,
            ARRIVALAT: item.ARRIVALAT,
            RESTDAYS: item.RESTDAYS,
            RESTHOURS: item.RESTHOURS,
            NEXTAVAILABLEDATE: item.NEXTAVAILABLEDATE,
            AIRCRAFTREGNO: item.AIRCRAFTREGNO,
            STATUS: "CANCEL",
            CREATEDBY: userLoggeduserCode,
            ACTUALDEPTDATE: "",
            ACTUALDEPTTIME: "",
            ACTUALARRIVALDATE: "",
            ACTUALARRIVALTIME: "",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        );
        alert(response.data.message);
        window.location.reload();
        setIsLoading(false);
      } catch (error) {
        console.error("Error saving schedule:", error);
        setIsLoading(false);
        alert("Failed to save the schedule.");
      }
    }
  };
  if (isloading) {
    return <Loading />;
  }

  return (
    <div className="bg-gray-50 min-h-[100vh] newpages__input">
      <AllPagesNavbar />
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page">{menu}</h1>
        <div className="lg:flex items-center gap-5 my-1">
          {" "}
          <button
            onClick={() => {
              window.location.reload();
            }}
            className="border-l-indigo-900 rounded mx-2 text-white"
          >
            New
          </button>
          <button
            onClick={handleSave}
            className="border-l-indigo-900 rounded mx-2 text-white"
          >
            Save
          </button>
        </div>
      </header>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="lg:mx-5 mx-2 bg-white lg:p-5 p-3 mt-2 rounded">
          <div className="lg:flex justify-between">
            <div>
              <p className=" text-gray-700">SCHEDULE NO </p>
              <input
                type="text"
                value={SCHEDULENOpass ? SCHEDULENOpass : ""}
                readOnly
                className="w-[150px] h-10 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>{" "}
            <div className="lg:w-[400px]">
              <p className=" text-gray-700">Pilot</p>

              <Select
                options={pilotOptions}
                value={{
                  value: PILOTNAME,
                  label: PILOTNAME || "Select ",
                }}
                onChange={(selectedOption) => {
                  setPILOTCODE(selectedOption.value);
                  setPILOTNAME(selectedOption.label);
                }}
                className="w-full z-[300]"
              />
            </div>
            <div>
              <p className=" text-gray-700">Pilot Code </p>
              <input
                type="text"
                value={PILOTCODE ? PILOTCODE : ""}
                readOnly
                className="w-full h-10 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              <p className=" text-gray-700">Departure Date</p>
              <input
                type="date"
                value={DEPARTUREDATE}
                onChange={handleDepartureDateChange}
                className="w-full h-10 p-2 rounded border border-gray-300  focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              {" "}
              <p className=" text-gray-700">Departure Time</p>
              <input
                type="time"
                value={DEPARTURETIME}
                onChange={(e) => setDEPARTURETIME(e.target.value)}
                className="w-full h-10 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
          </div>
          <div className="lg:grid lg:grid-cols-2 gap-10 my-4">
            <div>
              <p className=" text-gray-700">Departure From</p>
              <Select
                value={{ value: DEPTFROM, label: DEPTFROM || "" }}
                options={routeOptions}
                onChange={(selectedOption) => setDEPTFROM(selectedOption.value)}
                className="w-full z-[280]"
              />
            </div>
            <div>
              <p className=" text-gray-700">Arrival At</p>
              <Select
                value={{ value: ARRIVALAT, label: ARRIVALAT || "" }}
                options={routeOptions.filter(
                  (route) => route.value !== DEPTFROM
                )}
                onChange={(selectedOption) =>
                  setARRIVALAT(selectedOption.value)
                }
                className="w-full z-[250]"
              />
            </div>
          </div>
          <div className="lg:flex  gap-4 my-4">
            <div>
              <p className=" text-gray-700">Aircraft Registration No.</p>
              <Select
                value={{ value: AIRCRAFTREGNO, label: AIRCRAFTREGNO || "" }}
                options={aircraftOptions}
                onChange={(selectedOption) =>
                  setAIRCRAFTREGNO(selectedOption.value)
                }
                className="lg:w-[300px] z-[220]"
              />
            </div>
            <div>
              <p className=" text-gray-700">Arrival Date</p>

              <input
                type="date"
                value={arrivalDate}
                onChange={handleArrivalDateChange}
                className="w-full h-10 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>{" "}
            <div>
              <p className=" text-gray-700">Arrival Time</p>

              <input
                type="time"
                value={arrivalTime}
                onChange={(e) => setArrivalTime(e.target.value)}
                className="w-full h-10 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>{" "}
            <div>
              <p className=" text-gray-700">Rest Days</p>

              <input
                type="number"
                value={restDays}
                min="0" // Ensure non-negative values
                step="1" // Enforce integer values
                onChange={handleRestDaysChange}
                className="w-[100px] h-10 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>{" "}
            <div>
              <p className="text-gray-700">Rest Hours</p>

              <input
                type="number"
                min="0" // Minimum value allowed
                step="1" // Only integer values
                value={restHours}
                onChange={handleRestHoursChange}
                className="w-[100px] h-10 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              <p className=" text-gray-700">Status</p>

              <input
                readOnly
                value={"SCHEDULE"}
                className="w-full h-10 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            {/* <div>
              <p className=" text-gray-700">Actual Departure Date</p>
              <input
                readOnly
                type="date"
                value={ACTUALDEPTDATE}
                onChange={(e) => setACTUALDEPTDATE(e.target.value)}
                className="w-full h-10 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div>
            <div>
              <p className=" text-gray-700">Actual Departure Time</p>
              <input
                type="text"
                value={ACTUALDEPTTIME}
                readOnly
                className="w-full h-10 p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
              />
            </div> */}
          </div>
        </div>
      )}

      <section className="lg:flex items-center gap-8 mx-5">
        <div className="lg:flex items-center gap-3">
          <p>Search:</p>
          <input
            className="w-[300px] h-7 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
            type="text"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <label className=" text-gray-700">From Date</label>
          <input
            type="date"
            onChange={(e) => {
              setStartdate(e.target.value);
            }}
            value={startdate.split("T")[0]}
            placeholder="From Date"
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <label className=" text-gray-700">End Date</label>
          <input
            type="date"
            onChange={(e) => {
              setEnddate(e.target.value);
            }}
            value={enddate.split("T")[0]}
            placeholder="To Date"
          />
        </div>
        <div className="lg:flex items-center gap-3">
          <p className=" text-gray-700">Status</p>
          <Select
            options={[
              { value: "SCHEDULE", label: "SCHEDULE" },
              { value: "DEPARTURE", label: "DEPARTURE" },
              { value: "ARRIVAL", label: "ARRIVAL" },
              { value: "CANCEL", label: "CANCEL" },
            ]}
            isClearable
            placeholder="Select.."
            onChange={(selectedOption) =>
              setStatusFilter(selectedOption ? selectedOption.value : null)
            }
            className="w-[200px] z-[100]"
          />
        </div>
      </section>
      {isloading ? (
        <Loading />
      ) : (
        <div className="overflow-y-auto h-[500px] px-4">
          <table className="custom-table  overflow__y__ relative text-[12px] ">
            <thead
              className="header-cell sticky top-[0px] left-[10px]"
              style={{ zIndex: 50 }}
            >
              <tr>
                <td className="text-right">SCHEDULE NO</td>
                <td className="">PILOT CODE</td>
                <td>PILOT NAME</td>
                <td>DEPARTURE DATE & TIME</td>

                <td>DEPARTURE FROM</td>
                <td>ARRIVAL AT</td>
                <td>ARRIVAL DATE & TIME</td>
                <td>REST DAYS</td>
                <td>REST HOURS</td>
                <td>NEXT AVAILABLE DATE</td>
                <td>AIRCRAFT REG NO</td>
                <td>ACTUAL DEPARTURE DATE & TIME</td>
                <td>ACTUAL ARRIVAL DATE & TIME</td>

                <td>CREATED BY</td>
                <td>CREATED TIME</td>
                <td>STATUS</td>
                <td>EDIT</td>
                <td>UPDATE</td>
              </tr>
            </thead>
            <tbody>
              {filteredData.length > 0 &&
                filteredData.map((item, index) => (
                  <tr
                    key={index}
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    <td className=" text-right">
                      {item["SCHEDULENO"] ? item["SCHEDULENO"] : "NA"}
                    </td>
                    <td className=" ">
                      {item["PILOTCODE"] ? item["PILOTCODE"] : "NA"}
                    </td>
                    <td className="min-w-[140px]">
                      {item["PILOTNAME"] ? item["PILOTNAME"] : "NA"}
                    </td>
                    <td className="">
                      {item["DEPARTUREDATE"]
                        ? item["DEPARTUREDATE"].slice(8, 10) +
                          "/" +
                          item["DEPARTUREDATE"].slice(5, 7) +
                          "/" +
                          item["DEPARTUREDATE"].slice(0, 4) +
                          " " +
                          item["DEPARTURETIME"].slice(11, 19)
                        : "NA"}
                    </td>
                    <td className=" ">
                      {item["DEPTFROM"] ? item["DEPTFROM"] : "NA"}
                    </td>
                    <td className=" ">
                      {item["ARRIVALAT"] ? item["ARRIVALAT"] : "NA"}
                    </td>
                    <td className="">
                      {item["ARRIVALDATE"]
                        ? item["ARRIVALDATE"]?.slice(8, 10) +
                          "/" +
                          item["ARRIVALDATE"]?.slice(5, 7) +
                          "/" +
                          item["ARRIVALDATE"]?.slice(0, 4) +
                          " " +
                          item["ARRIVATIME"]?.slice(11, 19)
                        : "NA"}
                    </td>
                    <td className=" ">
                      {item["RESTDAYS"] ? item["RESTDAYS"] : "0"}
                    </td>
                    <td className=" ">
                      {item["RESTHOURS"] ? item["RESTHOURS"] : "0"}
                    </td>
                    <td className="">
                      {item["NEXTAVAILABLEDATE"]
                        ? item["NEXTAVAILABLEDATE"]?.slice(8, 10) +
                          "/" +
                          item["NEXTAVAILABLEDATE"]?.slice(5, 7) +
                          "/" +
                          item["NEXTAVAILABLEDATE"]?.slice(0, 4) +
                          " " +
                          item["NEXTAVAILABLEDATE"]?.slice(11, 19)
                        : "NA"}
                    </td>
                    <td className=" ">
                      {item["AIRCRAFTREGNO"] ? item["AIRCRAFTREGNO"] : "NA"}
                    </td>
                    <td className="">
                      {item["ACTUALDEPTDATE"]
                        ? item["ACTUALDEPTDATE"].slice(8, 10) +
                          "/" +
                          item["ACTUALDEPTDATE"].slice(5, 7) +
                          "/" +
                          item["DEPARTUREDATE"].slice(0, 4) +
                          " " +
                          item["ACTUALDEPTTIME"].slice(11, 19)
                        : "N/A"}
                    </td>
                    <td className="">
                      {item["ACTUALARRIVALDATE"]
                        ? item["ACTUALARRIVALDATE"].slice(8, 10) +
                          "/" +
                          item["ACTUALARRIVALDATE"].slice(5, 7) +
                          "/" +
                          item["ACTUALARRIVALDATE"].slice(0, 4) +
                          " " +
                          item["ACTUALARRIVATIME"].slice(11, 19)
                        : "N/A"}
                    </td>
                    <td className=" ">
                      {item["CREATEDBY"] ? item["CREATEDBY"] : "NA"}
                    </td>
                    <td className=" ">
                      {item["CREATEDTIME"]
                        ? item["CREATEDTIME"]?.slice(11, 19)
                        : "NA"}
                    </td>{" "}
                    <td className=" ">
                      {item["STATUS"] ? item["STATUS"] : "NA"}
                    </td>{" "}
                    <td
                      className="w-[60px]"
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontSize: "22px",
                      }}
                    >
                      {!item.ACTUALDEPTDATE && (
                        <BiSolidEditAlt
                          onClick={() => {
                            handleEdit(item);
                            scrollToSection();
                          }}
                        />
                      )}
                    </td>
                    <td className=" ">
                      {!item.ACTUALDEPTDATE && item["STATUS"] !== "CANCEL" && (
                        <button
                          className="px-1 rounded py-1  border-none outline-none "
                          onClick={() => {
                            cancelTripSchedued(item);
                          }}
                        >
                          Cancel
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default PILOTSCHEDULES;
