import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import axios from "axios";
import AllPagesNavbar from "./Navbar";
import Loading from "./Loading";
import { BiSolidEditAlt } from "react-icons/bi";
import { useLocation } from "react-router-dom";

import "./../CSS/EngineMaster.css";
const ENGINEOVERHAUL = () => {
  const location = useLocation();
  const menu = location.state ? location.state.menu : null;
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sclientSecret = window.sessionStorage.getItem("sclientSecret");
  const state = useSelector((state) => state?.loginreducer);
  const date = new Date().toISOString();
  const myState = useSelector((state) => state.updateUserRights);
  const userState = useSelector((state) => state.getUserState);
  console.log(myState);
  console.log(userState.user.userCode);
  const passUSER = userState.user.userCode;

  const [PassHAULNO, setPassHAULNO] = useState("");
  const [PassENGINENO, setPassENGINENO] = useState("");
  const [search, setSearch] = useState("");
  console.log(PassENGINENO);
  const [isloading, setisloading] = useState(false);
  const [EngineList, setEngineList] = useState([]);
  const [EngineListPAST, setDivisionList] = useState([]);

  const [selEngineSerial, setSelEngineSerial] = useState("");

  const [PassLASTOVERREADING, setPassLASTOVERREADING] = useState("");
  const [PassLASTOVERHAULDATE, setPassLASTOVERHAULDATE] = useState("");
  const [PassOVERHAULENGINESERIALNO, setPassOVERHAULENGINESERIALNO] =
    useState("");
  useEffect(() => {
    FetchDetails();
  }, []);
  function FetchDetails() {
    setisloading(true);
    axios
      .get(`${BASEURL}/getNewPropellerHaulNo`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        console.log(res);
        setisloading(false);
        setPassHAULNO(res.data[0].NEXTNO);
      })
      .catch((err) => {
        console.log("Error while fetching /getEngines List", err);
      });
    axios
      .get(`${BASEURL}/getPropellerOverhaul`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        console.log(res.data);
        setisloading(false);
        setEngineList(res?.data);
      })
      .catch((err) => {
        console.log("Error while fetching /getEngines List", err);
      });
    axios
      .get(`${BASEURL}/getPropellerMaster`, {
        headers: {
          "auth-token": authToken,
          "session-token": sclientSecret,
        },
      })
      .then((res) => {
        console.log(res.data);
        setisloading(false);
        setDivisionList(res?.data);
      })
      .catch((err) => {
        console.log("Error while fetching /getEngines List", err);
      });
  }
  console.log(selEngineSerial);
  const filteredData = EngineList.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true; // If there are no search terms, include all items
    }

    // Check if search terms match
    return searchData.every((term) => {
      return ["READING", "OVERHAULDATE", "ENGINESERIALNO", "OVERHAULNO"].some(
        (key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        }
      );
    });
  });

  const HandleSave = async () => {
    if (!PassOVERHAULENGINESERIALNO) {
      alert("Please Select OVERHAUL ENGINE SERIAL NO");
      return;
    }
    if (!PassLASTOVERHAULDATE) {
      alert("Please Provide LAST OVER HAUL DATE");
      return;
    }
    if (!PassLASTOVERREADING) {
      alert("Please Provide LAST OVER READING");
      return;
    }

    const confirmEdit = window.confirm("Are you sure you want to Save?");
    console.log({
      OVERHAULDOCNO: PassHAULNO,
      OVERHAULENGINESERIALNO: Number(PassOVERHAULENGINESERIALNO),
      OVERHAULDATE: PassLASTOVERHAULDATE,
      OVERHAULREADING: Number(PassOVERHAULENGINESERIALNO),
      OVERHAULUPDATEDBY: passUSER,
    });
    if (confirmEdit) {
      setisloading(true);

      try {
        const LICENSEResponse = await axios.post(
          `${BASEURL}/insertPropellerOverHaul`,
          {
            OVERHAULDOCNO: PassHAULNO,
            OVERHAULENGINESERIALNO: Number(PassOVERHAULENGINESERIALNO),
            OVERHAULDATE: PassLASTOVERHAULDATE,
            OVERHAULREADING: Number(PassLASTOVERREADING),
            OVERHAULUPDATEDBY: passUSER,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sclientSecret,
            },
          }
        );
        alert(LICENSEResponse.data.message);
        window.location.reload();
        setisloading(false);
      } catch (error) {
        setisloading(false);
        alert(error.message);
      }
    }
  };
  function handleEdit(item) {
    setPassHAULNO(item.OVERHAULNO);
    setPassENGINENO(item.ENGINESERIALNO);
    setSelEngineSerial(item.ENGINESERIALNO);
    setPassOVERHAULENGINESERIALNO(item.ENGINESERIALNO);
    setPassLASTOVERREADING(item.READING);
    setPassLASTOVERHAULDATE(item.OVERHAULDATE.slice(0, 10));
  }

  const scrollToSection = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleDivisionSelection = (selected) => {
    const division = selected.value;
    setPassENGINENO(division);
    setPassOVERHAULENGINESERIALNO(division);
    setSelEngineSerial(division);
  };

  if (isloading) {
    return <Loading />;
  }

  return (
    <div className="bg-gray-50 min-h-[100vh] newpages__input">
      <AllPagesNavbar />
      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page">{menu || "PROPELLER OVERHAUL"}</h1>
        <div className="lg:flex items-center gap-5 my-1">
          {" "}
          <button
            onClick={() => {
              window.location.reload();
            }}
            className="border-l-indigo-900 rounded mx-2 text-white"
          >
            New
          </button>
          <button
            onClick={async () => {
              if (!PassHAULNO) {
                alert("Kindly Supply ENGINE NO");
              } else {
                HandleSave();
              }
            }}
            className="border-l-indigo-900 rounded mx-2 text-white"
          >
            Save
          </button>
        </div>
      </header>
      <aside className="m-5">
        <section className="lg:flex  gap-10">
          <div>
            <p>DOCUMENT NO</p>
            <input
              type="text"
              value={PassHAULNO}
              readOnly="true"
              className="h-9 px-1"
            />
          </div>
          <div>
            <p>ENGINE SERIAL NO</p>
            <div className="w-[300px] ">
              <Select
                onChange={(selected) => handleDivisionSelection(selected)}
                value={{
                  value: selEngineSerial,
                  label: selEngineSerial || "Select ",
                }}
                options={[
                  { value: "", label: "Select .." },
                  ...(EngineListPAST
                    ? EngineListPAST.map((item) => ({
                        value: item.ENGINESERIALNO,
                        label: item.ENGINESERIALNO,
                      }))
                    : []),
                ]}
                className="z-[100]"
              />
            </div>
          </div>
          <div>
            <p>LAST OVERHAUL DATE</p>
            <input
              className="h-9 px-1"
              type="date"
              value={PassLASTOVERHAULDATE}
              onChange={(e) => setPassLASTOVERHAULDATE(e.target.value)}
            />
          </div>
          <div>
            <p> OVERHAUL READING</p>
            <input
              className="h-9 px-1"
              type="number"
              value={PassLASTOVERREADING}
              onChange={(e) => setPassLASTOVERREADING(e.target.value)}
            />
          </div>
        </section>
      </aside>
      {isloading ? (
        <Loading />
      ) : (
        <div className="z-10 lg:flex  flex-col w-full">
          <div>
            <div className="lg:flex mx-6">
              <p className="w-[70px]">Search:</p>
              <input
                className="w-[300px] h-7 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
                type="text"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
            <div className="overflow-y-auto h-[500px] px-4">
              <table className="custom-table  overflow__y__ relative text-[12px] ">
                <thead
                  className="header-cell sticky top-[0px] left-[10px]"
                  style={{ zIndex: 50 }}
                >
                  <tr>
                    <td className="text-right">
                      <p>OVERHAUL NO</p>
                    </td>
                    <td className="text-right">ENGINE SERIAL NO </td>
                    <td className="text-right">OVERHAUL DATE </td>
                    <td className="text-right"> OVERHAUL READING </td>

                    <td className="w-[120px]">EDIT</td>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 &&
                    filteredData.map((item, index) => (
                      <tr
                        key={index}
                        style={{
                          textTransform: "uppercase",
                        }}
                      >
                        <td className="w-[100px] pr-5 text-right">
                          {item.OVERHAULNO ? item.OVERHAULNO : "NA"}
                        </td>
                        <td className="w-[100px] pr-5 text-right">
                          {item.ENGINESERIALNO ? item.ENGINESERIALNO : "NA"}
                        </td>

                        <td className="">
                          {item.OVERHAULDATE
                            ? item.OVERHAULDATE.slice(8, 10) +
                              "/" +
                              item.OVERHAULDATE.slice(5, 7) +
                              "/" +
                              item.OVERHAULDATE.slice(0, 4)
                            : "NA"}
                        </td>
                        <td className=" pr-5 text-right">
                          {item.READING ? item.READING : "NA"}
                        </td>
                        <td
                          className="w-[100px]"
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            fontSize: "22px",
                          }}
                          onClick={() => {
                            handleEdit(item);
                            scrollToSection();
                          }}
                        >
                          <BiSolidEditAlt />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ENGINEOVERHAUL;
